import React, { useState } from 'react'
import { Button } from "@mui/material"
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'

import { TypographyCustom } from "../TypographyCustom"

export const ButtonCustomDownload = ({ fileName ="arquivo", extension="pdf", text="", sx={}, onRequestFile=()=>{} }) => {

  const [ loading, setLoading ] = useState(false)

  const onClick = async () => {
    setLoading(true)

    const filePdfBase64 = await onRequestFile()
    const downloadLink = document.createElement('a')
    downloadLink.href = filePdfBase64
    downloadLink.download = `${fileName}.${extension}`
    downloadLink.click()
  }

  return (
    <Button
      onClick={onClick}
      variant='contained'
      disabled={loading}
      sx={{ height:'36px', textTransform:'none', backgroundColor:'#3466B2', borderRadius:'4px', display:'flex', gap:'4px', alignItems:'center', justifyContent:'center', ...sx }}
    >
      <TypographyCustom
        text={text}
        type='label-m-v2'
        colorText='#FFFFFF'
      />
      <ArrowDownTrayIcon style={{color:'#FFFFFF', width:'16px', height:'16px'}}/>
    </Button>
  )
}