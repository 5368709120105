import React from "react"

import { useCompanyDebtContext } from "../../context/CompanyDebtContext"
import { formatCurrency, formatDateTime } from "../../../../../../../../utils/tools"
import { TableButtonEdit, TableButtonRemove, TableCustom, TableTypographyCustom } from "../../../../../../../../components"

export function TableCompanyDebt(){

  const { loading, statusTable, getAllByFilters, companyDebts, handleIsOpenDrawerForm, handleRemoveById  } = useCompanyDebtContext()

  const columnsTable = [
    { field: 'Código'},
    { field: 'Data da Dívida'},
    { field: 'Nome da Dívida'},
    { field: 'Dia do Vencimento'},
    { field: 'Valor Total'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row.id}/>,
          dataDivida: <TableTypographyCustom text={formatDateTime(row.data_divida, 'DD/MM/YYYY')}/>,
          nome: <TableButtonEdit text={row.nome} onClick={()=> handleIsOpenDrawerForm(row)}/>,
          diaVencimentoParcela: <TableTypographyCustom text={row.dia_vencimento_parcela}/>,
          valorTotal: <TableTypographyCustom text={`R$ ${formatCurrency(row.total_divida)}`}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(companyDebts)}
      pathContinue={'/diagnostic/marketing/salesPortfolio'}
      handleAdd={handleIsOpenDrawerForm}
      loading={loading}
    />
  )
}