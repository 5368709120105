import { Box, Chip } from "@mui/material";
import { useTableCustomContext } from "../TableCustomContainer";
import { useEffect } from "react";

export default function TableCustomHeaderFilters() {
	const { filters, handleRemoveFilter } = useTableCustomContext();

	const filterKeys = Object.keys(filters);

	useEffect(() => {
		console.log("filtros:", filters);
		console.log("chaves filtros:", filterKeys);
	}, []);

	return (
		<Box sx={{ display: "flex", gap: "10px" }}>
			{filterKeys.forEach((key, index) => {
				<Chip
					key={`chip-${index}-${key}`}
					label={`${key}: ${filters[key]}`}
					variant="outlined"
					onDelete={() => handleRemoveFilter(key)}
				/>;
			})}
		</Box>
	);
}
