import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useSalesPortfolioContext } from "../../context"
import { ContainerDrawerLayout } from "../../../../../../../../layouts"
import { yup } from "../../../../../../../../config/yupValidationCustom"
import { TextFieldCustomCurrency, TextFieldCustomAutocomplete, TextFieldCustomText, TextFieldCustomNumber, TextFieldCustomFile } from "../../../../../../../../components"

const schema = yup.object({
	// img_servico: yup.mixed().test("is-valid-type", "Extensão da imagem não suportada!", value => isValidFileType(value && value.name.toLowerCase(), "image"))
	// .test("is-valid-size", "A imagem deve ter no máximo 5MB!", value => value && value.size <= MAX_FILE_SIZE).required("Campo obrigatório!"),
	aux_ci_categoria_vendas_id: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	categoria_tratamento_id: yup.number().when('aux_ci_categoria_vendas_id', {
		is: (value) => value !== 2 && value !== 3 ,
		then: yup.number().nullable(),
		otherwise: yup.number().typeError("Campo obrigatório!").required("Campo obrigatório!").positive("Campo obrigatório!"),
	}),
	tratamentos_id: yup.number().when('aux_ci_categoria_vendas_id', {
		is: (value) => value !== 2 && value !== 3 ,
		then: yup.number().nullable(),
		otherwise: yup.number().typeError("Campo obrigatório!").required("Campo obrigatório!").positive("Campo obrigatório!"),
	}),
	nome_servico: yup.string().when('aux_ci_categoria_vendas_id', {
		is: (value) => value !== 2 && value !== 3 ,
		then: yup.string().required('Campo obrigatório'),
		otherwise: yup.string(),
	}),
	duracao_procedimento: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	media_qtd_vendas: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	preco_venda: yup.number().required("Campo obrigatório!").positive("Campo obrigatório!"),
	descricao_servico: yup.string().trim()
})

export function FormSalesPortfolio({ isOpen, mode='add', defaultValues, onClose=()=>{} }) {
	
	const { handleSave, optionsFields, handleDataSelectForForm } = useSalesPortfolioContext()
	const [file, setFile] = useState(defaultValues?.url_img)

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		watch,
	} = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues })

	const resetForm = () => {
		handleDataSelectForForm({})
		reset()
		onClose()
	}

	const handleSub = async (data) => {
		data.img = file

		delete data.categoria_tratamento_id // usado apenas para filtrar o tratamento
		
		resetForm()
		await handleSave(data)
	}

	const getFiltersOptions = (filters = undefined, options=[]) => {
    if(!!filters){
      return options.filter(item => item.categorias_id == filters.id)
    }
    return options
  }

	useEffect(()=>{
		Object.keys(defaultValues).forEach((fieldName) => {
			setValue(fieldName, defaultValues[fieldName])
		})
	},[defaultValues])

	return (
		<ContainerDrawerLayout
			isOpen={isOpen}
			onClose={resetForm}
			title="Marketing / Portfólio de vendas"
			subtitle={mode === 'add' ? 'Adicionar serviço' : 'Atualizar serviço'}
			onCancel={resetForm}
			onSubmit={handleSubmit(handleSub)}
			textSubmit={mode === 'add' ? 'Adicionar serviço' : 'Atualizar serviço'}
		>
			<Box sx={{ width:'100%', display:'flex', flexDirection:'column', gap:'10px' }}>
				<TextFieldCustomFile
					value={file || defaultValues?.url_img}
					onChange={(file)=>{setFile(file)}}
				/>
				<TextFieldCustomAutocomplete
					required
					name={"aux_ci_categoria_vendas_id"}
					label={"Categoria de Vendas"}
					control={control}
					options={optionsFields.salesCategories}
					onBlur={()=>{
						setValue('categoria_tratamento_id', null)								
						setValue('tratamentos_id', null)
						setValue('nome_servico', '')							
					}}
				/>
				{watch('aux_ci_categoria_vendas_id') == 2 || watch('aux_ci_categoria_vendas_id') == 3 ?
					<>
						<TextFieldCustomAutocomplete
							required
							name={"categoria_tratamento_id"}
							label={"Categoria do Tratamento"}
							control={control}
							options={optionsFields.treatmentsCategories}
						/>
						<TextFieldCustomAutocomplete
							required
							placeholder={!watch('categoria_tratamento_id') && 'Selecione uma categoria de tratamento primeiro'}
							name={"tratamentos_id"}
							label={"Tratamento"}
							control={control}
							options={getFiltersOptions({ id: watch('categoria_tratamento_id') }, optionsFields.treatments)}
							disabled={!watch('categoria_tratamento_id')}
						/>
					</>
				:
					<TextFieldCustomText
						required
						name={"nome_servico"}
						label={"Nome do Serviço"}
						control={control}
					/>
				}
				<TextFieldCustomNumber
					required
					name={"duracao_procedimento"}
					label={"Duração do Procedimento (minutos)"}
					control={control}
				/>
				<TextFieldCustomNumber
					required
					name={"media_qtd_vendas"}
					label={"Média da quantidade de vendas"}
					control={control}
				/>
				<TextFieldCustomCurrency
					required
					name={"preco_venda"}
					label={"Preço de Venda"}
					control={control}
				/>
				<TextFieldCustomText
					name={"descricao_servico"}
					label={"Descrição do Serviço"}
					control={control}
					multiline
					textRows={4}
				/>
			</Box>
			
		</ContainerDrawerLayout>
	)
}
