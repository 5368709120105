import { Box, Checkbox, Typography, TextField } from "@mui/material";
import { useState } from "react";

export function CheckboxTreatmentService({
	title = "",
	description = "",
	picture = undefined,
	onChange = () => {},
	defaultChecked = false,
}) {
	const [checked, setChecked] = useState(defaultChecked);

	const handleOnChange = (event) => {
		const valueChecked = event.target.checked;
		setChecked(valueChecked);
		onChange(valueChecked);
	};

	const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#374151",
    fontFamily: "Inter",
  }

	return (
		<Box sx={{ display: "flex", flex: 1, paddingY: "24px" }}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
				}}
			>
				<Checkbox value={checked} onChange={handleOnChange} />
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
				<Typography variant="big-b" sx={{ color: "#3466B2" }}>
					{title}
				</Typography>
				<Typography variant="body-r-v2" sx={{ color: "#374151" }}>
					{description}
				</Typography>
				<Box>
					<Typography sx={styleTypography}>Quantidades de Sessões{checked && '*'}</Typography>
					<TextField type="number"/>
				</Box>
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<img src={picture} alt="treatment-plan-picture" />
			</Box>
		</Box>
	);
}
