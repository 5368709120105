import React, { useEffect } from "react";

import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { LayoutAdminDefault } from "../../../../../layouts";
import { normalizeCPFNumber, normalizePhoneNumber } from "../../../../../utils/masks";
import { apiUsers } from "../../../../../services/apiInterface/admin/users";


export function AddUser() {

  const navigate = useNavigate();

  const schema = yup.object({
    nome: yup.string().required("Campo obrigatório!"),
    apelido: yup.string().required("Campo obrigatório!"),
    cpf: yup.string().required("Campo obrigatório!"),
    email: yup.string().email("E-mail inválido").required("Campo obrigatório!"),
    celular: yup.string().required("Campo obrigatório!"),
    senha: yup.string().required("Campo obrigatório!"),
    confirmarSenha: yup.string().oneOf([yup.ref("senha"), null], "A senha de confirmação não confere!").required("Campo obrigatório!")
  }).required();

  const { register, handleSubmit, watch, setValue, formState: {errors} } = useForm({resolver: yupResolver(schema)});

  const handleBack = () => {
    navigate('/usuarios');
  }

  const sendForm = async (newUser) => {
    try {
      const response = await apiUsers.create(newUser);

      if(response.ok){
        navigate('/usuarios', {state: {type:'success', msg: 'Usuário cadastrado com sucesso!'}});
      }else{
        navigate('/usuarios', {state: {type:'error', msg: response.msg}});
      }

    } catch (error) {
      navigate('/usuarios', {state: {type:'error', msg: error.message}});
    }
  }

  const handleAddUser = (data) => {
    sendForm(data);
  }

  const celularValue = watch('celular');

  useEffect(()=>{
    setValue('celular', normalizePhoneNumber(celularValue));
  },[celularValue]);

  const cpfValue = watch('cpf');

  useEffect(()=>{
    setValue('cpf', normalizeCPFNumber(cpfValue));
  },[cpfValue]);

  return (
    <LayoutAdminDefault
      title="Adicionar usuário"
    >
      <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2}}>
          <TextField
            required
            id="nome"
            label="Nome"
            type="text"
            {...register("nome", { required: true })}
          />
          {errors.nome && <Typography variant="caption" color="#DC1637">{errors.nome?.message}</Typography>}

          <TextField
            required
            id="cpf"
            label="CPF"
            type="text"
            {...register("cpf", { required: true})}
          />

          
           {errors.cpf && <Typography variant="caption" color="#DC1637">{errors.cpf?.message}</Typography>}

          <TextField
            required
            id="email"
            label="E-mail"
            type="email"
            {...register("email", { required: true })}
          />
          {errors.email && <Typography variant="caption" color="#DC1637">{errors.email?.message}</Typography>}

          <TextField
            required
            id="celular"
            label="Celular"
            type="tel"
            {...register("celular", { required: true })}
          />
          {errors.celular && <Typography variant="caption" color="#DC1637">{errors.celular?.message}</Typography>}

          <TextField
            required
            id="apelido"
            label="Login"
            type="text"
            {...register("apelido", { required: true })}
          />
          {errors.apelido && <Typography variant="caption" color="#DC1637">{errors.apelido?.message}</Typography>}

          <TextField
            required
            id="senha"
            label="Senha Inicial"
            type="password"
            {...register("senha", { required: true })}
          />  
          {errors.senha && <Typography variant="caption" color="#DC1637">{errors.senha?.message}</Typography>}

          <TextField
            required
            id="confirmarSenha"
            label="Confirme a Senha Inicial"
            type="password"
            {...register("confirmarSenha", { required: true })}
          />  
          {errors.confirmarSenha && <Typography variant="caption" color="#DC1637">{errors.confirmarSenha?.message}</Typography>}

      </Box>

      <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2}}>
        <Button
          variant="contained" 
          onClick={handleSubmit(handleAddUser)}
          sx={{width:"15vw"}}
          color="primary"
        >
          <Typography sx={{fontSize:"18px"}}>Cadastrar</Typography>
        </Button>

        <Button 
          variant="contained" 
          onClick={handleBack}
          sx={{width:"15vw"}}
          color="error"
        >
          <Typography sx={{fontSize:"18px"}}>Voltar</Typography>
        </Button>
      </Box>
      
    </LayoutAdminDefault>
  )
}