import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { assets } from "../../../../../../../assets/images_sys";
import { UserInformation } from "../UserInformation";

export function PatientDescription() {
	return (
		<Box
			sx={{
				width: "330px",
				gap: "16px",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					borderWidth: "1px",
					borderStyle: "solid",
					borderRadius: "10px",
					borderColor: "#1A417D",
				}}
			>
				<img
					style={{ resize: "both" }}
					src={assets.imgs.tela_img_paciente}
					alt="cover"
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "15px",
						paddingX: "20px",
						paddingBottom: "20px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "15px",
							alignItems: "center",
							marginTop: "10px",
						}}
					>
						<Avatar
							sx={{
								width: "44px",
								height: "44px",
								borderWidth: "2px",
								borderColor: "#09234A",
								borderStyle: "solid",
							}}
						/>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								paddingY: "10px",
							}}
						>
							<Typography
								variant="subtitle-m"
								sx={{
									color: "#1A417D",
								}}
							>
								Lívia Silva
							</Typography>
							<Typography
								variant="small-r-v2"
								sx={{
									color: "#09234A",
								}}
							>
								Cliente desde Janeiro de 2020
							</Typography>
						</Box>
					</Box>
					<Typography variant="big-m">
						Informações cadastrais
					</Typography>
					<UserInformation name="Email" value="lilfulana@email.com" />
					<UserInformation name="Telefone" value="(11) 98765-1234" />
					<UserInformation
						name="Data de nascimento"
						value="18/02/1996 • 27 anos"
					/>
					<UserInformation name="Gênero" value="Feminino" />
					<UserInformation
						name="Endereço"
						value="Av. Paulista, 3409, São Paulo"
					/>
					<UserInformation
						name="Interesses ou preferências"
						value="Ana, mulher moderna e antenada, encontrou no nosso salão de beleza o lugar perfeito para se sentir ainda mais linda e confiante."
					/>
				</Box>
			</Box>
		</Box>
	);
}
