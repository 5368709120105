import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotFound, Site } from "../../pages/publics";

//localhost.com.br
export const AppSiteRouters = () => {
  return (
    <Routes>
      <Route
        path="/" 
        element={
          <Site/>
        } 
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}