import React from "react";
import MenuLateralTreeAdmin from "../../components/MenuLateralTreeAdmin";
import { AppInfoProvider, AppAuthProvider, AppDrawerAdminProvider, AppThemeProvider } from "../../contexts";
import ProtectedAdminRouters from "./ProtectedAdminRouters";

export default function SystemRoutersAdmin({children}){
  return (
    <AppInfoProvider>
      <AppAuthProvider>
        <ProtectedAdminRouters>
          <AppThemeProvider>
            <AppDrawerAdminProvider>
              <MenuLateralTreeAdmin>
                {children}
              </MenuLateralTreeAdmin>
            </AppDrawerAdminProvider>
          </AppThemeProvider>
        </ProtectedAdminRouters>
      </AppAuthProvider>
    </AppInfoProvider>
  )
}