import React from 'react'
import { Box } from '@mui/material'

import { FormBody, FormCapillary, FormFacial } from './components'
import { AccordionCustom } from '../../../../../../../../../../../../../../components'
import { useSalesPortfolioContext } from '../../../../../../../../context'
import { ID_CLASSIFICATION_BODY, ID_CLASSIFICATION_CAPILLARY, ID_CLASSIFICATION_FACIAL } from '../../../../constrains'

export const TabCareAfter = () => {
  const { dataSelectView } = useSalesPortfolioContext()

  const checkClassification = (id) => {
    const classification = dataSelectView?.classificacao || []
    const check = classification.some(item => item.id == id)
    return check
  }

  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'8px'}}>
      {checkClassification(ID_CLASSIFICATION_CAPILLARY) && 
        <AccordionCustom
          headerTitle="Capilar"
          colorClosed={'#f3f4f6'}
          defaultExpanded
        >
          <FormCapillary/>
        </AccordionCustom>
      }
      {checkClassification(ID_CLASSIFICATION_FACIAL) && 
        <AccordionCustom
          headerTitle="Facial"
          colorClosed={'#f3f4f6'}
          defaultExpanded
        >
          <FormFacial/>
        </AccordionCustom>
      }
      {checkClassification(ID_CLASSIFICATION_BODY) && 
        <AccordionCustom
          headerTitle="Corporal"
          colorClosed={'#f3f4f6'}
          defaultExpanded
        >
          <FormBody/>
        </AccordionCustom>
      }
    </Box>
  )
}