import React from 'react'
import { Box, CircularProgress } from '@mui/material'

import { DateFieldsAvailability, HeaderBack } from '../../../../../../components'
import { useAgendaAdmin } from '../../../../../../hooks/useAgendaAdmin'
import { ContainerLayout } from '../../../../../../layouts/ContainerLayout'

export function AgendaAdmin() {

  const { loading, agendaAdmin, handleSave } = useAgendaAdmin()

  return (
    <ContainerLayout
      title='Informações Iniciais'
      subtitle='Agenda Admin'
      sx={{width:'100%'}}
      headerCustom={<HeaderBack/>}
    >
      {!loading ? 
        <Box sx={{width:'100%', display:'flex'}}>
          <DateFieldsAvailability
            dataDefault={agendaAdmin}
            onChange={(data)=>{handleSave(data)}}
            type={'full'}
          />
        </Box>
      :
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }
    </ContainerLayout>
  )
}