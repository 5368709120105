import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";

import { useOnboarding } from "../../hooks";
import Questions from "./Questions";
import NavigateQuestions from "./NavigateQuestions";

export function OnboardingQuestionsClients() {

  const { questions, loading, page, handleGoPage, handleNextPage, handlePreviousPage, handleSelectAlternative, isSelected, isAllSelected, handleSaveAnswers } = useOnboarding();

  const [ setIsAnswerLaterButtonActive ] = useOutletContext();

  useEffect(()=>{
    setIsAnswerLaterButtonActive(true);
  },[]);

  return (
    <Box sx={{ display:'flex', width: "100vw", height:'94%', backgroundColor: "#F3F4F6", alignItems:'center', justifyContent:'center'}}>
      {!loading ?
        <Box sx={{width:'1000px', height:'400px', display:'flex', flexDirection:'column'}}>
          <Box sx={{height:'70px'}}>
            <Typography variant="h1" align="justify" sx={{ fontSize:'25px', fontWeight:700, lineHeight:'130%', color:'#374151', fontStyle:'normal', letterSpacing:'0%', fontFamily:'Inter', wordSpacing:'8px'}}>
              {`${questions[page]?.sequencia}. ${questions[page]?.pergunta}`}
            </Typography>
          </Box>

          <Questions
            alternatives={questions[page].alternativas}
            handleSelectAlternative={handleSelectAlternative}
          />

          <NavigateQuestions
            page={page}
            questions={questions}
            isSelected={isSelected}
            handleGoPage={handleGoPage}
            isAllSelected={isAllSelected}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            handleSaveAnswers={handleSaveAnswers}
          />
        </Box>
        :
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
          <CircularProgress color="info"/>
        </Box>
      }
    </Box>
  )
}