import React, { useState } from "react";
import { Box, Pagination, Typography } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid';

import { get } from "../../../environment";
import { formatDateTime } from "../../../utils/tools";
import { ToolsActionsRoles } from "../../ToolsActions/admin";

export function TableGridRoles({rows=[], columns=[], search, setSearchParams, loading, rowsCount, page, onUpdatedRows}){
  const [rowsSelect, setRowsSelect] = useState([]);

  const searchRowsFormated = rows.map((role)=>{
    role.createdAtFormated = formatDateTime(role.createdAt);
    role.updatedAtFormated = formatDateTime(role.updatedAt);
    return role;
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport/>
      </GridToolbarContainer>
    );
  }

  return(
    <>
      <ToolsActionsRoles
        textSearch={search}
        setTextSearch={text => setSearchParams({page, search: text}, {replace: true})}
        rowsSelect={rowsSelect}
        onUpdatedRows={onUpdatedRows}
      />
      <Box sx={{m: 1, height:750, width:'auto'}}>
        <DataGrid
          onSelectionModelChange={(idRowsSelec)=>{
            let array = [];
            for (const id of idRowsSelec) {
              for (const row of rows) {
                if(row.id === id){
                  array.push(row);
                }
              }
            }
            setRowsSelect(array);
          }}
          rows={searchRowsFormated}
          columns={columns}
          // pageSize={Environment.LIMIT_ROWS_TABLE_PAGE}
          pagination={undefined}
          hideFooterPagination
          checkboxSelection
          loading={loading}
          components={{Toolbar: CustomToolbar}}
          density={"compact"}
        />
      </Box>
      <Box sx={{m: 1, display: 'flex', flexDirection:'column', alignItems:'center', height:'10%'}}>
        <Pagination
          color="primary"
          count={Math.ceil(rowsCount/get('LIMIT_ROWS_TABLE_PAGE'))}
          page={page}
          onChange={(_, newPage)=> setSearchParams({search, page: newPage.toString()}, {replace: true})}
          showFirstButton
          showLastButton
        />
        <Box sx={{display: 'flex', width: '100%', justifyContent:'flex-end'}}>
          <Typography>
            Total de registros: {rowsCount}
          </Typography>
        </Box>
      </Box>
    </>
    
  )
}