import React, { useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import { assets } from "../../../../../assets/images_sys";

export function OnboardingResultProfileClients() {

  const [ setIsAnswerLaterButtonActive ] = useOutletContext();

  const { state } = useLocation();

  const navigate = useNavigate();


  const handleRedirectDiagnostic = () => {
    navigate('/diagnostic');
  }

  useEffect(()=>{
    setIsAnswerLaterButtonActive(false);
  },[]);
  
  useEffect(()=>{
    if(!state){
      navigate('/onboarding');
    }
  },[state]);
  
  return (
    <Box sx={{ display:'flex', width: "100vw", height:'94%', backgroundColor: "#F3F4F6"}}>

      <Box sx={{ display:'flex', width: '1400px', height:'94%'}}>
        <Box sx={{width:'55vw', display:'flex', alignItems:'center', justifyContent:'left'}}>
          <Box sx={{height:400, display:'flex', alignItems:'center', justifyContent:'left'}}>
            <img src={assets.imgs.tela_img_perfil} style={{width: '90%', height: 'auto'}}/>
          </Box>
        </Box>

        <Box sx={{width:'40vw', display:'flex', alignItems:'center', justifyContent:'left'}}>
          <Box sx={{width:'500px', display:'flex', flexDirection:'column', gap:'18px'}}>
            <Typography sx={{fontSize:'28px', fontWeight:700, lineHeight:'130%', color:'#374151', fontFamily:'Inter'}}>
              {`Seu perfil é: ${state?.perfil}`}
            </Typography>

            <Box sx={{display:'flex', flexDirection:'column', gap:'12px', textAlign:'justify'}}>
              {state?.textos.map((text, index)=>(
                <Typography key={index} sx={{fontSize:'20px', fontWeight:500, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
                  {text.texto}
                </Typography>
              ))}
            </Box>

            <Typography sx={{fontSize:'20px', fontWeight:500, lineHeight:'140%', color:'#374151', fontFamily:'Inter'}}>
              Agora, o próximo passo é a <strong>Análise do Negócio</strong>.
            </Typography>
            <Button
              variant="contained"
              sx={{ mt:'20px',width: "260px", fontSize:'16px', textTransform:'none', backgroundColor:'#3466B2'}}
              endIcon={<ArrowForward />}
              onClick={handleRedirectDiagnostic}
            >
              <Typography sx={{fontSize:'16px', fontWeight:600, lineHeight:'150%', color:'#FFFFFF', fontFamily:'Inter'}}>
                Vamos, estou pronta
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>

    </Box>
  )
}