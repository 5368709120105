import React from "react"
import { useNavigate } from "react-router-dom"

import { useConsultationContext, useConsultationPageContext } from "../../../../../../contexts"
import { TableButtonRemove, TableButtonView, TableCustom, TableTypographyCustom } from "../../../../../../../../../components"
import { formatDateTime } from "../../../../../../../../../utils/tools"

export function ConsultationTable(){

  const { handleConfirmPlan, handleConfirmPayment, handleConfirmFinishedTreatment   } = useConsultationPageContext()

  const { loading, statusTable, getAllByFilters, formalInvoicing, handleIsOpenDrawerForm, handleRemoveById  } = useConsultationContext()

  const navigate = useNavigate()

  const handleViewConsultation = () => {
    handleConfirmPlan()
    handleConfirmPayment()
    handleConfirmFinishedTreatment()
    navigate('assessment')
  }

  const columnsTable = [
    { field: 'Código'},
    { field: 'Data da Consulta'},
    { field: 'Responsável'},
    { field: 'Plano de Tratamento'},
    { field: 'Ações'}
  ]

  const rowsFormat = (rows=[]) => {
    return rows.map((row)=>{
      return (
        {
          id: <TableTypographyCustom text={row.id}/>,
          data_consulta: <TableButtonView text={row.data_consulta} onClick={()=> handleViewConsultation(row)}/>,
          responsavel: <TableTypographyCustom text={row.responsavel}/>,
          plano_tratamento: <TableTypographyCustom text={row.plano_tratamento}/>,
          remover: <TableButtonRemove onClick={()=> handleRemoveById(row.id)}/>
        }
      )
    })
  }

  const rows = [
    {
      id:1,
      data_consulta: formatDateTime(new Date(), 'DD/MM/YYYY'),
      responsavel: 'Laura Camargo',
      plano_tratamento: '#XF45J'
    }
  ]

  return (
    <TableCustom
      handleSubmit={getAllByFilters}
      statusTable={statusTable}
      columns={columnsTable}
      rows={rowsFormat(rows)}
      // pathContinue={'/diagnostic/marketing/taxes'}
      // handleAdd={handleIsOpenDrawerForm}
      loading={loading}
      showButtonContinue={false}
      positionButtonAdd="none"
    />
  )
}