import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";

export default function ImageMarker({
	posX = 0,
	posY = 0,
	mirrored = false,
	children,
}) {
	const lineWidthInPixels = 105;
	const linePosInPixels = 16;
	const boxPosXInPixels = 71;
	const boxPosXMirroredInPixels = -174;

	return (
		<Box
			sx={{
				position: "absolute",
				left: posX,
				top: posY,
				width: "40px",
				height: "40px",
				borderRadius: "20px",
				backgroundColor: "#FFFFFF",
			}}
		>
			<Box
				sx={{
					position: "relative",
					top: "4px",
					left: "4px",
					width: "32px",
					height: "32px",
					borderRadius: "16px",
					backgroundColor: "#A7CAFF",
				}}
			>
				<Box
					sx={{
						position: "relative",
						top: "8px",
						left: "8px",
						width: "16px",
						height: "16px",
						borderRadius: "8px",
						backgroundColor: "#558EE3",
					}}
				/>
				{!!children && (
					<>
						<Box
							sx={{
								position: "relative",
								top: "-1px",
								left: `${
									linePosInPixels -
									mirrored * lineWidthInPixels
								}px`,
								width: `${lineWidthInPixels}px`,
								borderWidth: "1px",
								borderStyle: "dashed",
								borderColor: "#558EE3",
							}}
						/>
						<Box
							sx={{
								display: "flex",
								position: "relative",
								top: "-16px",
								left: `${
									mirrored
										? boxPosXMirroredInPixels
										: boxPosXInPixels
								}px`,
								width: "250px",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "8px",
									padding: "8px",
									backgroundColor: "#EFF5FF",
									borderWidth: "1px",
									borderColor: "#558EE3",
									borderStyle: "solid",
									borderRadius: "8px",
									maxWidth:'200px'
								}}
							>
								{children}
							</Box>
						</Box>
					</>
				)}
			</Box>
		</Box>
	);
}
