import React, { useState } from "react";
import { Box } from "@mui/system";
import { Pagination, Typography } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid';

import { get } from "../../../environment";
import { formatDateTime } from "../../../utils/tools";
import { normalizeCNPJNumber } from "../../../utils/masks";
import { ToolsActionsClients } from "../../ToolsActions/admin";

export function TableGridClients({ rows=[], columns=[], search, setSearchParams, loading, rowsCount, page, onUpdatedRows}){
  const [rowsSelect, setRowsSelect] = useState([]);

  const searchRowsFormated = rows.map((row)=>{
    row.nickname = row.user.nickname;
    row.cnpjFormatado = normalizeCNPJNumber(row.cnpj);
    row.classificacao_empresa_desc = row.classificacao_empresa.descricao;
    row.categoria_negocio_desc = row.categoria_negocio.descricao;
    row.dataCriacaoFormatado = formatDateTime(row.data_criacao);
    row.dataAlteracaoFormatado = formatDateTime(row.data_alteracao);
    row.statusFormated = row.status === '1'? 'Ativo':'Inativo';
    return row;
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport/>
      </GridToolbarContainer>
    );
  }

  return(
    <>
      <ToolsActionsClients
        textSearch={search}
        setTextSearch={text => setSearchParams({page, search: text}, {replace: true})}
        rowsSelect={rowsSelect}
        onUpdatedRows={onUpdatedRows}
      />
      <Box sx={{m: 1, height:'80%', width:'auto',
        "& .status--Inativo": {
          // backgroundColor: "#F7656C"
          backgroundColor: "#E6B7B5",
          color: 'black'
        }
        }}
      >
        <DataGrid
          onSelectionModelChange={(idRowsSelec)=>{
            let array = [];
            for (const id of idRowsSelec) {
              for (const row of rows) {
                if(row.id === id){
                  array.push(row);
                }
              }
            }
            setRowsSelect(array);
          }}
          rows={searchRowsFormated}
          columns={columns}
          getRowClassName={(params) => `status--${params.row.statusFormated}`}
          pagination={undefined}
          hideFooterPagination
          checkboxSelection
          loading={loading}
          components={{Toolbar: CustomToolbar}}
          density={"compact"}
        />
      </Box>
      <Box sx={{m: 1, display: 'flex', flexDirection:'column', alignItems:'center', height:'10%'}}>
        <Pagination
          color="primary"
          count={Math.ceil(rowsCount/get('LIMIT_ROWS_TABLE_PAGE'))}
          page={page}
          onChange={(_, newPage)=> setSearchParams({search, page: newPage.toString()}, {replace: true})}
          showFirstButton
          showLastButton
        />
        <Box sx={{display: 'flex', width: '100%', justifyContent:'flex-end'}}>
          <Typography>
            Total de registros: {rowsCount}
          </Typography>
        </Box>
      </Box>
    </>
    
  )
}