import { AppSiteRouters, AppAdminRouters, AppClientRouters } from "./appRouters";


export const APPS_DEFAULT = [
  {
    subdomain: 'www',
    app: AppSiteRouters,
    main: true
  },
  {
    subdomain: 'admin',
    app: AppAdminRouters,
    main: false
  },
]

export const APP_CLIENT = AppClientRouters;