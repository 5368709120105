import React from "react"

import { TypographyCustom } from "../TypographyCustom"

export function TableTypographyCustom({text=''}) {
  return (
    <TypographyCustom
      text={text}
      type={'inputText-r-m'}
      colorText={'#4B5563'}
      textAlign={'center'}
    />
  )
}