import { useState } from "react"
import { Outlet } from "react-router-dom"

import { usePlanningContext } from "../contexts"
import { usePageContext } from "../../../../contexts"
import { ContainerMain, DrawerCustom, HeaderMain } from "../../../../components"
import SystemRoutersClients from "../../../../routers/routers/SystemRoutersClients"

export function PlanningLayout() {
	const [isAnswerLaterButtonActive, setIsAnswerLaterButtonActive] = useState(false)
	const { isPageHeaderPrincipalShown, isPageHeaderSecondaryShown } = usePageContext()
  const { isPageDrawerShown } = usePlanningContext()

	return (
		<SystemRoutersClients>
			<ContainerMain>
				{isPageHeaderPrincipalShown && (
					<HeaderMain height={"6%"} isAnswerLaterButtonActive={isAnswerLaterButtonActive}/>
				)}
				{isPageDrawerShown ? (
					<DrawerCustom module="planning" disableHeader={isPageHeaderSecondaryShown}>
						<Outlet context={[setIsAnswerLaterButtonActive]}/>
					</DrawerCustom>
				) : (
					<Outlet context={[setIsAnswerLaterButtonActive]}/>
				)}
			</ContainerMain>
		</SystemRoutersClients>
	)
}
