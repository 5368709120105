import { api } from "../../../axios_config";

const getPermissionsByRoleId = async (roleId) => {
  const resp = await api.get(`/cadastros/permissionsRole/${roleId}`);
  return resp.data;
}

const post = async (newPermissionsRole) => {
  const resp = await api.post('/cadastros/permissionsRole', newPermissionsRole);
  return resp.data;
}

export const apiPermissionsRole = {
  getPermissionsByRoleId,
  post,
}