import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

import { LayoutAdminDefault } from "../../../../../layouts";
import { apiUsers } from "../../../../../services/apiInterface/admin/users";

export function ChangePassUser() {

  const params = useParams();

  const navigate = useNavigate();

  const schema = yup.object({
    newPass: yup.string().required("Campo obrigatório!"),
    confirmNewPass: yup.string().oneOf([yup.ref("newPass"), null], "A senha de confirmação não confere!").required("Campo obrigatório!"),
    changePass: yup.string().required("Campo obrigatório!")
  }).required();

  const { register, handleSubmit, formState: { errors} } = useForm({resolver: yupResolver(schema)});

  const handleBack = () => {
    navigate('/usuarios');
  }

  const handleChangePass = async (data) =>{
    try {
      const { ok, msg } = await apiUsers.updateChangePass(params.id, data);
      navigate('/usuarios', {state: {type:ok?'success':'error', msg: msg}});
    } catch (error) {
      navigate('/usuarios', {state: {type:'error', msg: error.message}});
    }
  }

  return (
    <LayoutAdminDefault
      title="Alterar senha do usuário"
    >
      <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2}}>
        <TextField
          required
          id="newPass"
          label="Nova Senha"
          type="password"
          autoComplete="current-password"
          {...register("newPass", { required: true })}
        />
        {errors.newPass && <Typography variant="caption" color="#DC1637">{errors.newPass?.message}</Typography>}

        <TextField
          required
          id="confirmNewPass"
          label="Confirme a Nova Senha"
          type="password"
          autoComplete="current-password"
          {...register("confirmNewPass", { required: true })}
        />
        {errors.confirmNewPass && <Typography variant="caption" color="#DC1637">{errors.confirmNewPass?.message}</Typography>}

        <FormControl required>
          <InputLabel id="changePass">Trocar senha no logon?</InputLabel>
          <Select
            id="changePass"
            label="Trocar senha no logon?"
            defaultValue="1"
            {...register("changePass", { required: true })}
          >
            <MenuItem value={"1"}>Sim</MenuItem>
            <MenuItem value={"0"}>Não</MenuItem>
          </Select>
        </FormControl>
        {errors.changePass && <Typography variant="caption" color="#DC1637">{errors.changePass?.message}</Typography>}
      </Box>

      <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2}}>
        <Button
          variant="contained" 
          onClick={handleSubmit(handleChangePass)}
          sx={{width:"15vw"}}
          color="primary"
        >
          <Typography sx={{fontSize:"18px"}}>Atualizar</Typography>
        </Button>

        <Button 
          variant="contained" 
          onClick={handleBack}
          sx={{width:"15vw"}}
          color="error"
        >
          <Typography sx={{fontSize:"18px"}}>Voltar</Typography>
        </Button>
      </Box>
      
    </LayoutAdminDefault>
  )
}