import { Navigate, useLocation } from "react-router-dom";
import { LoadingIndeterminate } from "../../components";
import { useAuthContext } from "../../contexts";

export default function ProtectedAdminRouters({children}){
  const { authenticated, loading } = useAuthContext();
  const location = useLocation();

  if(!loading){

    if(location.pathname === '/'){
      if(authenticated){
        // Verificar o estado do app, para saber para onde direcionar
        return <Navigate to='/home'/>
      }else{
        return children;
      }
    }else{
      if(authenticated){
        return children;
      }else{
        // Verificar o estado do app, para saber para onde direcionar
        return <Navigate to='/'/> // Retorna para o login
      }
    }
  }else{
    return <LoadingIndeterminate />
  }
}