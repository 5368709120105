export const columnsClients = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'statusFormated', headerName: 'Status', width: 90 },
  { field: 'cnpjFormatado', headerName: 'CNPJ', width: 160 },
  { field: 'nome_empresa', headerName: 'Nome da Empresa', width: 200 },
  { field: 'nome_empresario', headerName: 'Nome do Empresário', width: 200 },
  { field: 'email', headerName: 'E-mail', width: 150 },
  { field: 'categoria_negocio_desc', headerName: 'Categoria do Negócio', width: 170 },
  { field: 'classificacao_empresa_desc', headerName: 'Classificação', width: 150},
  { field: 'dataCriacaoFormatado', headerName: 'Criado em', width: 160},
  { field: 'dataAlteracaoFormatado', headerName: 'Atualizado em', width: 160}
];