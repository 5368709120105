import React from "react";
import { Box } from "@mui/system";
import { Divider, IconButton, Paper, TextField, Tooltip, useTheme, Zoom } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import PreviewIcon from '@mui/icons-material/Preview';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import DnsIcon from '@mui/icons-material/Dns';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { useNavigate } from "react-router-dom";
import { validatePermission } from "../../../utils/tools";
import { useAuthContext } from "../../../contexts";

export function ToolsActionsClients({textSearch, setTextSearch, rowsSelect}){

  const navigate = useNavigate();

  const theme = useTheme();

  const { userLogged } = useAuthContext();

  const handleClearTextSearch = () => {
    setTextSearch('');
  }

  const handleFormAdd = () => {
    navigate('/clientes/adicionar');
  }

  const handleFormEdit = () => {
    navigate(`/clientes/${rowsSelect[0].id}/editar`);
  }

  const handleViewRow = () => {
    navigate(`/clientes/${rowsSelect[0].id}`);
  }

  const handleUsersClient = () => {
    navigate(`/clientes/${rowsSelect[0].id}/usuarios`);
  }

  const handleSubdomain = () => {
    navigate(`/clientes/${rowsSelect[0].id}/subdominio`);
  }

  const handleUploadImg = () => {
    navigate(`/clientes/${rowsSelect[0].id}/imagem`);
  }

  return (
    <Box 
      height={theme.spacing(5)} 
      component={Paper} 
      display="flex" 
      alignItems="center"
      padding={1}
      marginX={1}
      paddingX={2}
      gap={1}
    >
      <Box>
        <TextField 
          label="Procurar..." 
          variant="outlined" 
          size="small"
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value)}
        />
        <Tooltip title="Limpar Pesquisa" TransitionComponent={Zoom}>
          <IconButton onClick={handleClearTextSearch}>
            <ClearIcon/>
          </IconButton>
        </Tooltip>
      </Box>
      <Divider orientation="vertical" variant="middle"/>
      <Box>
        <Tooltip title="Adicionar" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleFormAdd}
            disabled={!validatePermission(userLogged, 'cadastrar_clientes')}
            component="div"
          >
            <AddBoxIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Visualizar" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleViewRow}
            disabled={!validatePermission(userLogged, 'ver_clientes') === false ? (rowsSelect.length === 1 ? false: true): true}
            component="div"
          >
            <PreviewIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Editar" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleFormEdit} 
            disabled={!validatePermission(userLogged, 'editar_clientes') === false ? (rowsSelect.length === 1 ? false: true): true}
            component="div"
          >
            <EditIcon/>
          </IconButton>
        </Tooltip>
      </Box>
      <Divider orientation="vertical" variant="middle"/>
      <Box>
        <Tooltip title="Usuários" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleUsersClient}
            disabled={!validatePermission(userLogged, 'usuarios_clientes') === false ? (rowsSelect.length === 1 ? false: true): true}
            component="div"
          >
            <PeopleOutlineIcon/>
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title="Subdominio" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleSubdomain}
            disabled={!validatePermission(userLogged, 'cadastro_subdominio') === false ? (rowsSelect.length === 1 ? false: true): true}
            component="div"
          >
            <DnsIcon/>
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title="Upload Imagem" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleUploadImg}
            disabled={!validatePermission(userLogged, 'upload_imagem_cliente') === false ? (rowsSelect.length === 1 ? false: true): true}
            component="div"
          >
            <UploadFileIcon/>
          </IconButton>
        </Tooltip>
      </Box>

      {/* <Divider orientation="vertical" variant="middle"/>
      <Box>
        <Tooltip title="Definir Grupos" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleFormAttachGroup}
            disabled={!validatePermission(userLogged, 'vincular_grupos_pessoas') === false ? (rowsSelect.length === 1 ? false: true): true}
            component="div"
          >
            <GroupsIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Definir Regra para Pesquisa" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleFormAttachRule}
            disabled={!validatePermission(userLogged, 'vincular_regras_pessoas') === false ? (rowsSelect.length === 1 ? false: true): true}
            component="div"
          >
            <RuleIcon/>
          </IconButton>
        </Tooltip>
      </Box> */}
    </Box>
  )
}