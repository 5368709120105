import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";

import { LayoutAdminDefault } from "../../../../../layouts";
import { formatDateTime } from "../../../../../utils/tools";
import { TableGridHistoryAccessUserClients } from "../../../../../components/TableGrid/admin";
import { apiAccessHistoryUser } from "../../../../../services/apiInterface/admin/accessHistoryUser";

export function AccessHistoryUserClients() {

  const params = useParams();

  const navigate = useNavigate();

  const [ accessHistory, setAccessHistory ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  const handleBack = () => {
    navigate('/clientes');
  }

  useEffect(()=>{
    (async ()=>{
      try {
        setLoading(true);
        const {ok, registers} = await apiAccessHistoryUser.getAllByUser(params?.user_id, params?.client_id);
        if(ok){
          const accessHistoryFormatted = registers.map((access)=>{
            access.dateLoginFormatted = formatDateTime(access.data_login);
            return access;
          });
          setAccessHistory(accessHistoryFormatted);
          setLoading(false);
        }else{
          alert('Não foi possível obter o histórico!');
        }
      } catch (error) {
        alert('Error: ' + error.message);
      }
    })()
  },[]);

  return (
    <LayoutAdminDefault
      title="Histórico de Acesso"
    >
      <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2}}>
        {!loading ?
            <TableGridHistoryAccessUserClients
              rows={accessHistory}
            />
          :
            <Typography>
              Carregando...
            </Typography>
        }
      </Box>

      <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2}}>
        <Button 
          variant="contained" 
          onClick={handleBack}
          sx={{width:"15vw"}}
          color="error"
        >
          <Typography sx={{fontSize:"18px"}}>Voltar</Typography>
        </Button>
      </Box>
      
    </LayoutAdminDefault>
  )
}