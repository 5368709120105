import { PencilIcon } from "@heroicons/react/20/solid";
import { Box, Button, Typography } from "@mui/material";

export function Notes({ value = undefined, onChange = () => {} }) {
	const handleOnChange = () => {};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				padding: "12px",
				gap: "16px",
				backgroundColor: "#F9FAFB",
				borderRadius: "8px",
				border: "solid 1px #6B7280",
			}}
		>
			{value ? (
				<>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Typography variant="big-m">
							Proporções gerais:
						</Typography>
						<Typography variant="body-m">
							14 Agosto, 2023 - 15:32
						</Typography>
					</Box>
					<Typography variant="body-r-v2">
						• Estrutura facial simétrica e bem definida.
						<br />
						• Proporções nasais adequadas em relação às proporções
						faciais gerais.
						<br /> • Linha dos olhos bem alinhada com o terço
						superior da face.
					</Typography>
					<Box>
						<Button
							variant="outlined"
							onClick={() => {}}
							startIcon={
								<PencilIcon
									style={{
										width: "13px",
										height: "13px",
										color: "#3466B2",
									}}
								/>
							}
						>
							<Typography variant="button-s-v2">
								Editar anotações
							</Typography>
						</Button>
					</Box>
				</>
			) : (
				<Box>
					<Button variant="text" onClick={handleOnChange}>
						<Typography variant="button-s-v2">
							Adicionar anotações...
						</Typography>
					</Button>
				</Box>
			)}
		</Box>
	);
}
