import { api } from "../../../axios_config";

const getAllByUser = async (userId, clientId) => {
  const resp = await api.get(`/cadastros/accessHistoryByUser/${userId}?client_id=${clientId}`);
  return resp.data;
}

const create = async (userId) => {
  const resp = await api.post('/cadastros/accessHistory', { userId });
  return resp.data;
}

export const apiAccessHistoryUser = {
  getAllByUser,
  create,
}