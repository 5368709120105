import { api } from "../../../axios_config";

export const getLocalidades = async () => {
  const {data} = await api.get('/cadastros/localidades');
  if(data.ok){
    const options = [];
    for (const opt of data.registers) {
      options.push({label: `${opt.cidade}-${opt.estado}`, value: opt.id});
    }
    return options;
  }else{
    return new Error('Erro na busca das opções das localidade!')
  }
}