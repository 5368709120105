import { Box } from "@mui/material";

export function PageHeaderContainer({ children }) {
	return (
		<Box
			sx={{
				height: "65px",
				display: "flex",
				backgroundColor: "#D1D5DB",
			}}
		>
			<Box sx={{ ml: "50px", display: "flex", alignItems: "center" }}>
				{children}
			</Box>
		</Box>
	);
}
