import React from "react"
import { Route, Outlet } from "react-router-dom"

import { NotFound } from "../../../../pages/publics"
import DiagnosticPage from "../pages/diagnosticPage"
import { Users, ViewUsers } from "../pages/settings"
import { Employees, ViewEmployee } from "../pages/people"
import { Active, Stock, ViewActive } from "../pages/operation"
import { UsersContextProvider } from "../pages/settings/users/context"
import { ActiveContextProvider } from "../pages/operation/active/context"
import { EmployeesContextProvider } from "../pages/people/employees/context"
import { FinancialContextProvider } from "../pages/finances/financial/context"
import { DiagnosticContextProvider, PageContextProvider } from "../../../../contexts"
import { SalesPortfolioContextProvider } from "../pages/marketing/salesPortfolio/context"
import { ProductivityContextProvider } from "../pages/initialInformation/productivity/context"
import { FinancialReserveContextProvider } from "../pages/initialInformation/financialReserve/context"
import { FormalInvoicing, GrossInvoicing, SalesPortfolio, Taxes, ViewSalesPortfolio } from "../pages/marketing"
import { AgendaAdmin, CompanyDebt, FinancialReserve, PreliminaryInformation, Productivity } from "../pages/initialInformation"
import { DiagnosticInitial, DiagnosticMenuMainClients, DiagnosticProcessingResultClients, DiagnosticResultClients } from "../pages"
import { PreliminaryInformationContextProvider } from "../pages/initialInformation/preliminaryInformation/context/PreliminaryInformationContext"
import { BanksMachines, Financial, FormMachinesTaxes, General, Infra, Marketing, ViewAccount, ViewAddAccount, ViewMachine } from "../pages/finances"
import { AccountsContextProvider, BanksContextProvider, MachinesContextProvider, MachinesFlagsCardsContextProvider, MachinesModelsContextProvider, MachinesTaxesContextProvider } from "../pages/finances/banksMachines/context"

export const ModuleDiagnosticRouters = (
  <>
    <Route path="diagnostic"
			element={
				<PageContextProvider>
					<DiagnosticContextProvider>
						<DiagnosticPage />
					</DiagnosticContextProvider>
				</PageContextProvider>
			}
		>
			<Route index element={<DiagnosticInitial />} />
			<Route
				path="menu"
				element={<DiagnosticMenuMainClients />}
			/>

			<Route path="initialInformation">
				<Route
					path="preliminaryInformation"
					element={
						<PreliminaryInformationContextProvider>
							<PreliminaryInformation />
						</PreliminaryInformationContextProvider>
					}
				/>
				<Route
					path="financialReserve"
					element={
						<FinancialReserveContextProvider>
							<FinancialReserve />
						</FinancialReserveContextProvider>
					}
				/>
				<Route
					path="companyDebt"
					element={<CompanyDebt />}
				/>
				<Route
					path="agendaAdmin"
					element={<AgendaAdmin />}
				/>
				<Route
					path="productivity"
					element={
						<ProductivityContextProvider>
							<Productivity />
						</ProductivityContextProvider>
					}
				/>
				<Route path="*" element={<NotFound />} />
			</Route>

			<Route path="marketing">
				<Route path="salesPortfolio" element={
					<SalesPortfolioContextProvider>
						<Outlet/>
					</SalesPortfolioContextProvider>
				}>
					<Route index element={<SalesPortfolio />}/>
					<Route path=":salesPortfolioId" element={<ViewSalesPortfolio />}/>
				</Route>
				<Route
					path="grossInvoicing"
					element={<GrossInvoicing />}
				/>
				<Route
					path="formalInvoicing"
					element={<FormalInvoicing />}
				/>
				<Route path="taxes" 
					element={<Taxes />} 
				/>
				<Route path="*" element={<NotFound />} />
			</Route>

			<Route path="people">
				<Route path="employees" element={
					<EmployeesContextProvider>
						<Outlet/>
					</EmployeesContextProvider>
				}>
					<Route index element={<Employees />}/>
					<Route path=":employeesId" element={<ViewEmployee />}/>
				</Route>
				<Route path="*" element={<NotFound />} />
			</Route>

			<Route path="finances">
				<Route
					path="infra"
					element={<Infra />}
				/>
				<Route
					path="marketing"
					element={<Marketing />}
				/>

				<Route path="banksMachines" element={
					<AccountsContextProvider>
						<BanksContextProvider>
							<Outlet/>
						</BanksContextProvider>
					</AccountsContextProvider>
				}>
					<Route index element={<BanksMachines />}/>
					<Route path="addAccount" element={<ViewAddAccount/>}/>
					{/* <Route path="viewAccount/:accountId"> */}
					<Route path="viewAccount/:accountId" element={
						<MachinesModelsContextProvider>
							<MachinesContextProvider>
								<Outlet/>
							</MachinesContextProvider>
						</MachinesModelsContextProvider>
					}>
						<Route index element={<ViewAccount />}/>
						<Route
							path="viewMachine/:machineId"
							element={
								<MachinesFlagsCardsContextProvider>
									<MachinesTaxesContextProvider>
										<Outlet />
									</MachinesTaxesContextProvider>
								</MachinesFlagsCardsContextProvider>
							}
						>
							<Route index element={<ViewMachine />}/>
							<Route path="addTax" element={<FormMachinesTaxes />}/>
							<Route path="editTax/:taxesId" element={<FormMachinesTaxes />}/>
						</Route>
					</Route>
				</Route>

				<Route
					path="financial"
					element={
						<FinancialContextProvider>
							<Financial />
						</FinancialContextProvider>
					}
				/>
				<Route
					path="general"
					element={<General />}
				/>
				<Route path="*" element={<NotFound />} />
			</Route>

			<Route path="operation">
				<Route path="active" element={
					<ActiveContextProvider>
						<Outlet/>
					</ActiveContextProvider>
				}>
					<Route index element={<Active />}/>
					<Route path=":activeId" element={<ViewActive />}/>
				</Route>
				<Route 
					path="stock" 
					element={<Stock />} 
				/>
				<Route path="*" element={<NotFound />} />
			</Route>

			<Route path="settings">
				<Route path="users" element={
					<UsersContextProvider>
						<Outlet/>
					</UsersContextProvider>
				}>
					<Route index element={<Users />}/>
					<Route path=":usersId" element={<ViewUsers />}/>
				</Route>
				<Route path="*" element={<NotFound />} />
			</Route>

			<Route
				path="processingResult"
				element={<DiagnosticProcessingResultClients />}
			/>
			<Route
				path="resultDiagnostic"
				element={<DiagnosticResultClients />}
			/>

			<Route path="*" element={<NotFound />} />
		</Route>
  </>
)