export const columnsUsers = [
  { field: 'id', headerName: 'ID', width: 70 },
  // { field: 'status', headerName: 'Status', width: 130 },
  { field: 'statusFormated', headerName: 'Status', width: 70 },
  { field: 'name', headerName: 'Nome', width: 130,},
  { field: 'cpf', headerName: 'CPF', width: 125,},
  { field: 'email', headerName: 'E-mail', width: 150,},
  { field: 'fone', headerName: 'Telefone', width: 135,},
  { field: 'nickname', headerName: 'Login', width: 110,},
  // { field: 'changePass', headerName: 'Trocar de senha', width: 50,},
  { field: 'changePassFormated', headerName: 'Trocar de senha', width: 125,},
  // { field: 'createdAt', headerName: 'Criado em', width: 200,}
  { field: 'createdAtFormated', headerName: 'Criado em', width: 160,}
];