import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { api } from "../services/axios_config";
import { useNavigate } from "react-router-dom";

import { apiAccessHistoryUser } from "../services/apiInterface/admin/accessHistoryUser";
import { askForNewTokens } from "../services/apiInterface/auth";
import { useErrorHandler } from "./useErrorHandler";

export default function useAuth() {
	const navigate = useNavigate();
	const { handleError } = useErrorHandler();
	const [authenticated, setAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true);
	const [userLogged, setUserLogged] = useState();

	const checkAuthentication = async () => {
		try {
			console.log("checkAuthentication");
			//Identificar se existe o token
			const token = localStorage.getItem("token");

			if(!token || token === 'undefined'){
				//refresh
				const result = await askForNewTokens();
				if(!result) return;

				localStorage.setItem("token", JSON.stringify(result.newAccessToken));
				// api.headers["Authorization"] = `Bearer ${result.newAccessToken}`;
			}

			const {data: { user }} = await api.post("validateToken");

			// const userInfo = jwt_decode(token);

			setUserLogged(user); // TODO: Guardar apenas o ID do usuário neste state
			setAuthenticated(true);

		} catch (error) {
			console.log('error checkAuthentication')
			handleError(error);
		}
	}

	useEffect(() => {
		checkAuthentication();
		setLoading(false);
	}, []);

	const handleLogin = async (body) => {
		console.log("handleLogin");
		try {
			const {
				data: { ok, msg, token, refreshToken },
			} = await api.post("/auth", body);

			if (!ok) {
				return { success: false, msg: msg };
			}

			const user = jwt_decode(token);

			localStorage.setItem("token", JSON.stringify(token));
			localStorage.setItem("refreshToken", JSON.stringify(refreshToken));

			setUserLogged(user);

			// api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

			setAuthenticated(true);
			setLoading(false);

			if (user.trocar_senha === "1") {
				navigate("/trocarSenha");
			}

			return { success: true };
		} catch (error) {
			handleError(error);
			let msg = "";
			if (!error?.response) {
				msg = "Servidor não está respondendo, contate o administrador!";
			} else if (!error?.response.status === 400) {
				msg = "Não foi passado todas as informações necessárias!";
			} else if (!error?.response.status === 401) {
				msg = "Não autenticado!";
			} else {
				msg = "Falha no Login! Error:" + error.response.message;
			}
			console.log("handleLogin error", error);
			return { success: false, msg: msg };
		}
	};

	const handleLogout = async () => {
		setAuthenticated(false);
		localStorage.removeItem("token");
		localStorage.removeItem("refreshToken");
		api.defaults.headers.common["Authorization"] = undefined;
		setUserLogged(undefined);
		navigate("/");
	};

	const handleChangePass = async (user, pass) => {
		try {
			const body = pass;
			if (user.trocar_senha === "1") {
				body.trocar_senha = "0";
			} else {
				body.trocar_senha = "1";
			}

			const response = await api.put(
				`/cadastros/trocarSenhaUsuarios/${user.id}`,
				body
			);

			if (response.status === 204) {
				if (user.id === userLogged.id) {
					setUserLogged({ ...userLogged, trocar_senha: "0" });
				}
				// alert('Senha atualizada com sucesso!');
				// navigate(link);
			}

			return { success: true, msg: "Senha atualizada com sucesso!" };
		} catch (error) {
			handleError(error);
			return { success: false, msg: error.message };
		}
	};

	const handleChangePassByUser = async (pass) => {
		try {
			const {
				data: { ok, msg, token },
			} = await api.put(
				`/cadastros/atualizarSenha/${userLogged.id}`,
				pass
			);

			if (ok) {
				localStorage.removeItem("token");
				localStorage.setItem("token", JSON.stringify(token));
				const user = jwt_decode(token);
				setUserLogged(user);
			}
			return { ok: ok, msg: msg };
		} catch (error) {
			handleError(error);
			return { ok: false, msg: error.message };
		}
	};

	const handleUpdatePermissions = async () => {
		try {
			const {
				data: { ok, permissions, token: newToken },
			} = await api.get(
				`/cadastros/permissionsTokenByUser/${userLogged.id}`
			);

			if (ok) {
				setUserLogged({ ...userLogged, permissions: permissions });
				localStorage.setItem("token", JSON.stringify(newToken));
				// api.defaults.headers.Authorization = `Bearer ${newToken}`;
				api.defaults.headers.common[
					"Authorization"
				] = `Bearer ${newToken}`;
			}

			return {
				success: true,
				msg: "Permissões atualizadas com sucesso!",
			};
		} catch (error) {
			handleError(error);
			return { success: false, msg: error.message };
		}
	};

	const handleAccessHistory = async (userLoggedId) => {
		try {
			const { ok, msg } = await apiAccessHistoryUser.create(userLoggedId);

			return { ok: ok, msg: msg };
		} catch (error) {
			handleError(error);
			return { ok: false, msg: error.message };
		}
	};

	return {
		loading,
		authenticated,
		handleLogin,
		handleLogout,
		userLogged,
		setUserLogged,
		handleChangePass,
		handleChangePassByUser,
		handleUpdatePermissions,
		// isAuthenticated,
		// getRedirectionRoute
	};
}
