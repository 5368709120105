import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useNavigate } from "react-router-dom";

import { LayoutAdminDefault } from "../../../../../layouts";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { normalizeCPFNumber, normalizePhoneNumber } from "../../../../../utils/masks";
import { apiUsers } from "../../../../../services/apiInterface/admin/users";

export function EditUser() {

  const params = useParams()

  const navigate = useNavigate();

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  
  const schema = yup.object({
    nome: yup.string().required("Campo obrigatório!"),
    cpf: yup.string().required("Campo obrigatório!"),
    email: yup.string().email("E-mail inválido").required("Campo obrigatório!"),
    celular: yup.string().required("Campo obrigatório!"),
    apelido: yup.string().required("Campo obrigatório!")
  }).required();
  
  const { register, handleSubmit, watch, setValue, formState: { errors} } = useForm({ resolver: yupResolver(schema)});

  const handleBack = () => {
    navigate('/usuarios');
  }

  const sendForm = async (user_data) => {
    try {
      const {ok, msg} = await apiUsers.update(user?.id, user_data);

      navigate('/usuarios', {state: {type: ok?'success':'error', msg: msg}});

    } catch (error) {
      navigate('/usuarios', {state: {type:'error', msg: error.message}});
    }
  }

  const handleEditUser = (data) => {
    sendForm(data);
  }

  const celularValue = watch('celular');

  useEffect(()=>{
    setValue('celular', normalizePhoneNumber(celularValue));
  },[celularValue]);

  const cpfValue = watch('cpf');

  useEffect(()=>{
    setValue('cpf', normalizeCPFNumber(cpfValue));
  },[cpfValue]);

  useEffect(()=>{
    (async ()=>{
      try {
        setLoading(true)
        const {ok, newUser: register} = await apiUsers.getOne(params.id);
        if(ok){
          setUser(register);
        }
      } catch (error) {
        alert('Error: ' + error.message);
      } finally{
        setLoading(false)
      }
    })()
  },[]);

  useEffect(()=>{
    setValue('nome', user?.name); 
    setValue('cpf', user?.cpf); 
    setValue('email', user?.email); 
    setValue('celular', user?.fone); 
    setValue('apelido', user?.nickname); 
  },[user]);

  return (
    <LayoutAdminDefault
      title="Editar usuário"
    >
      {!loading ? 
        <>
          <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2}}>
            <FormControl required>
              <InputLabel id="status">Status</InputLabel>
              <Select
                id="status"
                label="Status *"
                defaultValue={user?.status}
                {...register("status", { required: true })}
              >
                <MenuItem value={"1"}>Ativo</MenuItem>
                <MenuItem value={"0"}>Inativo</MenuItem>
              </Select>
            </FormControl>
            
            <TextField
              required
              id="nome"
              label="Nome"
              type="text"
              {...register("nome", { required: true })}
            />
            {errors.nome && <Typography variant="caption" color="#DC1637">{errors.nome?.message}</Typography>}

            <TextField
              required
              id="cpf"
              label="CPF"
              type="text"
              {...register("cpf", { required: true })}
            />
            {errors.cpf && <Typography variant="caption" color="#DC1637">{errors.cpf?.message}</Typography>}

            <TextField
              required
              id="email"
              label="E-mail"
              type="email"
              {...register("email", { required: true })}
            />
            {errors.email && <Typography variant="caption" color="#DC1637">{errors.email?.message}</Typography>}

            <TextField
              required
              id="celular"
              label="Celular"
              type="tel"
              {...register("celular", { required: true })}
            />
            {errors.celular && <Typography variant="caption" color="#DC1637">{errors.celular?.message}</Typography>}

            <TextField
              required
              id="apelido"
              label="Login"
              type="text"
              {...register("apelido", { required: true })}
            />
            {errors.apelido && <Typography variant="caption" color="#DC1637">{errors.apelido?.message}</Typography>}

          </Box>

          <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2}}>
            <Button
              variant="contained" 
              onClick={handleSubmit(handleEditUser)}
              sx={{width:"15vw"}}
              color="primary"
            >
              <Typography sx={{fontSize:"18px"}}>Atualizar</Typography>
            </Button>

            <Button 
              variant="contained" 
              onClick={handleBack}
              sx={{width:"15vw"}}
              color="error"
            >
              <Typography sx={{fontSize:"18px"}}>Voltar</Typography>
            </Button>
          </Box>
        </>
      :
        <Typography>
          Carregando....
        </Typography>
      }
    </LayoutAdminDefault>
  )
}