import React from "react"

import { PageLayout } from "../../../../../../../components"
import { ConsultationContextProvider } from "../../../../contexts"
import { ConsultationTable, ConsultationTableFilter } from "./components"

export function Consultation() {
  return (
    <PageLayout.Container>
			<PageLayout.Header.Container>
				<PageLayout.Header.ButtonBack />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container sx={{width:'800px'}}>
        <PageLayout.Body.Title
          subtitle="Pacientes"
					title="Consultas"
					description="Com nossa plataforma de gestão de negócios, gerenciar seus clientes nunca foi tão fácil. Simplifique sua rotina e concentre-se em oferecer um serviço de excelência aos seus clientes."
				/>
				<ConsultationContextProvider>
          <ConsultationTableFilter/>
          <ConsultationTable/>
        </ConsultationContextProvider>
			</PageLayout.Body.Container>
		</PageLayout.Container>
  )
}