import React, { useEffect, useState } from "react";
import './styles.css';
import { Typography } from "@mui/material";

import Login from "../../../components/Login";
import { ContainerMain, NotClient } from "../../../components";
import { useAuthContext, useAppInfoContext } from "../../../contexts";
import { infoApp } from "../../../services/apiInterface/public/infoApp";
import { useErrorHandler } from "../../../hooks";

export const ClientsLogin = () => {
  const { handleLogin } = useAuthContext();
  const { appLogged, setAppLogged } = useAppInfoContext();

  const { handleError } = useErrorHandler();

  const [subdomainIsValid, setSubdomainIsValid] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
		(async () => {
			try {
				setLoading(true);

				const { ok, client } = await infoApp.getInfoApp();
				
        if (ok) setAppLogged(client);
				setSubdomainIsValid(ok);
				setLoading(false);
			} catch (error) {
        handleError(error);
        setSubdomainIsValid(false);
			}finally{
				setLoading(false);
      }
		})();
	}, []);

  const handleLoginValidate = async (data) =>{
    const { success, msg } = await handleLogin(data);
  }

  return (
    <ContainerMain>
      {!loading ? 
          subdomainIsValid ?
            <Login
              submeterFormulario={handleLoginValidate}
            /> 
            :
            <NotClient/>
        : 
          <Typography>
            Carregando
          </Typography>
      }
    </ContainerMain>
  )
}