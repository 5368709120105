import React from "react";

import { LayoutAdminDefault } from "../../../../../layouts";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apiRoles } from "../../../../../services/apiInterface/admin/roles";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export function AddRole() {

  const navigate = useNavigate();

  const schema = yup.object({
    nome: yup.string().required("Campo obrigatório!"),
    descricao: yup.string().required("Campo obrigatório!")
  }).required();

  const { register, handleSubmit, formState: { errors}, setError } = useForm({resolver: yupResolver(schema)});

  const handleBack = () => {
    navigate('/papeis');
  }

  const sendForm = async (newRole) => {
    try {
      const {ok, msg} = await apiRoles.post(newRole);

      navigate('/papeis', {state: {type: ok ?'success':'error', msg: msg}});

    } catch (error) {
      navigate('/papeis', {state: {type:'error', msg: error.message}});
    }
  }

  const handleAdd = (data) => {
    sendForm(data);
  }

  return (
    <LayoutAdminDefault
      title="Adicionar grupo de acesso"
    >
      <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2}}>
          <TextField
            required
            error={!!errors.name}
            id="nome"
            label="Nome"
            {...register("nome", { required: true })}
          />
          {errors.nome && <Typography variant="caption" color="#DC1637">{errors.nome?.message}</Typography>}

          <TextField
            required
            error={!!errors.description}
            id="descricao"
            label="Descrição"
            {...register("descricao", { required: true })}
          />
          {errors.descricao && <Typography variant="caption" color="#DC1637">{errors.descricao?.message}</Typography>}
      </Box>

      <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2}}>
        <Button
          variant="contained" 
          onClick={handleSubmit(handleAdd)}
          sx={{width:"15vw"}}
          color="primary"
        >
          <Typography sx={{fontSize:"18px"}}>Cadastrar</Typography>
        </Button>

        <Button 
          variant="contained" 
          onClick={handleBack}
          sx={{width:"15vw"}}
          color="error"
        >
          <Typography sx={{fontSize:"18px"}}>Voltar</Typography>
        </Button>
      </Box>
      
    </LayoutAdminDefault>
  )
}