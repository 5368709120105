import { useEffect, useState } from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material"

export default function NavigateQuestions({ questions = [], page = 0, handlePreviousPage = ()=>{}, handleNextPage = ()=>{}, handleGoPage = ()=>{}, isSelected = ()=>{}, isAllSelected = ()=>{}, handleSaveAnswers=()=>{} }){

  const [ allSelected, setAllSelected ] = useState(false);
  
  useEffect(()=>{
    setAllSelected(isAllSelected());
  },[questions]);
  
  return (
    <Box sx={{display:'flex', gap:'20px', height:'40px', justifyContent:'space-between', alignItems:'center', mt:'20px'}}>
      <Button
        sx={{textTransform:'none', width:'250px', border: `2px solid ${page <= 0 ? '#CCC':'#558EE3'}`}}
        startIcon={<ArrowBack />}
        onClick={handlePreviousPage}
        disabled={page <= 0}
      >
        <Typography sx={{fontSize:'16px', fontWeight:600, lineHeight:'150%', color:page <= 0 ? '#CCC':'#3466B2', fontFamily:'Inter'}}>
          Voltar
        </Typography>
      </Button>

      <Box sx={{width:'450px', display:'flex', justifyContent:'center', alignItems:'center', gap:'10px'}}>
        {questions.map((quention, index) => (
            <IconButton
              key={index}
              onClick={()=>{handleGoPage(index)}}
              sx={{
                width: "25px",
                height: "25px",                
                backgroundColor: page === index ? '#3466B2' : (isSelected(quention.id) ? 'green' : '#FFFFFF'),
                ":hover":{
                  backgroundColor:'#99B2D8'
                }
              }}
            >
              <Typography sx={{fontSize:'16px', fontWeight:500, lineHeight:'150%', fontFamily:'Inter', color: page === index ? '#FFFFFF': (isSelected(quention.id) ? '#FFFFFF' : '#3466B2') }}>
                {index + 1}
              </Typography>
            </IconButton>
          )
        )}
      </Box>
      
      <Button
        sx={{textTransform:'none', backgroundColor:'#3466B2', width:'250px'}}
        variant="contained"
        endIcon={<ArrowForward />}
        disabled={allSelected ? false : page === (questions.length - 1)}
        onClick={()=>{
          if(allSelected){
            handleSaveAnswers();
          }else{
            handleNextPage();
          }
        }}
      >
        <Typography sx={{fontSize:'16px', fontWeight:600, lineHeight:'150%', color:'#FFFFFF', fontFamily:'Inter'}}>
          {allSelected ? 'Conhecer seu perfil' : 'Próxima pergunta'}
        </Typography>
      </Button>
    </Box>
  )
}