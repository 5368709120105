import React, { useEffect } from 'react'
import { Box } from "@mui/material"

import { TypographyCustom } from '../../../../components'
import { usePageContext } from '../../../../contexts'
import { usePlanningContext } from '../contexts'

export function ContainerMainLayout({ children, title='', subtitle='', description='', isPageHeaderPrincipalShown=false, isPageHeaderSecondaryShown=false, isPageDrawerShown=true, sx={}, headerCustom }){

  const { setIsPageHeaderPrincipalShown, setIsPageHeaderSecondaryShown } = usePageContext()
  const { setIsPageDrawerShown } = usePlanningContext()

  useEffect(()=>{
    setIsPageHeaderPrincipalShown(isPageHeaderPrincipalShown)
    setIsPageHeaderSecondaryShown(isPageHeaderSecondaryShown)
    setIsPageDrawerShown(isPageDrawerShown)
  },[])

  return (
    <Box sx={{display:'flex', flex:1, flexDirection:'column', backgroundColor:'#f3f4f6'}}>
      {!isPageHeaderPrincipalShown && !isPageHeaderSecondaryShown && headerCustom}
      <Box sx={{ py:'24px', ml:'100px', width:'1056px',display:'flex', flexDirection:'column', ...sx}}>
        <Box sx={{ width:'100%', display:'flex', flexDirection:'column'}}>
          {title !== '' && 
            <TypographyCustom
              text={title}
              type={'body-m'}
              colorText='#374151'
            />
          }
          {subtitle !== '' && 
            <TypographyCustom
              text={subtitle}
              type={'subtitle-m'}
              colorText='#374151'
            />
          }
          {description !== '' && 
            <TypographyCustom
              text={description}
              type={'small-m'}
              colorText='#374151'
              textAlign='justify'
            />
          }
        </Box>
        <Box sx={{width:'100%', height:'100%', display:'flex'}}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}