import React from 'react'
import { Button } from "@mui/material"
import { ArrowBack, ArrowForward } from '@mui/icons-material'

import { TypographyCustom } from "../TypographyCustom"

export const ButtonCustomSubmit = ({ text="", onClick=()=>{}, sx={}, haveIcon=true, disabled=false, haveIconBack=false }) => {
  return (
    <Button
      onClick={onClick}
      variant='contained'
      disabled={disabled}
      sx={{ textTransform:'none', height:'36px', backgroundColor:'#3466B2', borderRadius:'4px', display:'flex', gap:'4px', alignItems:'center', justifyContent:'center', ...sx }}
    >
      {haveIconBack && <ArrowBack style={{color:'#FFFFFF', width:'20px', height:'20px'}}/>}
      <TypographyCustom
        text={text}
        type='label-m'
        colorText='#FFFFFF'
      />
      {haveIcon && <ArrowForward style={{color:'#FFFFFF', width:'20px', height:'20px'}}/>}
    </Button>
  )
}