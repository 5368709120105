import React from "react"
import { Box, Typography } from "@mui/material"

import { BoxLayout } from "../../../../../layouts"
import { TextFieldCustomRadio } from "../../../../../../../../components"

export function TableObjective({ id, control, watch }){

  const optObjective = [
    {
      value: 1,
      label: 'Aumento de Faturamento',
      description: 'Ao escolher o objetivo #BOLD#, as ações deverão ser direcionadas ao aumento das vendas, ou seja, será de extrema importância aumentar o investimento em divulgação através de campanhas, criar políticas de descontos e ter uma equipe de vendas treinada, com boas comissões.',
    },
    {
      value: 2,
      label: 'Redução de Custos',
      description: 'Ao escolher o objetivo #BOLD#, a clínica estará em um cenário no qual os gastos vêm sendo pagos com muita dificuldade. As ações deverão ser direcionadas à redução dos custos fixos e variavéis, ou seja, será fudamental rever acordos comerciais com fornecedores, analisar a eficiência da equipe e controlar o estoque de forma rigorosa.',
    },
    {
      value: 3,
      label: 'Reestruturação do Negócio',
      description: 'Ao escolher o objetivo #BOLD#, as ações de mudança deverão ser profundas e com muita atenção porque a empresa não vem conseguindo arcar com o pagamento de todos os gastos. Os esforços deverão ser direcionados à lucratividade da cliníca através de ações simples, como melhorar os processos diários e renegociar dívidas. Nesse momento, será de extrema importância aderir e executar o plano de reestruturação sugerido pelo nosso Consultor.',
    },
    {
      value: 4,
      label: 'Investimento na Empresa',
      description: 'Ao escolher o objetivo #BOLD# , as ações deverão ser direcionadas para otimizar e padronizar a execução das tarefas do dia a dia. Será necessário elaborar um plano financeiro para o investimento e analisar o possível impacto no aumento dos lucros da empresa. Adquirir novas tecnologias será sempre positivo, pois resultam em eficiência e acréscimo de novos serviços ao portfólio de vendas.',
    },
    {
      value: 5,
      label: 'Expansão do Negócio',
      description: 'Ao escolher o objetivo #BOLD#, será necessário elaborar um estudo de mercado, entender as tendências e os pontos sensíveis para que esse OBJETIVO seja alcançado e tenha bons resultados financeiros à médio e longo prazo. Elaborar o planejamento e ter um fundo para o investimento será imprescindível nesta etapa.',
    },
  ]

  const objectiveValueSelect = watch('objetivo')
  const optionSelect = optObjective.find(item => item.value == objectiveValueSelect)

  const descriptionSplit = optionSelect?.description.split('#BOLD#')

  return (
    <BoxLayout
      id={id}
      title={`${id} - Definição do Objetivo Estratégico`}
      description={'A definição do objetivo estratégico do seu negócio é essencial para o sucesso financeiro e a perenidade da sua empresa. Selecione uma opção para que possamos orientar as suas ações e alcançar os resultados de maneira eficiente.'}
    >
      <Box sx={{ display:'flex', justifyContent:'space-between' }}>
        <TextFieldCustomRadio
          control={control}
          name={'objetivo'}
          options={optObjective}
        />
        {objectiveValueSelect > 0 && 
          <Box sx={{backgroundColor:'#FFFFFF', width:'70%', p:'16px', borderRadius:'8px'}}>
            <Typography sx={{ fontSize:'14px', lineHeight:'130%', color:'#4B5563', fontFamily:'InterRegular', letterSpacing: '0%', textAlign:'justify'}}>
              {descriptionSplit[0]}
              <strong>{optionSelect?.label}</strong>
              {descriptionSplit[1]}
            </Typography>
          </Box>
        }
      </Box>
    </BoxLayout>
  )
}