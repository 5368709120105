import axios from "axios";
import { getConfig } from "../../environment";
import { askForNewTokens } from "../apiInterface/auth";

const REQUEST_TIMEOUT = 15000;

const clearTokenAndReject = (error) => {
	localStorage.removeItem("token");
	localStorage.removeItem("refreshToken");
	return Promise.reject(error);
};

const api = axios.create({
	baseURL: getConfig('URL_API'),
	headers: {
		"Content-Type": "application/json",
	},
});

const apiForRefreshToken = axios.create({
	baseURL: getConfig('URL_API'),
	headers: {
		"Content-Type": "application/json",
	},
});

api.interceptors.request.use(
	(config) => {

		const token = localStorage.getItem("token");
		if(!!token || token !== 'undefined'){
			config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
		}

		config.timeout = REQUEST_TIMEOUT;
		return config;
	},
	(error) => Promise.reject(error)
);

api.interceptors.response.use(
	(response) => response,
	async (error) => {
		const status = error?.response?.status;
		const prevRequest = error?.config;

		if (status === 401) {
			console.log("interceptors");
			if (prevRequest?._retry) return clearTokenAndReject(error);

			try {
				prevRequest._retry = true;

				const refreshToken = localStorage.getItem("refreshToken");

				if (!refreshToken) return clearTokenAndReject(error);

				// Verifica se o refresh token esta expirado
				const parsedRefreshToken = JSON.parse(refreshToken);
				const nowMilliseconds = new Date().getMilliseconds();
				if(nowMilliseconds > parsedRefreshToken.dateExpire){
					console.log("refreshToken expirado");
					return clearTokenAndReject(error);
				}

				// const { data: { token: newAccessToken } } = await apiForRefreshToken.post("/refreshAuth", {id: parsedRefreshToken.id });
				const result = await askForNewTokens();
				if(!result) return clearTokenAndReject(error);

				localStorage.setItem("token", JSON.stringify(result.newAccessToken));

				// prevRequest.headers["Authorization"] = `Bearer ${result.newAccessToken}`;

				// Reenvia a requisição original com o novo token
				return api(prevRequest);
			} catch (errorRefreshToken) {
				console.log('error interceptors')
				return clearTokenAndReject(errorRefreshToken);
			}
		}

		return Promise.reject(error);
	}
);

export { api, apiForRefreshToken };
