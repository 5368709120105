import React from "react"

import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { TableFilterSalesPortfolio, TableSalesPortfolio } from "./components"

export function SalesPortfolio() {

  return (
    <ContainerLayout
      title="Marketing"
      subtitle="Portfólio de vendas"
      description="Com o Portfólio de vendas da nossa plataforma, você pode gerenciar todos os serviços oferecidos pela sua clínica em um só lugar. Adicione, edite e remova serviços com facilidade e mantenha seus clientes sempre atualizados. Além disso, visualize relatórios de desempenho de cada serviço e tome decisões mais estratégicas para o crescimento do seu negócio."
      sx={{
        // width: lockDataSheet ? '1250px' : '1050px'
        width:'800px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <TableFilterSalesPortfolio/>
      <TableSalesPortfolio/>
    </ContainerLayout>
  )
}