import React from "react";
import { Box } from "@mui/system";
import { Divider, IconButton, Paper, TextField, Tooltip, useTheme, Zoom } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RuleIcon from '@mui/icons-material/Rule';
import ClearIcon from '@mui/icons-material/Clear';

import { useNavigate } from "react-router-dom"
import { apiRoles } from "../../../services/apiInterface/admin/roles";
import { useAuthContext } from "../../../contexts";
import { validatePermission } from "../../../utils/tools";

export function ToolsActionsRoles({textSearch, setTextSearch, rowsSelect, onUpdatedRows}){

  const navigate = useNavigate();

  const theme = useTheme();

  const { userLogged } = useAuthContext();

  const handleClearTextSearch = () => {
    setTextSearch('');
  }

  const handleFormAdd = () => {
    navigate('/papeis/adicionar');
  }

  const handleFormEdit = () => {
    navigate(`/papeis/${rowsSelect[0].id}/editar`);
  }

  const handleDeleteRow = async () => {
    for (const row of rowsSelect) {

      if (confirm('Deseja realmente excluir este registro?')) {
        const response = await apiRoles.del(row.id);
        if(response.status === 204){
          onUpdatedRows(old => !old);
          alert(`Registro deletado! \n - Nome: ${row.name}`);
        }else{
          alert(response.data.msg)
        }
      }

    }
  };

  const handleFormAttach = () => {
    navigate(`/papeis/${rowsSelect[0].id}/permissoes`);
  }

  return (
    <Box 
      height={theme.spacing(5)} 
      component={Paper} 
      display="flex" 
      alignItems="center"
      padding={1}
      marginX={1}
      paddingX={2}
      gap={1}
    >
      <Box>
        <TextField 
          label="Procurar..." 
          variant="outlined" 
          size="small"
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value)}
        />
        <Tooltip title="Limpar Pesquisa" TransitionComponent={Zoom}>
          <IconButton onClick={handleClearTextSearch}>
            <ClearIcon/>
          </IconButton>
        </Tooltip>
      </Box>
      <Divider orientation="vertical" variant="middle"/>
      <Box>
        <Tooltip title="Adicionar" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleFormAdd}
            disabled={!validatePermission(userLogged, 'cadastrar_grupo_acesso')}
            component="div"
          >
            <AddBoxIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Editar" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleFormEdit} 
            disabled={!validatePermission(userLogged, 'editar_grupo_acesso') === false ? (rowsSelect.length === 1 ? false: true): true}
            component="div"
          >
            <EditIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Remover" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleDeleteRow}
            disabled={!validatePermission(userLogged, 'excluir_grupo_acesso') === false ? (rowsSelect.length >= 1 ? false: true): true}
            component="div"
          >
            <DeleteForeverIcon/>
          </IconButton>
        </Tooltip>
      </Box>
      <Divider orientation="vertical" variant="middle"/>
      <Box>
        <Tooltip title="Anexar Permissões" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleFormAttach}
            disabled={!validatePermission(userLogged, 'vincular_permissoes') === false ? (rowsSelect.length === 1 ? false: true): true}
            component="div"
          >
            <RuleIcon/>
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}