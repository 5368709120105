import React, { createContext, useContext, useState } from "react"
import { Outlet } from "react-router-dom"

const ConsultationPageContext = createContext()

export const useConsultationPageContext = () => {
	return useContext(ConsultationPageContext)
}

export function ConsultationPageContextProvider() {

  const [ confirmPlan, setConfirmPlan ] = useState(false)
  const [ confirmPayment, setConfirmPayment] = useState(false)

  const [ finishedTreatment, setFinishedTreatment] = useState(false)

  const handleConfirmPlan = () => {
    setConfirmPlan(true)
  }

  const handleCancelPlan = () => {
    if(confirm('Deseja relamente cancelar este plano de tratamento?')){
      setConfirmPlan(false)
    }
  }

  const handleConfirmPayment = () => {
    setConfirmPayment(true)
  }

  const handleConfirmFinishedTreatment = () => {
    setFinishedTreatment(true)
  }

	return (
		<ConsultationPageContext.Provider
			value={{
        confirmPlan,
        confirmPayment,
        handleConfirmPlan,
        handleCancelPlan,
        handleConfirmPayment,
        finishedTreatment,
        handleConfirmFinishedTreatment
			}}
		>
      <Outlet/>
		</ConsultationPageContext.Provider>
	)
}
