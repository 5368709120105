import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Box, Drawer, IconButton, Tooltip } from "@mui/material"

import { ButtonCustomBack, ButtonCustomSubmit, TypographyCustom } from '../components'

export function ContainerDrawerLayout({ children, isOpen=false, title='', subtitle='', onClose=()=>{}, onCancel=()=>{}, onSubmit=()=>{}, textSubmit='', sx={} }){

  return (
    <Drawer
			open={isOpen}
			onClose={onClose}
			anchor="right"
			PaperProps={{
				sx: {
					width: '400px',
					backgroundColor: "#F3F4F6",
          ...sx
				},
			}}
		>
			<Box sx={{ px:'20px', pt:'20px', width:'100%', height:'100vh', display:'flex', flexDirection:'column'}}>
				<Box sx={{ width:'100%', height:'90%', display:'flex', flexDirection:'column', gap:'16px' }}>
					<Box sx={{ width:'100%', display:'flex', flexDirection:'column' }}>
						<Box sx={{ width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
              <TypographyCustom
                text={title}
                type={'body-m'}
                colorText='#374151'
              />
							<Tooltip title={'Fechar'}>
								<IconButton onClick={onClose}>
									<XMarkIcon style={{color: '#4B5563', width:'18px', height:'18px'}}/>
								</IconButton>
							</Tooltip>
						</Box>
            <TypographyCustom
              text={subtitle}
              type={'subtitle-b-v2'}
              colorText='#374151'
            />
					</Box>
					<Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'15px'}}>
            {children}
						<Box sx={{ width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between', gap:'20px', mb:'40px' }}>
							<ButtonCustomBack
								text={'Cancelar'}
								onClick={onCancel}
							/>
							<ButtonCustomSubmit
								text={textSubmit}
								haveIcon={false}
								onClick={onSubmit}
								sx={{
									width:'300px',
									height:'36px'
								}}
							/>
						</Box>
          </Box>
				</Box>				
			</Box>
		</Drawer>
  )
}