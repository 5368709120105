import {
	Box,
	Button,
	Divider,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import {
	AccordionCustom,
	ButtonCustomSubmit,
	Notes,
	PageLayout,
	ProfileInfo,
} from "../../../../../../components";
import { assets } from "../../../../../../assets/images_sys";
import {
	ArrowPathIcon,
	CalendarIcon,
	PhoneIcon,
} from "@heroicons/react/20/solid";
import { useConsultationPageContext } from "../../../contexts";
import { useNavigate } from "react-router-dom"

export function TreatmentToxinaBotulinica() {

	const { handleConfirmFinishedTreatment, finishedTreatment  } = useConsultationPageContext()

	const navigate = useNavigate()

	const handleFinished = () => {
		handleConfirmFinishedTreatment()
		navigate(`../../assessment`)
	}

	return (
		<PageLayout.Container>
			<PageLayout.Header.Container>
				<PageLayout.Header.ButtonBack />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container
				sx={{ maxWidth: "1056px", gap: "8px", paddingBottom:'40px' }}
			>
				{/* <ProfileInfo
					fullName="Lívia Silva"
					dateAndAge="18/02/1996 • 27 anos"
				/> */}
				<PageLayout.Body.Title
					subtitle="Consulta / Plano de Tratamentos / Tratamento"
					title="Prontuário: Toxina Botulínica (1ª sessão)"
					description="A toxina botulínica é indicada para amenizar linhas de
					expressão e rugas profundas. Por exemplo, as linhas
					verticais entre as sobrancelhas; pés-de-galinha nos
					cantos dos olhos; linhas horizontais na testa e nas
					bandas do músculo platisma, conhecido como pescoço de
					peru."
				/>
				{/* <Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "8px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Typography
							variant="title-h4-v2"
							sx={{ color: "#09234A" }}
						>
							Anamnese / Toxina Botulínica (2ª sessão)
						</Typography>
						<Typography
							variant="subtitle-m"
							sx={{ color: "#09234A" }}
						>
							15/09/2023
						</Typography>
					</Box>
					<Typography variant="body-r-v2" sx={{ color: "#374151" }}>
						A toxina botulínica é indicada para amenizar linhas de
						expressão e rugas profundas. Por exemplo, as linhas
						verticais entre as sobrancelhas; pés-de-galinha nos
						cantos dos olhos; linhas horizontais na testa e nas
						bandas do músculo platisma, conhecido como pescoço de
						peru.
					</Typography>
				</Box> */}
				<Box
					sx={{
						padding: "24px",
						display: "flex",
						backgroundColor: "#FFF8F1",
						border: "1px solid #FFDBB4",
						borderRadius: "4px",
						alignItems: "start",
						gap: "12px",
					}}
				>
					<Box
						sx={{
							padding: "8px",
							backgroundColor: "#FFDBB4",
							borderRadius: "50%",
							display: "flex",
							alignItems: "center",
						}}
					>
						<ArrowPathIcon
							style={{
								width: "24px",
								height: "24px",
								color: "#09234A",
							}}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "4px",
						}}
					>
						<Typography variant="big-b" sx={{ color: "#09234A" }}>
							Lembrete de Renovação: Garanta resultados duradouros
						</Typography>
						<Typography variant="body-m" sx={{ color: "#09234A" }}>
							A eficácia do procedimento de Toxina Botulínica
							realizado na paciente em 21 de Março de 2023 está
							chegando ao seu prazo de validade, que é de 6 meses.
							Para manter os resultados deslumbrantes e a
							satisfação da paciente, é o momento ideal para
							convidá-la a agendar uma nova sessão.
						</Typography>
						<Box
							sx={{
								marginTop: "12px",
								display: "flex",
								gap: "8px",
								alignItems:'center'
							}}
						>
							<Box>
								<ButtonCustomSubmit
									text={'Enviar mensagem para Lívia'}
									sx={{ paddingY: "10px", paddingX: "18px" }}
									haveIcon={false}
								/>
							</Box>
							<Box>
								<Button
									variant="text"
									startIcon={
										<PhoneIcon
											style={{
												width: "16px",
												height: "16px",
											}}
										/>
									}
								>
									<Typography
										variant="button-m-v2"
										sx={{ color: "#1A417D" }}
									>
										Ligar para Lívia
									</Typography>
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
				<AccordionCustom headerTitle="Anamnese">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				{/* <AccordionCustom headerTitle="Histórico clínico">
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "24px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "16px",
							}}
						>
							<Typography variant="big-m">
								Está atualmente sob tratamento médico? Se sim,
								qual o motivo?
							</Typography>
							<FormControl>
								<RadioGroup>
									<FormControlLabel
										value={false}
										control={<Radio />}
										label={
											<Typography variant="inputText-r-l">
												Não
											</Typography>
										}
									/>
									<FormControlLabel
										value={true}
										control={<Radio />}
										label={
											<Typography variant="inputText-r-l">
												Sim
											</Typography>
										}
									/>
								</RadioGroup>
							</FormControl>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "16px",
							}}
						>
							<Typography variant="big-m">
								Está tomando algum medicamento
								(anti-inflamatório, antibiótico, corticoide,
								etc.)? Se sim, qual o motivo?
							</Typography>
							<FormControl>
								<RadioGroup>
									<FormControlLabel
										value={false}
										control={<Radio />}
										label={
											<Typography variant="inputText-r-l">
												Não
											</Typography>
										}
									/>
									<FormControlLabel
										value={true}
										control={<Radio />}
										label={
											<Typography variant="inputText-r-l">
												Sim
											</Typography>
										}
									/>
								</RadioGroup>
							</FormControl>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "16px",
							}}
						>
							<Typography variant="big-m">
								Tem alergia a algum medicamento? Se sim,
								especifique qual.
							</Typography>
							<FormControl>
								<RadioGroup>
									<FormControlLabel
										value={false}
										control={<Radio />}
										label={
											<Typography variant="inputText-r-l">
												Não
											</Typography>
										}
									/>
									<FormControlLabel
										value={true}
										control={<Radio />}
										label={
											<Typography variant="inputText-r-l">
												Sim
											</Typography>
										}
									/>
								</RadioGroup>
							</FormControl>
						</Box>
						<Box
							sx={{
								paddingX: "20px",
								paddingY: "12px",
								display: "flex",
								backgroundColor: "#F9FAFB",
								border: "1px solid #6B7280",
								borderRadius: "8px",
							}}
						>
							<Typography
								variant="inputText-r-l"
								sx={{ color: "#374151", lineHeight: "24px" }}
							>
								Tenho alergia a penicilina. Descobri essa
								alergia na adolescência, quando precisei tomar
								um medicamento contendo penicilina e tive uma
								reação alérgica grave.
							</Typography>
						</Box>
					</Box>
				</AccordionCustom> */}
				{/* <AccordionCustom headerTitle="Termo de consentimento">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom> */}
				<AccordionCustom headerTitle="Plano de Aplicação">
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "32px",
							paddingY: "24px",
						}}
					>
						<Typography variant="big-b" sx={{ color: "#374151" }}>
							Análise facial
						</Typography>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								border: "solid 1px #D1D5DB",
								borderRadius: "12px",
								gap: "8px",
							}}
						>
							<FormControl>
								<RadioGroup>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											padding: "24px",
											gap: "24px",
										}}
									>
										<Typography
											variant="body-m"
											sx={{ color: "#09234A" }}
										>
											Rugas:
										</Typography>
										<Box
											sx={{
												display: "flex",
												flexWrap: "wrap",
												gap: "8px",
											}}
										>
											<FormControlLabel
												value="rugas-discretas"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Discretas
													</Typography>
												}
											/>
											<FormControlLabel
												value="rugas-moderada"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Moderada
													</Typography>
												}
											/>
											<FormControlLabel
												value="rugas-avancadas"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Avançadas
													</Typography>
												}
											/>
											<FormControlLabel
												value="rugas-dinamicas"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Dinâmicas
													</Typography>
												}
											/>
											<FormControlLabel
												value="rugas-estaticas"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Estáticas
													</Typography>
												}
											/>
										</Box>
									</Box>
								</RadioGroup>
							</FormControl>
							<Divider />
							<FormControl>
								<RadioGroup>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											padding: "24px",
											gap: "24px",
										}}
									>
										<Typography
											variant="body-m"
											sx={{ color: "#09234A" }}
										>
											Flacidez de pele:
										</Typography>
										<Box
											sx={{
												display: "flex",
												flexWrap: "wrap",
												gap: "8px",
											}}
										>
											<FormControlLabel
												value="flacidezpele-leve"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Leve
													</Typography>
												}
											/>
											<FormControlLabel
												value="flacidezpele-moderada"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Moderada
													</Typography>
												}
											/>
											<FormControlLabel
												value="flacidezpele-intensa"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Intensa
													</Typography>
												}
											/>
											<FormControlLabel
												value="flacidezpele-tissular"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Tissular
													</Typography>
												}
											/>
											<FormControlLabel
												value="flacidezpele-muscular"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Muscular
													</Typography>
												}
											/>
										</Box>
									</Box>
								</RadioGroup>
							</FormControl>
							<Divider />
							<FormControl>
								<RadioGroup>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											padding: "24px",
											gap: "24px",
										}}
									>
										<Typography
											variant="body-m"
											sx={{ color: "#09234A" }}
										>
											Tocar a pele:
										</Typography>
										<Box
											sx={{
												display: "flex",
												flexWrap: "wrap",
												gap: "8px",
											}}
										>
											<FormControlLabel
												value="tocarpele-lisa"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Lisa
													</Typography>
												}
											/>
											<FormControlLabel
												value="tocarpele-aspera"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Áspera
													</Typography>
												}
											/>
											<FormControlLabel
												value="tocarpele-fina"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Fina
													</Typography>
												}
											/>
											<FormControlLabel
												value="tocarpele-espessa"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Espessa
													</Typography>
												}
											/>
											<FormControlLabel
												value="tocarpele-rugosa"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Rugosa
													</Typography>
												}
											/>
										</Box>
									</Box>
								</RadioGroup>
							</FormControl>
							<Divider />
							<FormControl>
								<RadioGroup>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											padding: "24px",
											gap: "24px",
										}}
									>
										<Typography
											variant="body-m"
											sx={{ color: "#09234A" }}
										>
											Estado cutâneo:
										</Typography>
										<Box
											sx={{
												display: "flex",
												flexWrap: "wrap",
												gap: "8px",
											}}
										>
											<FormControlLabel
												value="estadocutaneo-normal"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Normal
													</Typography>
												}
											/>
											<FormControlLabel
												value="estadocutaneo-sensivel"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Sensível
													</Typography>
												}
											/>
											<FormControlLabel
												value="estadocutaneo-desidratada"
												control={<Radio />}
												label={
													<Typography
														variant="inputText-r-l"
														sx={{
															color: "#1F2937",
														}}
													>
														Desidratada
													</Typography>
												}
											/>
										</Box>
									</Box>
								</RadioGroup>
							</FormControl>
							<Divider />
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									padding: "24px",
								}}
							>
								<Notes />
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Typography
								variant="big-b"
								sx={{ color: "#374151" }}
							>
								Toxina Botulínica
							</Typography>
							<Typography
								variant="big-r"
								sx={{ color: "#374151" }}
							>
								Data de aplicação: 08/09/2023
							</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								border: "solid 1px #D1D5DB",
								borderRadius: "12px",
								gap: "8px",
							}}
						>
							<Box
								sx={{
									padding: "24px",
									display: "flex",
									alignItems: "start",
									justifyContent: "space-between",
									gap: "32px",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flex: 0.5,
										flexDirection: "column",
										gap: "8px",
									}}
								>
									<Box
										sx={{
											display: "flex",
											flex: 1,
											justifyContent: "center",
										}}
									>
										<img
											src={assets.imgs.human_body}
											alt="human_body"
										/>
									</Box>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											backgroundColor: "#F9FAFB",
											border: "solid 1px #D1D5DB",
											paddingX: "24px",
											paddingY: "12px",
											borderRadius: "12px",
										}}
									>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>
														<Typography
															variant="body-b"
															sx={{
																color: "#4B5563",
																textAlign:
																	"center",
															}}
														>
															Valor
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															variant="body-b"
															sx={{
																color: "#4B5563",
															}}
														>
															Unidade
														</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell align="center">
														<TextField
															variant="outlined"
															sx={{
																maxWidth:
																	"96px",
															}}
														/>
													</TableCell>
													<TableCell>
														<Typography
															variant="body-r-v2"
															sx={{
																color: "#09234A",
															}}
														>
															U Frontal
														</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="center">
														<TextField
															variant="outlined"
															sx={{
																maxWidth:
																	"96px",
															}}
														/>
													</TableCell>
													<TableCell>
														<Typography
															variant="body-r-v2"
															sx={{
																color: "#09234A",
															}}
														>
															U Corrugador
														</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="center">
														<TextField
															variant="outlined"
															sx={{
																maxWidth:
																	"96px",
															}}
														/>
													</TableCell>
													<TableCell>
														<Typography
															variant="body-r-v2"
															sx={{
																color: "#09234A",
															}}
														>
															U Prócero
														</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="center">
														<TextField
															variant="outlined"
															sx={{
																maxWidth:
																	"96px",
															}}
														/>
													</TableCell>
													<TableCell>
														<Typography
															variant="body-r-v2"
															sx={{
																color: "#09234A",
															}}
														>
															U Orbicular do Olho
															(pés de galinha)
														</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="center">
														<TextField
															variant="outlined"
															sx={{
																maxWidth:
																	"96px",
															}}
														/>
													</TableCell>
													<TableCell>
														<Typography
															variant="body-r-v2"
															sx={{
																color: "#09234A",
															}}
														>
															U Nasal (nariz)
														</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="center">
														<TextField
															variant="outlined"
															sx={{
																maxWidth:
																	"96px",
															}}
														/>
													</TableCell>
													<TableCell>
														<Typography
															variant="body-r-v2"
															sx={{
																color: "#09234A",
															}}
														>
															U Orbicular da boca
															(rugas labiais)
														</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="center">
														<TextField
															variant="outlined"
															sx={{
																maxWidth:
																	"96px",
															}}
														/>
													</TableCell>
													<TableCell>
														<Typography
															variant="body-r-v2"
															sx={{
																color: "#09234A",
															}}
														>
															U Mentual (queixo)
														</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="center">
														<TextField
															variant="outlined"
															sx={{
																maxWidth:
																	"96px",
															}}
														/>
													</TableCell>
													<TableCell>
														<Typography
															variant="body-r-v2"
															sx={{
																color: "#09234A",
															}}
														>
															U Outras
														</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="center">
														<Typography
															variant="body-b"
															sx={{
																color: "#09234A",
																textAlign:
																	"center",
															}}
														>
															90
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															variant="body-b"
															sx={{
																color: "#09234A",
															}}
														>
															U Total de unidades
															injetadas
														</Typography>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</Box>
								</Box>
								<Box
									sx={{
										display: "flex",
										flex: 0.5,
										flexDirection: "column",
										gap: "20px",
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											gap: "24px",
											justifyContent: "space-between",
										}}
									>
										<TextField
											variant="outlined"
											label="Marca comercial"
										/>
										<TextField
											variant="outlined"
											label="Validade"
										/>
									</Box>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											gap: "24px",
											justifyContent: "space-between",
										}}
									>
										<TextField
											variant="outlined"
											label="Número de Lote"
										/>
										<TextField
											variant="outlined"
											label="Reconstituição"
										/>
									</Box>
									<Typography
										variant="label-l"
										sx={{ color: "#374151" }}
									>
										Observações sobre o procedimento
									</Typography>
									<Box
										sx={{
											paddingX: "16px",
											paddingY: "24px",
											display: "flex",
											flexDirection: "column",
											border: "1px solid #6B7280",
											borderRadius: "8px",
											gap: "24px",
										}}
									>
										<Typography
											variant="inputText-r-l"
											sx={{ color: "#374151" }}
										>
											• A paciente demonstrou
											sensibilidade ao redor dos olhos
											durante a aplicação, portanto,
											fizemos uma injeção mais superficial
											nessa área para minimizar o
											desconforto.
										</Typography>
										<Typography
											variant="inputText-r-l"
											sx={{ color: "#374151" }}
										>
											• A paciente expressou preocupação
											com a possibilidade de sobrancelhas
											excessivamente arqueadas. Optei por
											usar uma dose menor na região
											frontal para um resultado mais
											natural.
										</Typography>
										<Typography
											variant="inputText-r-l"
											sx={{ color: "#374151" }}
										>
											• A paciente relatou um evento
											especial em duas semanas. Recomendei
											que ela agendasse a aplicação com
											tempo suficiente para permitir que
											os resultados se estabeleçam antes
											do evento.
										</Typography>
										<Typography
											variant="inputText-r-l"
											sx={{ color: "#374151" }}
										>
											• A paciente indicou que já
											experimentou uma reação alérgica a
											produtos contendo albumina.
											Utilizamos uma alternativa que não
											contém esse componente.
										</Typography>
										<Typography
											variant="inputText-r-l"
											sx={{ color: "#374151" }}
										>
											• A paciente mencionou histórico de
											cefaleia tensional. Discuti a
											possibilidade de usar Toxina
											Botulínica para o tratamento da
											cefaleia em futuro próximo.
										</Typography>
										<Typography
											variant="inputText-r-l"
											sx={{ color: "#374151" }}
										>
											• Expliquei à paciente os cuidados
											pós-tratamento, incluindo evitar
											deitar-se por pelo menos quatro
											horas após a aplicação e evitar
											massagem facial nas primeiras 24
											horas.
										</Typography>
									</Box>
									<Box sx={{ display: "flex", gap: "8px" }}>
										<Box>
											<Button
												variant="contained"
												onClick={() => {}}
											>
												<Typography variant="button-m-v2">
													Salvar e continuar
												</Typography>
											</Button>
										</Box>
										<Box>
											<Button
												variant="text"
												onClick={() => {}}
												startIcon={
													<CalendarIcon
														style={{
															width: "16px",
															height: "16px",
															color: "#1A417D",
														}}
													/>
												}
											>
												<Typography variant="button-m-v2">
													Agendar retorno
												</Typography>
											</Button>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</AccordionCustom>
				<AccordionCustom headerTitle="Toxina Botulínica - Fotos">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				<AccordionCustom headerTitle="Receita">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				<AccordionCustom headerTitle="Pós Tratamento">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				{finishedTreatment && 
					<>
						<Typography>Observação</Typography>
						<TextField />
					</>
				}
				<AccordionCustom headerTitle="Termos de Autorização">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				<AccordionCustom headerTitle="POP">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				<Box>
					<ButtonCustomSubmit
						text={'Concluir tratamento'}
						onClick={handleFinished}
						sx={{ paddingY: "10px", paddingX: "18px" }}
					/>
				</Box>
			</PageLayout.Body.Container>
		</PageLayout.Container>
	);
}
