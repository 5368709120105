import React from "react"

import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { TableFilterUsers, TableUsers } from "./components"

export function Users() {
  // const navigate = useNavigate()

  // const { loading, users, handleAdd, handleRemoveById, statusTable, getAllByFilter } = useUsers()

  // const [openDrawerFormAdd, setOpenDrawerFormAdd] = useState(false)

  // const handleCloseDrawerFormAdd = () => {
  //   setOpenDrawerFormAdd(false)
  // }

  // const handleOpenDrawerFormAdd = () => {
  //   setOpenDrawerFormAdd(true)
  // }

  // const StyleButtonAdd = () => {
  //   if(users.length > 0){
  //     return (
  //       <Button
  //         onClick={handleOpenDrawerFormAdd}
  //         sx={{textTransform:'none', width:'247px', height:'45px', backgroundColor:'#FFFFFF', border: '2px solid #558EE3'}} 
  //         startIcon={<AddIcon sx={{color: '#558EE3'}}/>}
  //       >
  //         <Typography sx={{ fontSize:'16px', fontWeight:600, lineHeight:'150%', color:'#558EE3', fontFamily:'Inter'}}>
  //           Adicionar usuário
  //         </Typography>
  //       </Button>
  //     )
  //   }else{
  //     return (
  //       <Button
  //         variant="contained"
  //         onClick={handleOpenDrawerFormAdd}
  //         sx={{textTransform:'none', width:'247px', height:'45px', backgroundColor:'#3466B2'}} 
  //         startIcon={<AddIcon sx={{color: '#FFFFFF'}}/>}
  //       >
  //         <Typography sx={{ fontSize:'16px', fontWeight:600, lineHeight:'150%', color:'#FFFFFF', fontFamily:'Inter'}}>
  //           Adicionar usuário
  //         </Typography>
  //       </Button>
  //     )
  //   }
  // }

  return (
    <ContainerLayout
      title="Configurações"
      subtitle="Usuários"
      description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      sx={{
        width:'850px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <TableFilterUsers/>
      <TableUsers/>
      {/* {!loading ?
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
          {users.length > 0 && 
            <TableCustom
              rows={users}
              statusTable={statusTable}
              handlePagination={getAllByFilter}
              handleRemove={handleRemoveById}
              handleNavigateEditById={(id)=>{navigate(`${id}`)}}
            />
          }

          <Box sx={{width:'100%', display:'flex'}}>
            <StyleButtonAdd/>

            {users.length > 0 &&
              <Button
                onClick={()=>{navigate('/diagnostic/menu')}}
                variant="contained"
                sx={{textTransform:'none', ml:'24px', width:'247px', height:'45px', backgroundColor:'#3466B2'}} 
                endIcon={<ArrowForwardIcon sx={{color: '#FFFFFF'}}/>}
              >
                <Typography sx={{ fontSize:'16px', fontWeight:600, lineHeight:'150%', color:'#FFFFFF', fontFamily:'Inter'}}>
                  Continuar
                </Typography>
              </Button>
            }
          </Box>
          <FormUserAdd
            isOpen={openDrawerFormAdd}
            onCloseCall={handleCloseDrawerFormAdd}
            submitForm={handleAdd}
          />
        </Box>
      :
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      } */}

    </ContainerLayout>
  )
}