import {
	ArrowRightIcon,
	CalendarIcon,
	CheckIcon,
	ClipboardDocumentListIcon,
	EllipsisVerticalIcon,
} from "@heroicons/react/20/solid";
import { Avatar, Box, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function ScheduledVisitsItem({ preConsultation = false, date="" }) {
	const navigate = useNavigate();

	const handleGoToClinicalHistory = () => navigate("pre-consultation/clinical-history");
	const handleGoToConsultation = () => navigate("consultation/assessment");

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				borderWidth: "1px",
				borderStyle: "solid",
				borderRadius: "10px",
				borderColor: "#1A417D",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
					backgroundColor: "#EFF5FF",
					padding: "20px",
					borderTopLeftRadius: "10px",
					borderTopRightRadius: "10px",
					gap: "8px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flex: 1,
						alignItems: "start",
						justifyContent: "space-between",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "12px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
							}}
						>
							<CalendarIcon
								style={{
									width: "20px",
									height: "20px",
									color: "#3466B2",
								}}
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "10px",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Typography
									variant="small-r-v2"
									sx={{ color: "#3466B2" }}
								>
									{date}
								</Typography>
								<Typography
									variant="big-m"
									sx={{ color: "#374151" }}
								>
									Consulta Geral (visita inicial)
								</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: "10px",
								}}
							>
								<Avatar
									sx={{
										borderWidth: "2px",
										borderColor: "#09234A",
										borderStyle: "solid",
										width: "38px",
										height: "38px",
									}}
								/>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<Typography
										variant="body-m"
										sx={{ color: "#09234A" }}
									>
										Giovana Moraes
									</Typography>
									<Typography
										variant="small-r-v2"
										sx={{ color: "#09234A" }}
									>
										Dermatologia estética
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
					<IconButton>
						<EllipsisVerticalIcon
							style={{
								width: "20px",
								height: "20px",
								color: "#3466B2",
							}}
						/>
					</IconButton>
				</Box>
				{!preConsultation && (
					<>
						<Divider sx={{ color: "#558EE3" }} />
						<Box
							sx={{
								marginLeft: "40px",
								display: "flex",
								flexDirection: "column",
								gap: "4px",
							}}
						>
							<Box
								sx={{
									display: "flex",
									gap: "8px",
									alignItems: "center",
								}}
							>
								<CheckIcon
									style={{
										width: "16px",
										height: "16px",
										color: "#09234A",
									}}
								/>
								<Typography
									variant="body-m"
									sx={{ color: "#09234A" }}
								>
									Histórico Clínico - 08/12/2023
								</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									gap: "8px",
									alignItems: "center",
								}}
							>
								<CheckIcon
									style={{
										width: "16px",
										height: "16px",
										color: "#09234A",
									}}
								/>
								<Typography
									variant="body-m"
									sx={{ color: "#09234A" }}
								>
									Queixas - 08/12/2023
								</Typography>
							</Box>
						</Box>
					</>
				)}
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					flex: 1,
					justifyContent: "space-between",
					backgroundColor: "#09234A",
					paddingX: "24px",
					paddingY: "12px",
					borderBottomLeftRadius: "8px",
					borderBottomRightRadius: "8px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: "15px",
					}}
				>
					<Box>
						<ClipboardDocumentListIcon
							style={{
								width: "32px",
								height: "32px",
								color: "#FFFFFF",
							}}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography variant="small-r-v2" sx={{ color: "#FFFFFF" }}>
							{preConsultation
								? "Preencher o Histórico Clínico e as Queixas"
								: "Iniciar consulta geral (visita inicial)"}
						</Typography>
						{preConsultation && (
							<Typography
								variant="body-r-v2"
								sx={{ color: "#FFFFFF" }}
							>
								Para primeira visita da Lívia precisamos
								solicitar que ela preencha algumas informações
							</Typography>
						)}
					</Box>
				</Box>
				<Box sx={{ display: "flex" }}>
					<IconButton
						onClick={() =>
							preConsultation
								? handleGoToClinicalHistory()
								: handleGoToConsultation()
						}
					>
						<ArrowRightIcon
							style={{
								width: "20px",
								height: "20px",
								color: "#FFFFFF",
							}}
						/>
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
}
