import { getConfig } from "../environment";
import { APPS_DEFAULT, APP_CLIENT } from "./subDomains";

// const getSubdomainDev = (location) => {
//   const locationSplit = location.split('.');

//   console.log('Environment.ENV ', Environment.ENV )
//   console.log('locationSplit', locationSplit)
//   console.log('locationSplit.slice(-1)', locationSplit.slice(-1))
//   console.log('locationSplit.slice(-1)[0]', locationSplit.slice(-1)[0])

//   let sliceTill = -2;
//   const isLocalhost = locationSplit.slice(-1)[0] === Environment.DOMAIN_APP;
//   if(isLocalhost){
//     sliceTill = -1;
//   }
//   return locationSplit.slice(0, sliceTill).join("");
// }

// const getSubdomainHmlProd = (location) => {
//   // Regra pra hml e prod
//   const locationSplit = location.split('.');

//   console.log('Environment.ENV ', Environment.ENV)
//   console.log('locationSplit', locationSplit)
//   console.log('locationSplit.slice(-3)', locationSplit.slice(-3))
//   console.log('locationSplit.slice(-3)[2]', locationSplit.slice(-3)[2])

//   let subdomain = "";
//   if(locationSplit.length === 4 && locationSplit.slice(-3)[0] === Environment.DOMAIN_APP){
//     subdomain = locationSplit[0];
//   }

//   return subdomain;
// }

const getSubdomain = (location) => {
  const locationSplit = location.split('.');

  let positionDomain = 0;
  for (let i = 0; i < locationSplit.length; i++) {
    if(locationSplit[i] === getConfig('DOMAIN_APP_NAME')){
      positionDomain = i;
    }
  }

  let subdomain = "";
  if(positionDomain === 0){
    subdomain = locationSplit[positionDomain];
  }else{
    subdomain = locationSplit[positionDomain-1];
  }

  return subdomain;
}

export const getRouterApp = () => {
  const subdomain = getSubdomain(window.location.hostname);

  const main = APPS_DEFAULT.find(app => app.main);

  if(!main) throw new Error('Deve possuir a pagina inicial');

  if(subdomain === getConfig('DOMAIN_APP_NAME')){
    return main.app;
  }

  const app = APPS_DEFAULT.find(app => subdomain === app.subdomain);

  if(!app){
    return APP_CLIENT;
  }

  return app.app;
}
