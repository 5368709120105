import React, { useState } from "react";
import { TableCustomComposition } from "../../../../../../../components";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";

export default function TableCustomPatients({ rows, columns }) {
	const navigate = useNavigate();

	const [filters, setFilters] = useState({});

	const [formPatientMode, setFormPatientMode] = useState("add");
	const [formPatientOpen, setFormPatientOpen] = useState(false);

	const handleOpenFormPatient = () => setFormPatientOpen(true);
	const handleCloseFormPatient = () => setFormPatientOpen(false);

	const handleOnClickAddPatient = () => {
		setFormPatientMode("add");
		handleOpenFormPatient();
	};

	const handleOnClickPatient = () => {
		setFormPatientMode("view");
		handleOpenFormPatient();
	};

	const handlePatientEdit = (data) => {
		const { id } = data;

		console.log(data);

		if (!id) return;

		navigate(`/business/patients/${id}`);
	};

	const handleFilter = (data) => {
		console.log(data);
	};

	const formatRows = (oldRows) => {
		const CellName = ({ id, name }) => {
			return (
				<Button
					variant="text"
					onClick={() => navigate(`/business/patients/${id}`)}
					sx={{
						display: "flex",
						gap: "8px",
						alignItems: "center",
					}}
				>
					<Avatar sx={{ width: "32px", height: "32px" }} />
					<Typography variant="body-m" sx={{ color: "#3466B2" }}>
						{name}
					</Typography>
				</Button>
			);
		};

		const CellActions = ({ id }) => {
			return (
				<Box sx={{ display: "flex", gap: "10px" }}>
					<Button variant="text">
						<Typography variant="button-m">Remover</Typography>
					</Button>
				</Box>
			);
		};

		let newRows = [];
		oldRows.forEach((row) => {
			let newRow = {
				...row,
				nome: <CellName id={row.id} name={row.nome} />,
				actions: <CellActions id={row.id} />,
			};

			newRow = newRows.push(newRow);
		});

		return newRows;
	};

	return (
		<TableCustomComposition.Container rows={formatRows(rows)} columns={columns}>
			<TableCustomComposition.Header.ButtonFilter
				handleOnClickFilter={handleFilter}
			/>
			<TableCustomComposition.Header.Filters />
			<TableCustomComposition.Body.Table />
			<TableCustomComposition.Footer.Pagination paginationStatus={{}} />
		</TableCustomComposition.Container>
	);
}
