import { useEffect } from "react"
import { Box } from "@mui/material"

import { PageLayout } from "../../../../../components"
import TableCustomPatients from "./components/TableCustomPatients"
import { useBusinessPageContext } from "../../../../../contexts/BusinessPageContext"

export function BusinessPatients() {
	const { setIsPageDrawerShown } = useBusinessPageContext()

	useEffect(() => {
		setIsPageDrawerShown(true)
	}, [])

	const dataRows = [
		{
			id: 1,
			nome: "Silva Lívia",
			idade: "25",
			telefone: "(99) 9 1234-5678",
			email: "liviasilva@email.com",
			cidade: "São Paulo",
		},
		{
			id: 2,
			nome: "Lívia Silva",
			telefone: "(99) 9 1234-5678",
			email: "liviasilva@email.com",
			cidade: "São Paulo",
			idade: "23",
		},
		{
			id: 3,
			nome: "Silva Silva",
			email: "liviasilva@email.com",
			idade: "20",
			telefone: "(99) 9 1234-5678",
			cidade: "São Paulo",
		},
	]

	const dataColumns = [
		{ id: "id", type: "none" },
		{ id: "nome", type: "text", name: "Nome", field: "Nome" },
		{ id: "idade", type: "number", name: "Idade", field: "Idade" },
		{ id: "telefone", type: "phone", name: "Telefone", field: "Telefone" },
		{ id: "email", type: "text", name: "E-mail", field: "E-mail" },
		{ id: "cidade", type: "text", name: "Cidade", field: "Cidade" },
		{ id: "actions", type: "action", name: "Ações", field: "Ações" },
	]

	return (
		<PageLayout.Container>
			{/* <DrawerFormPatient
				open={formPatientOpen}
				mode={formPatientMode}
				handleClose={handleCloseFormPatient}
			/> */}
			<PageLayout.Header.Container>
				<PageLayout.Header.ButtonBack />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container sx={{ maxWidth: "1056px" }}>
				<PageLayout.Body.Title
					title="Gestão de Pacientes"
					description="Com nossa plataforma de gestão de negócios, gerenciar seus clientes nunca foi tão fácil. Simplifique sua rotina e concentre-se em oferecer um serviço de excelência aos seus clientes."
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "12px",
					}}
				>
					<TableCustomPatients
						rows={dataRows}
						columns={dataColumns}
					/>
				</Box>
			</PageLayout.Body.Container>
		</PageLayout.Container>
	)
}
