import React from "react"

import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { TableActive, TableFilterActive } from "./components"

export function Active() {
  return (
    <ContainerLayout
      title="Operação"
      subtitle="Gestão de Ativos"
      description="Cadastre os ativos da sua clínica de estética. Dessa forma, você terá um controle completo dos seus recursos e poderá gerenciá-los de maneira eficiente."
      sx={{
        width:'800px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <TableFilterActive/>
      <TableActive/>
    </ContainerLayout>
  )
}