import { Box, Typography } from "@mui/material";
import { ButtonCustomSubmit, PageLayout, TextFieldCustomFile } from "../../../../../components";
import { useNavigate } from "react-router-dom";

export function BusinessPatientPreConsultationPhotos() {
	const navigate = useNavigate();

	const handleSaveAndContinue = () => {
		navigate("../..");
	};

	return (
		<PageLayout.Container>
			<PageLayout.Header.Container>
				<PageLayout.Header.ButtonBack />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container sx={{ maxWidth: "850px" }}>
				<PageLayout.Body.Title
					subtitle="Dados Clínicos"
					title="Enviar fotos (Opcional)"
					description="Compartilhe suas fotos pré-consulta para que possamos entender melhor seus objetivos e criar um plano de beleza personalizado que realce a sua melhor versão. Sua jornada de beleza começa aqui!"
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "16px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							bgcolor: "#FFFFFF",
							padding: "32px",
							gap: "24px",
							borderRadius: "12px",
						}}
					>
						<Typography
							variant="body-b-v2"
							sx={{ color: "#09234A" }}
						>
							Capture sua essência estética e compartilhe conosco
						</Typography>
						<TextFieldCustomFile />
					</Box>
					<Box>
						<ButtonCustomSubmit
							text={'Salvar e continuar'}
							onClick={handleSaveAndContinue}
							sx={{ paddingY: "12px", paddingX: "22px" }}
						/>
					</Box>
				</Box>
			</PageLayout.Body.Container>
		</PageLayout.Container>
	);
}
