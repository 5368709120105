import {
	Box,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { Medication, MonitorHeart } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { ButtonCustomSubmit, PageLayout, TypographyCustom } from "../../../../../components";

export function BusinessPatientPreConsultationClinicalHistory() {
	const navigate = useNavigate();
	const ANSWERS = {
		YesNo: [
			{ label: "Sim", value: true },
			{ label: "Não", value: false },
		],
	};

	const handleSaveAndContinue = () => {
		navigate("../complaint");
	};

	const QuestionGroupContainer = ({ children }) => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					bgcolor: "#FFFFFF",
					padding: "32px",
					gap: "24px",
					borderRadius: "12px",
				}}
			>
				{children}
			</Box>
		);
	};

	const QuestionGroupTitle = ({ title, icon }) => {
		const IconComponent = icon;

		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Typography variant="body-b" sx={{ color: "#09234A" }}>
					{title}
				</Typography>
				{!!icon && (
					<IconComponent
						style={{
							width: "25px",
							height: "25px",
							color: "#1A417D",
						}}
					/>
				)}
			</Box>
		);
	};

	const QuestionGroup = ({ children }) => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "8px",
				}}
			>
				{children}
			</Box>
		);
	};

	const Question = ({ questionText, answers = [] }) => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
				<Typography variant="small-r" sx={{ color: "#374151" }}>
					{questionText}
				</Typography>
				<RadioGroup>
					{answers.map((resposta, index) => (
						<FormControlLabel
							key={`resposta-${index}`}
							control={<Radio />}
							label={<TypographyCustom text={resposta.label} type="button-m"/>}
							value={resposta.value}
						/>
					))}
				</RadioGroup>
			</Box>
		);
	};

	return (
		<PageLayout.Container>
			<PageLayout.Header.Container>
				<PageLayout.Header.ButtonBack />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container sx={{ maxWidth: "850px" }}>
				<PageLayout.Body.Title
					subtitle="Dados Clínicos"
					title="Histórico Clínico • Lívia Silva"
					description="Nesta seção, solicitamos algumas informações clínicas relevantes para garantir que possamos fornecer um tratamento personalizado e seguro para você. Sua saúde e bem-estar são de extrema importância para nós."
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "25px",
						marginBottom: "25px",
					}}
				>
					<QuestionGroupContainer>
						<QuestionGroupTitle
							title="Tratamento Médico e Medicamentos"
							icon={Medication}
						/>
						<QuestionGroup>
							<Question
								questionText="Está atualmente sob tratamento médico? Se sim, qual o motivo?"
								answers={ANSWERS.YesNo}
							/>
							<Question
								questionText="Está tomando algum medicamento (anti-inflamatório, antibiótico, corticoide, etc.)? Se sim, qual o motivo?"
								answers={ANSWERS.YesNo}
							/>
							<Question
								questionText="Tem alergia a algum medicamento? Se sim, especifique qual."
								answers={ANSWERS.YesNo}
							/>
						</QuestionGroup>
					</QuestionGroupContainer>
					<QuestionGroupContainer>
						<QuestionGroupTitle
							title="Hábitos de Vida"
							icon={MonitorHeart}
						/>
						<QuestionGroup>
							<Question
								questionText="Você fuma?"
								answers={ANSWERS.YesNo}
							/>
							<Question
								questionText="Bebe bebidas alcoólicas frequentemente?"
								answers={ANSWERS.YesNo}
							/>
							<Question
								questionText="Toma antidepressivos ou ansiolíticos? Se sim, especifique qual."
								answers={ANSWERS.YesNo}
							/>
						</QuestionGroup>
					</QuestionGroupContainer>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "24px",
							paddingX: "32px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "20px",
							}}
						>
							<Typography
								variant="body-b"
								sx={{ fontWeight: "bold", color: "#09234A" }}
							>
								Termo de consentimento
							</Typography>
							<Typography variant="small-r" sx={{textAlign: 'justify'}}>
								Pelo presente termo, eu Sara Lima portadora do
								Registro Geral (RG) 9876542-1 e cadastro de
								pessoa física (CPF) 000.123.456-78 declaro estar
								informada e autorizo a Profissional de estética
								Giovana Moraes realizar o procedimento de
								Limpeza de Pele. Declaro que recebi um guia de
								orientações pós procedimento e estou ciente de
								que elas deverão ser rigorosamente seguidas por
								mim e que não posso omitir nenhuma informação
								sobre meu estado de saúde, cosméticos em uso e
								outros tratamentos por mim realizados.
							</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "20px",
							}}
						>
							<TextField />
							<Typography variant="small-r" sx={{textAlign: 'justify'}}>
								Ao inserir seu nome completo no campo acima,
								você concede autorização para que o mesmo seja
								atualizado e utilizado como sua assinatura
								digital, podendo ser aceita para fins jurídicos,
								caso necessário.
							</Typography>
						</Box>
					</Box>
					<Box>
						<ButtonCustomSubmit
							text={'Salvar e continuar'}
							onClick={handleSaveAndContinue}
							sx={{ paddingY: "12px", paddingX: "22px" }}
						/>
					</Box>
				</Box>
			</PageLayout.Body.Container>
		</PageLayout.Container>
	);
}
