export const SelectCustom = ({ register, options = [], name, defaultValue=0,...rest }) => {
  return (
    <select {...register(name)} {...rest} defaultValue={defaultValue}>
      <option key={0} value={0}>Selecione</option>
      {options.map((item, index) => {
        let isSelected = false;
        if(item.value == defaultValue){
          isSelected = true;
        }
        return (
          <option 
            key={index+1} 
            value={item.value} 
            selected={isSelected}
          >
            {item.label}
          </option>
        )
      })}
    </select>
  );
}