import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import CssBaseline from '@mui/material/CssBaseline'

import { getRouterApp } from "./routers/ManagerRouters"

function App() {

  const cacheEmotion = createCache({
    key: 'css',
    prepend: true,
  })

  const CurrentRouterApp = getRouterApp()

  return (
    <CacheProvider value={cacheEmotion}>
      <BrowserRouter>
        <CssBaseline />
        <CurrentRouterApp/>
      </BrowserRouter>
    </CacheProvider>
  )
}

export default App
