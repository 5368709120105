import React, { useState } from 'react';
import { ListItemButton, Paper, Button, Checkbox, ListItemText, ListItemIcon, ListItem, List, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export function TransferList({listLeft, setListLeft, listRight, setListRight}) {
  const [checked, setChecked] = useState([]);

  const leftChecked = intersection(checked, listLeft);
  const rightChecked = intersection(checked, listRight);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setListRight(listRight.concat(listLeft));
    setListLeft([]);
  };

  const handleCheckedRight = () => {
    setListRight(listRight.concat(leftChecked));
    setListLeft(not(listLeft, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setListLeft(listLeft.concat(rightChecked));
    setListRight(not(listRight, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setListLeft(listLeft.concat(listRight));
    setListRight([]);
  };

  const customList = ( title, items) => (
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
      <Typography>
        {title}
      </Typography>
      <Paper sx={{ width: 300, height: 300, overflow: 'auto' }}>
        <List dense component="div" role="list">
          {items.map((value, index) => {
            const labelId = `transfer-list-item-${value.id}-label`;

            return (
              <ListItemButton
                key={index}
                role="listitem"
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value.name}`} />
              </ListItemButton>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </Box>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList('Disponíveis', listLeft)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={listLeft.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={listRight.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('Selecionados', listRight)}</Grid>
    </Grid>
  );
}
