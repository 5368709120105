import { api } from "../../../axios_config";

export const getSexo = async () => {
  const {data} = await api.get('/cadastros/sexo');
  if(data.ok){
    const options = [];
    for (const opt of data.registers) {
      options.push({label: opt.sexo, value: opt.id});
    }
    return options;
  }else{
    new Error('Erro na busca das opções dos sexos!')
  }
}