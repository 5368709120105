import { Box } from "@mui/material";

export function PageBodyContainer({ children, sx = {} }) {
	return (
		<Box
			sx={{
				display: "flex",
				width:'1056px',
				flexDirection: "column",
				paddingX: "50px",
				...sx,
			}}
		>
			{children}
		</Box>
	);
}
