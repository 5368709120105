import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";

import { LayoutAdminDefault } from "../../../../../layouts";
import { SelectCustom } from "../../../../../components";
import { useAuthContext } from "../../../../../contexts";
import { apiClient } from "../../../../../services/apiInterface/admin/clients";
import { normalizeCEPNumber, normalizeCNPJNumber, normalizeCPFNumber, normalizePhoneFixNumber, normalizePhoneNumber } from "../../../../../utils/masks";
import { formattedNameSubdomain } from "../../../../../utils/tools";

export function AddClients() {

  const navigate = useNavigate();

  const { userLogged } = useAuthContext();

  const [localityOptions, setLocalityOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [businessCategoryOptions, setBusinessCategoryOptions] = useState([]);
  const [classificationOptions, setClassificationOptions] = useState([]);
  
  const [loading, setLoading] = useState(true);

  const schema = yup.object({
    cnpj: yup.string().required("Campo obrigatório!"),
    nome_empresa: yup.string().required("Campo obrigatório!"),
    data_abertura: yup.string().required("Campo obrigatório!"),
    localidade_id: yup.string().required("Campo obrigatório!"),
    nome_empresario: yup.string().required("Campo obrigatório!"),
    cpf_empresario: yup.string().required("Campo obrigatório!"),
    data_nascimento: yup.string().required("Campo obrigatório!"),
    sexo_id: yup.string().required("Campo obrigatório!"),
    email: yup.string().required("Campo obrigatório!"),
    telefone: yup.string().required("Campo obrigatório!"),
    categoria_negocio_id: yup.string().required("Campo obrigatório!"),
    valor_total: yup.string().required("Campo obrigatório!"),    
    status: yup.string().required("Campo obrigatório!"),
    // nome_subdominio: yup.string().required("Campo obrigatório!"),
    // link_cliente: yup.string().required("Campo obrigatório!"),
    // usuario_banco_cliente: yup.string().required("Campo obrigatório!"),
    // senha_usuario_banco: yup.string().required("Campo obrigatório!"),
    // conf_senha_usuario_banco: yup.string().required("Campo obrigatório!")
  }).required();

  const { register, handleSubmit, watch, setValue, formState: { errors}, setError } = useForm({resolver: yupResolver(schema), defaultValues:{
    // usuario_banco_cliente: 'admin'
  }});

  const handleBack = () => {
    navigate('/clientes');
  }

  const sendForm = async (newRegister) => {
    try {
      
      newRegister.usuarios_id = userLogged.id;

      const {ok, msg} = await apiClient.create(newRegister);

      navigate('/clientes', {state: {type: ok ?'success':'error', msg: msg}});

    } catch (error) {
      navigate('/clientes', {state: {type:'error', msg: error.message}});
    }
  }

  const handleAdd = (data) => {
    sendForm(data);
  }

  useEffect(()=>{
    (async ()=>{
      try {
        const optionsCadastre = await apiClient.getOptionsCadastre();
        setLocalityOptions(optionsCadastre.locality);
        setGenderOptions(optionsCadastre.gender);
        setBusinessCategoryOptions(optionsCadastre.businessCategory);
        setClassificationOptions(optionsCadastre.classification);
      } catch (error) {
        alert('Error: ' + error.message);
      }
    })()
  },[]);

  useEffect(()=>{
    if(localityOptions.length > 0 && 
      genderOptions.length > 0 &&  businessCategoryOptions.length > 0 && 
      businessCategoryOptions.length > 0 && classificationOptions.length > 0){
      setLoading(false)
    }else{
      setLoading(true)
    }
  },[localityOptions, genderOptions, businessCategoryOptions, businessCategoryOptions, classificationOptions]);


  // const value_nome_subdominio = watch('nome_subdominio');
  // const value_nome_empresa = watch('nome_empresa');

  // useEffect(()=>{
  //   setValue('link_cliente', `${value_nome_subdominio}.cheffinanceiro.com.br`);
  //   setValue('nome_banco_dados', `${value_nome_subdominio}.cheffinanceiro`);
  // },[value_nome_subdominio]);


  // useEffect(()=>{
  //   setValue('nome_subdominio', formattedNameSubdomain(value_nome_empresa));
  // },[value_nome_empresa]);


  return (
    <LayoutAdminDefault
      title="Adicionar cliente"
    >
      <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2}}>
        {!loading ?
            <>
              <FormControl required>
                <InputLabel id="status">Status</InputLabel>
                <Select
                  id="status"
                  label="Status *"
                  defaultValue={"1"}
                  {...register("status", { required: true })}
                >
                  <MenuItem value={"1"}>Ativo</MenuItem>
                  <MenuItem value={"0"}>Inativo</MenuItem>
                </Select>
              </FormControl>
              
              <TextField
                required
                label={"CNPJ"}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("cnpj", { required: true })}
              />
              {/* {errors.cell && <Typography variant="caption" color="#DC1637">{errors.cell?.message}</Typography>} */}

              <TextField
                required
                label={"Nome da Empresa"}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("nome_empresa", { required: true })}
              />

              <TextField
                required
                label={"Data Abertura"}
                type="date"
                sx={{width:'20%'}}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("data_abertura", { required: true })}
              />

              <Typography>
                Localidade:
              </Typography>
              <SelectCustom 
                name="localidade_id" 
                options={localityOptions}
                register={register}
              />

              <TextField
                required
                label={"Nome do Empresário"}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("nome_empresario", { required: true })}
              />

              <TextField
                required
                label={"Data nascimento"}
                type="date"
                sx={{width:'20%'}}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("data_nascimento", { required: true })}
              />

              <Typography>
                Sexo:
              </Typography>
              <SelectCustom 
                name="sexo_id" 
                options={genderOptions}
                register={register}
              />
              
              <TextField
                required
                label={"E-mail"}
                type="email"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("email", { required: true })}
              /> 

              <TextField
                required
                label={"Telefone"}
                type="tel"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("telefone", { required: true })}
              /> 

              <Typography>
                Categoria do Negócio:
              </Typography>
              <SelectCustom 
                name="categoria_negocio_id" 
                options={businessCategoryOptions}
                register={register}
              />

              <TextField
                required
                label={"Valor Mensalidade"}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("valor_mensalidade", { required: true })}
              /> 

              <TextField
                required
                label={"Valor Total"}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("valor_total", { required: true })}
              /> 

              <Typography>
                Classificação:
              </Typography>
              <SelectCustom 
                name="classificacao_empresa_id" 
                options={classificationOptions}
                register={register}
              />

              {/* <TextField
                required
                label={"Nome do subdominio desejado"}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("nome_subdominio", { required: true })}
              />

              <TextField
                required
                label={"Link de acesso a área do cliente"}
                type="text"
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("link_cliente", { required: true })}
              />

              <TextField
                required
                label={"Nome do banco de dados"}
                type="text"
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("nome_banco_dados", { required: true })}
              />

              <TextField
                required
                label={"Usuário"}
                type="text"
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("usuario_banco_cliente", { required: true })}
              />
              
              <TextField
                required
                label={"Senha"}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("senha_usuario_banco", { required: true })}
              />

              <TextField
                required
                label={"Confirmar Senha"}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("conf_senha_usuario_banco", { required: true })}
              /> */}

            </>
          :
            <Typography>
              Carregando...
            </Typography>
        }
        

      </Box>

      <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2}}>
        <Button
          variant="contained" 
          onClick={handleSubmit(handleAdd)}
          sx={{width:"15vw"}}
          color="primary"
        >
          <Typography sx={{fontSize:"18px"}}>Cadastrar</Typography>
        </Button>

        <Button 
          variant="contained" 
          onClick={handleBack}
          sx={{width:"15vw"}}
          color="error"
        >
          <Typography sx={{fontSize:"18px"}}>Voltar</Typography>
        </Button>
      </Box>
      
    </LayoutAdminDefault>
  )
}