import { Box } from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'

import { ButtonCustomAdd, ButtonCustomRemove, ButtonCustomRemoveIcon, ButtonCustomSubmit, TextFieldCustomText, TypographyCustom } from '../../../../../../../../../../../../../../../../components'

export const FormCapillary = () => {

  const { control, reset } = useForm({defaultValues:{
    cuidados: [{
      descricao: ''
    }],
    consideracoes_finais: ''
  }})
  const { fields, append, remove} = useFieldArray({ control: control, name: 'cuidados'})

  const handleAdd = () => {
    append({descricao: ''})
  }

  const handleRemove = (index) => {
    remove(index)
  }

  const handleRemoveAll = () => {
    reset()
  }

  return (
    <Box sx={{display: 'flex', flexDirection:'column', width:'100%', gap:'12px'}}>
      <Box>
        <ButtonCustomAdd
          onClick={()=>{handleAdd()}}
          text='Adicionar Cuidado Pós'
          sx={{height: '44px'}}
        />
      </Box>
      <Box sx={{display: 'flex', flexDirection:'column', width:'100%', gap:'4px'}}>
      {fields.map((item, index)=>(
          <Box key={item.id} sx={{display: 'flex', width:'100%', gap:'8px', alignItems:'center'}}>
            <Box sx={{width:'60px'}}>
              <TypographyCustom
                text={`Cuidado Pós Nº ${index + 1}`}
                type={'small-m'}
              />
            </Box>
            <TextFieldCustomText
              name={`cuidados.${index}.descricao`}
              control={control}
              multiline
              textRows={4}
              maxLength={500}
              showMaxLength={false}
            />
            <ButtonCustomRemoveIcon
              onClick={()=>{ handleRemove(index) }}
            />
          </Box>
        ))}
        <Box sx={{mt:'4px', display: 'flex', width:'100%', alignItems:'center'}}>
          <TextFieldCustomText
            name={`consideracoes_finais`}
            label={'Considerações Finais Pós Tratamento'}
            control={control}
            multiline
            textRows={4}
            maxLength={500}
            showMaxLength={false}
          />
        </Box>
      </Box>
      <Box sx={{ mt:'16px', width:'100%', display:'flex', alignItems:'center', justifyContent:'center', mb:'40px', gap:'20px' }}>
        <ButtonCustomRemove
          disabled={fields.length == 0}
          onClick={handleRemoveAll}
          text='Excluir'
          sx={{
            width:'150px',
            height:'36px'
          }}
        />
        <ButtonCustomSubmit
          disabled={fields.length == 0}
          haveIcon={false}
          // onClick={handleSub}
          text='Salvar'
          sx={{
            width:'300px',
            height:'36px'
          }}
        />
      </Box>
    </Box>
  )
}