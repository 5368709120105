import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import { useDebounce } from "../../../../../hooks";
import { LayoutAdminDefault } from "../../../../../layouts";
import { columnsClientsUsers } from "../../../../../data/admin";
import { TableGridClientsUsers } from "../../../../../components/TableGrid/admin";
import { apiClient } from "../../../../../services/apiInterface/admin/clients";

export function UsersClients() {

  const params = useParams()

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [rowsCount, setRowsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const { debounce } = useDebounce();

  const handleBack = () => {
    navigate('/clientes');
  }

  const search = useMemo(()=>{
    return searchParams.get('search') || '';
  },[searchParams]);

  const page = useMemo(()=>{
    return Number(searchParams.get('page') || '1');
  },[searchParams]);

  useEffect(()=>{
    debounce(()=>{
      try {
        setLoading(true);
        apiClient.getAllUsers(params?.id,page, search)
          .then((result) => {
            if(result instanceof Error){
              alert(result.message)
            }else{
              const { data:{ok, msg, count, users} } = result;

              if(ok){
                setRowsCount(count)
                setUsers(users);
              }else{
                alert(msg)
              }
            }
          })
  
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    });
  },[search, page]);

  return (
    <LayoutAdminDefault
      title={`Usuários do cliente`}
    >
      <TableGridClientsUsers
        rows={users}
        columns={columnsClientsUsers}
        search={search}
        setSearchParams={setSearchParams}
        loading={loading}
        page={page}
        rowsCount={rowsCount}
        clientId={params?.id}
      />

      <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2}}>
        <Button 
          variant="contained" 
          onClick={handleBack}
          sx={{width:"15vw"}}
          color="error"
        >
          <Typography sx={{fontSize:"18px"}}>Voltar</Typography>
        </Button>
      </Box>
    </LayoutAdminDefault>
  )
}