import React, { createContext, useContext, useState } from "react"

const DrawerMenuBusinessContext = createContext()

export const useDrawerMenuBusinessContext = ()=>{
  return useContext(DrawerMenuBusinessContext)
}

export function DrawerMenuBusinessContextProvider({children}) {

  const [ isOpenDrawerMenu, setIsOpenDrawerMenu ] = useState(true)

  const handleOpenDrawerMenu = () => {
    setIsOpenDrawerMenu(true)
  }

  const handleCloseDrawerMenu = () => {
    setIsOpenDrawerMenu(false)
  }

  return (
    <DrawerMenuBusinessContext.Provider 
      value={{
        isOpenDrawerMenu, 
        handleOpenDrawerMenu, 
        handleCloseDrawerMenu
      }}>
      {children}
    </DrawerMenuBusinessContext.Provider>
  )
}