import { get } from "../../../environment";
import { Box, Button, Typography } from "@mui/material";
import usePagination from "@mui/material/usePagination";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const defaultPaginationStatus = {
	currentPage: 1,
	countTotalPages: 0,
	countTotalRows: 0,
};

export default function TableCustomFooterPagination({
	paginationStatus = defaultPaginationStatus,
	handlePagination = () => {},
}) {
	const firstItem =
		(paginationStatus.currentPage - 1) * get("LIMIT_ROWS_TABLE_PAGE") + 1;
	const lastItem = Math.min(
		paginationStatus.currentPage * get("LIMIT_ROWS_TABLE_PAGE"),
		paginationStatus.countTotalRows
	);

	const { items: itemsPagination } = usePagination({
		page: paginationStatus.currentPage,
		count: paginationStatus.countTotalPages,
	});

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			<Box sx={{ display: "flex", gap: "11px" }}>
				{itemsPagination.map(
					({ page, type, selected, ...item }, index) => {
						let children = null;

						if (
							type === "start-ellipsis" ||
							type === "end-ellipsis"
						) {
							children = (
								<Box
									sx={{
										backgroundColor: "#EFF5FF",
										width: "39px",
										height: "34px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										borderRadius: "8px",
									}}
								>
									<Typography
										sx={{
											fontSize: "14px",
											fontWeight: 500,
											lineHeight: "210%",
											color: selected
												? "#FFFFFF"
												: "#1A417D",
											fontFamily: "Inter",
										}}
									>
										{"…"}
									</Typography>
								</Box>
							);
						} else if (type === "page") {
							children = (
								<Box
									sx={{
										backgroundColor: selected
											? "#3466B2"
											: "#EFF5FF",
										width: "39px",
										height: "34px",
										borderRadius: "8px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<Button
										{...item}
										onClick={(event) => {
											item.onClick(event);
											handlePagination(page);
										}}
										sx={{
											width: "39px",
											height: "34px",
										}}
									>
										<Typography
											sx={{
												fontSize: "14px",
												fontWeight: 500,
												lineHeight: "210%",
												color: selected
													? "#FFFFFF"
													: "#1A417D",
												fontFamily: "Inter",
											}}
										>
											{page}
										</Typography>
									</Button>
								</Box>
							);
						} else {
							children = (
								<Box
									sx={{
										backgroundColor: item.disabled
											? "#9CA3AF"
											: "#EFF5FF",
										width: "108px",
										height: "36px",
										borderRadius: "8px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<Button
										{...item}
										onClick={(event) => {
											item.onClick(event);
											handlePagination(page);
										}}
										sx={{
											textTransform: "none",
										}}
										startIcon={
											type === "previous" ? (
												<ArrowBackIcon
													sx={{
														color: item.disabled
															? "#E5E7EB"
															: "#1A417D",
													}}
												/>
											) : null
										}
										endIcon={
											type === "next" ? (
												<ArrowForwardIcon
													sx={{
														color: item.disabled
															? "#E5E7EB"
															: "#1A417D",
													}}
												/>
											) : null
										}
									>
										<Typography
											sx={{
												fontSize: "14px",
												fontWeight: 500,
												lineHeight: "210%",
												color: item.disabled
													? "#E5E7EB"
													: "#1A417D",
												fontFamily: "Inter",
											}}
										>
											{type === "previous"
												? "Anterior"
												: "Próximo"}
										</Typography>
									</Button>
								</Box>
							);
						}

						return <Box key={index}>{children}</Box>;
					}
				)}
			</Box>
			<Box sx={{ display: "flex" }}>
				<Typography
					sx={{
						fontSize: "14px",
						fontWeight: 500,
						lineHeight: "210%",
						color: "#4B5563",
						fontFamily: "Inter",
					}}
				>
					{`Resultados:`}
				</Typography>
				<Typography
					sx={{
						ml: "3px",
						fontSize: "14px",
						fontWeight: 600,
						lineHeight: "210%",
						color: "#4B5563",
						fontFamily: "Inter",
					}}
				>
					{`${!isNaN(firstItem) ? firstItem : 0} - ${
						!isNaN(lastItem) ? lastItem : 0
					}`}
				</Typography>
				<Typography
					sx={{
						ml: "3px",
						fontSize: "14px",
						fontWeight: 500,
						lineHeight: "210%",
						color: "#4B5563",
						fontFamily: "Inter",
					}}
				>
					{`/ ${paginationStatus.countTotalRows ?? 0}`}
				</Typography>
			</Box>
		</Box>
	);
}
