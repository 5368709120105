import { apiForRefreshToken } from "../../axios_config";

/**
 * Verifica se o token de acesso fornecido expirou
 * @param {string} accessToken Token de acesso
 * @returns {boolean}
 */
export const hasAccessTokenExpired = (accessToken) => {
	const nowMilliseconds = new Date().getMilliseconds();
	return nowMilliseconds > accessToken.exp;
};

/**
 * Envia uma requisição ao servidor solicitando por AccessToken e RefreshToken novos
 * se necessário.
 * @async
 * @returns Novos tokens gerados pelo servidor dentro de um `object` ou `null` caso o
 * refreshToken do usuário não exista, seja inválido ou expirado.
 */
export const askForNewTokens = async () => {
	console.log("askForNewTokens");

	const currentRefreshToken = localStorage.getItem("refreshToken");
	if(!currentRefreshToken || currentRefreshToken === 'undefined') return null;

	const parsedRefreshToken = JSON.parse(currentRefreshToken);
	
	if (parsedRefreshToken === null) return null;

	// Verifica se o refresh token esta expirado
	const nowMilliseconds = new Date().getMilliseconds();
	if(nowMilliseconds > parsedRefreshToken.dateExpire){
		console.log("refreshToken expirado");
		return null;
	}

	const {
		data: { ok, token: newAccessToken },
	} = await apiForRefreshToken.post("/refreshAuth", {
		id: parsedRefreshToken.id,
	});

	if (!ok) return null;
	
	console.log("newAccessToken", newAccessToken);

	return { newAccessToken };
};

// /**
//  * Troca os tokens guardados no `localStorage` pelos fornecidos como argumento.
//  * @param {string} newAccessToken Novo token de acesso
//  * @param {object} newRefreshToken Novo token de atualização
//  */
// export const updateTokens = (newAccessToken, newRefreshToken) => {

// 	if (newAccessToken)
// 		localStorage.setItem("token", JSON.stringify(newAccessToken));

// 	if (newRefreshToken)
// 		localStorage.setItem("refreshToken", JSON.stringify(newRefreshToken));

// 	api.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
// };
