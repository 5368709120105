import React from "react";
import { Box, Typography } from "@mui/material";

import { ButtonCustomSubmit } from "../../../../../../../components";

export function ScheduledVisitsHeader() {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			<Typography variant="big-m" sx={{ color: "#09234A" }}>
				Consultas agendadas
			</Typography>
			<ButtonCustomSubmit
				text="Agendar consulta"
			/>
		</Box>
	);
}
