import { createTheme } from "@mui/material";
import { cyan, blue } from "@mui/material/colors";

const typographyVariants = {
	"title-h1": {
		fontSize: "58px",
		lineHeight: "120%",
		fontFamily: "InterBold",
		letterSpacing: "-2%",
	},
	"title-h2": {
		fontSize: "50px",
		lineHeight: "120%",
		fontFamily: "InterBold",
		letterSpacing: "-2%",
	},
	"title-h3": {
		fontSize: "38px",
		lineHeight: "120%",
		fontFamily: "InterBold",
		letterSpacing: "-2%",
	},
	"title-h4": {
		fontSize: "25px",
		lineHeight: "120%",
		fontFamily: "InterBold",
		letterSpacing: "0%",
	},
	"title-h4-v2": {
		fontSize: "26px",
		fontWeight: "500",
		lineHeight: "120%",
		fontFamily: "Inter",
		letterSpacing: "0%",
	},
	"title-h5": {
		fontSize: "25px",
		lineHeight: "120%",
		fontFamily: "InterMedium",
		letterSpacing: "0%",
	},
	"subtitle-b": {
		fontSize: "20px",
		lineHeight: "130%",
		fontFamily: "InterBold",
		letterSpacing: "0%",
	},
	"subtitle-b-v2": {
		fontSize: "22px",
		fontWeight: 500,
		lineHeight: "150%",
		fontFamily: "Inter",
	},
	"subtitle-m": {
		fontSize: "20px",
		lineHeight: "130%",
		fontFamily: "InterMedium",
		letterSpacing: "0%",
	},
	"subtitle-r": {
		fontSize: "20px",
		lineHeight: "130%",
		fontFamily: "InterRegular",
		letterSpacing: "0%",
	},
	"body-b": {
		fontSize: "16px",
		lineHeight: "140%",
		fontFamily: "InterBold",
		letterSpacing: "0%",
	},
	"body-b-v2": {
		fontSize: "14px",
		fontWeight: "500",
		lineHeight: "140%",
		fontFamily: "InterBold",
		letterSpacing: "0%",
	},
	"body-m": {
		fontSize: "14px",
		fontWeight: "500",
		lineHeight: "140%",
		fontFamily: "Inter",
		letterSpacing: "0%",
	},
	"body-r": {
		fontSize: "16px",
		lineHeight: "140%",
		fontFamily: "InterBold",
		letterSpacing: "0%",
	},
	"body-r-v2": {
		fontSize: "14px",
		lineHeight: "140%",
		fontFamily: "Inter",
		letterSpacing: "0%",
	},
	"body-l": {
		fontSize: "16px",
		lineHeight: "140%",
		fontFamily: "InterLight",
		letterSpacing: "0%",
	},
	"small-b": {
		fontSize: "11px",
		lineHeight: "150%",
		fontFamily: "InterBold",
		letterSpacing: "0%",
	},
	"small-m": {
		fontSize: "11px",
		lineHeight: "150%",
		fontFamily: "InterMedium",
		letterSpacing: "0%",
	},
	"small-r": {
		fontSize: "14px",
		fontWeight: 400,
		lineHeight: "150%",
		fontFamily: "Inter",
		letterSpacing: "0%",
	},
	"small-r-v2": {
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: "150%",
		fontFamily: "Inter",
		letterSpacing: "0%",
	},
	"small-l": {
		fontSize: "11px",
		lineHeight: "150%",
		fontFamily: "InterLight",
		letterSpacing: "0%",
	},
	"big-b": {
		fontSize: "16px",
		lineHeight: "150%",
		fontFamily: "InterBold",
		letterSpacing: "0%",
	},
	"big-b-v2": {
		fontSize: "20px",
		fontWeight: 700,
		lineHeight: "150%",
		fontFamily: "Inter",
		letterSpacing: "0%",
	},
	"big-m": {
		fontSize: "14px",
		lineHeight: "150%",
		fontFamily: "InterMedium",
		letterSpacing: "0%",
	},
	"big-r": {
		fontSize: "16px",
		lineHeight: "150%",
		fontFamily: "InterRegular",
		letterSpacing: "0%",
	},
	"big-l": {
		fontSize: "16px",
		lineHeight: "150%",
		fontFamily: "InterLight",
		letterSpacing: "0%",
	},
	"button-l": {
		fontSize: "16px",
		lineHeight: "100%",
		fontFamily: "InterSemiBold",
		letterSpacing: "0%",
	},
	"button-m": {
		fontSize: "12px",
		lineHeight: "100%",
		fontFamily: "InterSemiBold",
		letterSpacing: "0%",
	},
	"button-m-v2": {
		fontSize: "16px",
		fontWeight: 600,
		lineHeight: "150%",
		fontFamily: "Inter",
		letterSpacing: "0%",
	},
	"button-s": {
		fontSize: "11px",
		lineHeight: "100%",
		fontFamily: "InterSemiBold",
		letterSpacing: "0%",
	},
	"button-s-v2": {
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: "150%",
		fontFamily: "Inter",
		letterSpacing: "0%",
	},
	"label-l": {
		fontSize: "16px",
		lineHeight: "100%",
		fontFamily: "InterSemiBold",
		letterSpacing: "0%",
	},
	"label-m": {
		fontSize: "12px",
		lineHeight: "100%",
		fontFamily: "InterSemiBold",
		letterSpacing: "0%",
	},
	"label-m-v2": {
		fontSize: "12px",
		fontWeight: 600,
		lineHeight: "100%",
		fontFamily: "Inter",
		letterSpacing: "0%",
	},
	"label-m-v3": {
		fontSize: "12px",
		fontWeight: 500,
		lineHeight: "100%",
		fontFamily: "Inter",
		letterSpacing: "0%",
	},
	"label-s": {
		fontSize: "11px",
		lineHeight: "100%",
		fontFamily: "InterSemiBold",
		letterSpacing: "0%",
	},
	"inputText-r-l": {
		fontSize: "16px",
		lineHeight: "100%",
		fontFamily: "InterRegular",
		letterSpacing: "0%",
	},
	"inputText-r-m": {
		fontSize: "12px",
		lineHeight: "100%",
		fontFamily: "InterRegular",
		letterSpacing: "0%",
	},
	"inputText-r-s": {
		fontSize: "11px",
		lineHeight: "100%",
		fontFamily: "InterRegular",
		letterSpacing: "0%",
	},
	"inputText-m-l": {
		fontSize: "16px",
		lineHeight: "100%",
		fontFamily: "InterMedium",
		letterSpacing: "0%",
	},
	"inputText-m-m": {
		fontSize: "12px",
		lineHeight: "100%",
		fontFamily: "InterMedium",
		letterSpacing: "0%",
	},
	"inputText-m-s": {
		fontSize: "11px",
		lineHeight: "100%",
		fontFamily: "InterMedium",
		letterSpacing: "0%",
	},
};

export const LightTheme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: "#3466B2",
			// main: blue[700],
			// main: '#757ce8',
			dark: "#1A417D",
			// dark: blue[800],
			// dark: '#fff',
			light: "#A7CAFF",
			// light: blue[500],
			// light: '#ffffff',
			contrastText: "#ffffff",
			// contrastText: '#fff'
		},
		secondary: {
			main: cyan[500],
			dark: cyan[400],
			light: cyan[300],
			contrastText: "#ffffff",
		},
		background: {
			default: "#f7f6f3",
			paper: "#ffffff",
		},
	},
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true,
				style: {
					paddingLeft: "16px",
					paddingRight: "16px",
					borderRadius: "8px",
					textTransform: "none",
				},
			},
			variants: [
				{
					props: { variant: "outlined" },
					style: {
						backgroundColor: "#FFFFFF",
						borderWidth: "2px",
						borderColor: "#558EE3",
						":hover": {
							backgroundColor: "#EFF5FF",
							borderWidth: "2px",
							borderColor: "#1A417D",
						},
						boxShadow: "none",
					},
				},
			],
		},
		MuiTextField: {
			defaultProps: {
				InputProps: {
					style: {
						borderRadius: 10,
						backgroundColor: "#FFFFFF",
						border: "1px solid #6B7280",
						maxHeight: "44px",
						fontSize: "14px",
						fontWeight: 500,
						lineHeight: "120%",
						color: "#374151",
						fontFamily: "Inter",
					},
				},
			},
		},
		// MuiCheckbox: {
		// 	defaultProps: {
		// 		sx: {
		// 			width: "20px",
		// 			height: "20px",
		// 			margin: "8px",
		// 			borderRadius: "4px",
		// 			borderWidth: "1px",
		// 		},
		// 	},
		// },
	},
	typography: { fontFamily: "Inter", ...typographyVariants },
});

export const DarkTheme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: blue[700],
			dark: blue[800],
			light: blue[500],
			contrastText: "#ffffff",
		},
		secondary: {
			main: cyan[500],
			dark: cyan[400],
			light: cyan[300],
			contrastText: "#ffffff",
		},
		background: {
			default: "#202124",
			paper: "#303134",
		},
	},
	MuiButton: {
		defaultProps: {
			disableElevation: true,
			style: {
				borderRadius: "8px",
				textTransform: "none",
				fontSize: "14px",
				fontWeight: "600",
				lineHeight: "100%",
				letterSpacing: "0%",
			},
		},
		variants: [
			{
				props: { variant: "outlined" },
				style: {
					backgroundColor: "#FFFFFF",
					borderWidth: "2px",
					borderColor: "#558EE3",
					":hover": {
						backgroundColor: "#EFF5FF",
						borderWidth: "2px",
						borderColor: "#1A417D",
					},
					boxShadow: "none",
				},
			},
		],
	},
	typography: {
		allVariants: {
			color: "white",
		},
		fontFamily: "Inter",
		...typographyVariants,
	},
});
