import {
	Avatar,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import React, { isValidElement } from "react";
import { useTableCustomContext } from "../TableCustomContainer";

export default function TableCustomTable() {
	const { rows, columns } = useTableCustomContext();

	const TableHeadCellContent = ({ children }) => {
		if (!children) return null;

		if (isValidElement(children)) {
			return children;
		}

		return (
			<Typography
				variant="body-r-v2"
				sx={{ color: "#4B5563", textAlign: "left" }}
			>
				{children}
			</Typography>
		);
	};

	const TableBodyCellContent = ({ children }) => {
		if (!children) return null;

		if (isValidElement(children)) {
			return children;
		}

		return (
			<Typography
				variant="body-m"
				sx={{ color: "#4B5563", textAlign: "left" }}
			>
				{children}
			</Typography>
		);
	};

	return (
		<Box
			sx={{
				mb: "24px",
				height: "400px",
				display: "flex",
				justifyContent: "center",
				backgroundColor: "#FFFFFF",
				borderRadius: "8px",
			}}
		>
			<TableContainer
				sx={{ display: "flex", flexDirection: "column", px: "20px" }}
			>
				<Table sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							{columns
								.filter(
									(column) =>
										!!column?.type && column?.type !== "none"
								)
								.map((column) => (
									<TableCell key={`field-${column.id}`}>
										<TableHeadCellContent>
											{column.field}
										</TableHeadCellContent>
									</TableCell>
								))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, rowIndex) => {
							const rowCells = Object.keys(row);

							return (
								<TableRow key={`table-body-row-${rowIndex}`}>
									{rowCells.map((cell, cellIndex) => {
										const column = columns.find(
											(_, columnIndex) =>
												cellIndex === columnIndex
										);
										const columnId = column?.id;
										const rowCell = !!columnId
											? row[columnId]
											: null;

										if (
											!rowCell ||
											!column?.type ||
											column?.type === "none"
										)
											return null;

										return (
											<TableCell
												key={`table-body-row-${cellIndex}-cell-${cell}`}
											>
												<TableBodyCellContent>
													{rowCell}
												</TableBodyCellContent>
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}
