import { assets } from "../../../../../../../assets/images_sys";
import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";

export function RadioGroupFacialBiotype() {
	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<img
				src={assets.imgs.facial_biotype}
				style={{ maxWidth: "100%", maxHeight: "auto" }}
			/>
			<FormControl>
				<RadioGroup
					row
					sx={{ display: "flex", justifyContent: "space-evenly" }}
				>
					<FormControlLabel
						value="maçã"
						control={<Radio />}
						label={
							<Typography variant="inputText-r-l">
								Maçã
							</Typography>
						}
					/>
					<FormControlLabel
						value="quadrado"
						control={<Radio />}
						label={
							<Typography variant="inputText-r-l">
								Quadrado
							</Typography>
						}
					/>
					<FormControlLabel
						value="triangular"
						control={<Radio />}
						label={
							<Typography variant="inputText-r-l">
								Triangular
							</Typography>
						}
					/>
					<FormControlLabel
						value="redondo"
						control={<Radio />}
						label={
							<Typography variant="inputText-r-l">
								Redondo
							</Typography>
						}
					/>
					<FormControlLabel
						value="oval"
						control={<Radio />}
						label={
							<Typography variant="inputText-r-l">
								Oval
							</Typography>
						}
					/>
				</RadioGroup>
			</FormControl>
		</Box>
	);
}
