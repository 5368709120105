import React, { useState } from "react";
import { Divider, Drawer, 
         List, 
         ListItemButton, 
         ListItemIcon, 
         ListItemText, 
         Typography,
         useTheme,
         Button } from "@mui/material";
import { Box } from "@mui/system";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';

import { useDrawerAdminContext, useThemeContext } from "../../contexts";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { get } from "../../environment";

export default function MenuLateralThreeAdmin({children}){
  const theme = useTheme();
  const {theme: themeContext} = useThemeContext();

  const navigate = useNavigate();

  const {isDrawerOpen, drawerOptions, drawerUserOptions } = useDrawerAdminContext();

  const logo = get('IMG_LOGO');

  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0 ? ['1', '5', '6', '7'] : [],
    );
  };

  const handleLogo = () => {
    navigate('/home');
  }

  const ListItemLink = ({label, icon, to, onClick}) => {
  
    const resolvePath = useResolvedPath(to);
    const match = useMatch({path:resolvePath.pathname, end: false});
  
    const handleClick = () => {
      navigate(to);
      if(onClick !== undefined){
        onClick();
      }
    }
  
    return (
      <ListItemButton selected={!!match} onClick={handleClick}>
        <ListItemIcon>
          {icon()}
        </ListItemIcon>
        <ListItemText primary={label}/>
      </ListItemButton>
    )
  }

  return (
    <Box width='100vw' height='100vh' bgcolor={themeContext.palette.background.default}>
      <Drawer 
        variant={isDrawerOpen ? "permanent":"temporary"}
      >
        <Box width={theme.spacing(23)} height="100%" display="flex" flexDirection="column" sx={{overflow: "hidden"}}>
          <Box flex={1}>
            <Box sx={{display:'flex', justifyContent:'center', alignItems: 'center', backgroundColor: 'white', width: '100%', height:'9%'}}>
              <Button 
                onClick={handleLogo}
              >
                <img src={logo} width="auto" height="40"/>
              </Button>
            </Box>

            <Divider/>

            { expanded.length > 0 ? 
              <Box sx={{display:"flex", justifyContent:"center"}}>
                <Button onClick={handleExpandClick} variant="contained" sx={{mt:1, mb: 1}}>
                  Fechar tudo
                </Button>
              </Box>
              :
              null
            }
            <Divider/>

            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon/>}
              defaultExpandIcon={<ChevronRightIcon/>}
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
            >
              {/* menu admin */}
              <TreeItem nodeId="1" label="Cadastros">
                {drawerOptions.map((drawerOption) => {
                    if(drawerOption.category === 'cadastros'){
                      return (
                        <ListItemLink
                          key={drawerOption.path}
                          label={drawerOption.label}
                          icon={drawerOption.icon}
                          to={drawerOption.path}
                        />
                      )
                    }
                  })}   
              </TreeItem>
              {/* menu cliente */}
              {/* <TreeItem nodeId="1" label="Análise do Cliente">
                {drawerOptions.map((drawerOption) => {
                    if(drawerOption.category === 'analise_cliente'){
                      return (
                        <ListItemLink
                          key={drawerOption.path}
                          label={drawerOption.label}
                          icon={drawerOption.icon}
                          to={drawerOption.path}
                        />
                      )
                    }
                  })}   
              </TreeItem>

              <TreeItem nodeId="2" label="Resultado do Diagnóstico">
                {drawerOptions.map((drawerOption) => {
                    if(drawerOption.category === 'resultado_diagnostico'){
                      return (
                        <ListItemLink
                          key={drawerOption.path}
                          label={drawerOption.label}
                          icon={drawerOption.icon}
                          to={drawerOption.path}
                        />
                      )
                    }
                  })}   
              </TreeItem>

              <TreeItem nodeId="3" label="Gestão do Negócio">
                {drawerOptions.map((drawerOption) => {
                    if(drawerOption.category === 'gestao_negocio'){
                      return (
                        <ListItemLink
                          key={drawerOption.path}
                          label={drawerOption.label}
                          icon={drawerOption.icon}
                          to={drawerOption.path}
                        />
                      )
                    }
                  })}   
              </TreeItem>

              <TreeItem nodeId="4" label="Controle do Negócio">
                {drawerOptions.map((drawerOption) => {
                    if(drawerOption.category === 'controle_negocio'){
                      return (
                        <ListItemLink
                          key={drawerOption.path}
                          label={drawerOption.label}
                          icon={drawerOption.icon}
                          to={drawerOption.path}
                        />
                      )
                    }
                  })}   
              </TreeItem>

              <TreeItem nodeId="5" label="Configurações">
                {drawerOptions.map((drawerOption) => {
                    if(drawerOption.category === 'configuracoes'){
                      return (
                        <ListItemLink
                          key={drawerOption.path}
                          label={drawerOption.label}
                          icon={drawerOption.icon}
                          to={drawerOption.path}
                        />
                      )
                    }
                  })}   
              </TreeItem>

              <TreeItem nodeId="6" label="Suporte">
                {drawerOptions.map((drawerOption) => {
                    if(drawerOption.category === 'suporte'){
                      return (
                        <ListItemLink
                          key={drawerOption.path}
                          label={drawerOption.label}
                          icon={drawerOption.icon}
                          to={drawerOption.path}
                        />
                      )
                    }
                  })}   
              </TreeItem> */}
            </TreeView>
            <Divider/>
          </Box>
          
          <Box>
            <Divider/>
            <List component="nav">
              {drawerUserOptions.map((drawerUserOption)=>(
                <ListItemLink
                  key={drawerUserOption.path}
                  label={drawerUserOption.label}
                  icon={drawerUserOption.icon}
                  to={drawerUserOption.path}
                />
              ))}
            </List>
            <Divider/>
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography variant="caption">
              Versão Alpha
            </Typography>
          </Box>
        </Box>
      </Drawer>
      <Box height='100vh' marginLeft={!isDrawerOpen? 0 : theme.spacing(23)}>
        {children}
      </Box>
    </Box>
  )
}