import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useDebounce } from "../../../../hooks";
import { columnsClients } from "../../../../data/admin";
import { LayoutAdminDefault } from "../../../../layouts";
import { AlertCustom } from "../../../../components/AlertCustom";
import { TableGridClients } from "../../../../components/TableGrid/admin";
import { apiClient } from "../../../../services/apiInterface/admin/clients";

export function ClientsAdmin() {
  const {state} = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updatedRows, setUpdatedRows] = useState(false);

  const [formMessage, setFormMessage] = useState( !!state ? {type:state.type, msg: state.msg}: null);
  const [openFormMessage, setOpenFormMessage] = useState(true);
  const [rowsCount, setRowsCount] = useState(0);

  const { debounce } = useDebounce();

  const search = useMemo(()=>{
    return searchParams.get('search') || '';
  },[searchParams]);

  const page = useMemo(()=>{
    return Number(searchParams.get('page') || '1');
  },[searchParams]);

  useEffect(()=>{

    debounce(()=>{
      try {
        setLoading(true);
        apiClient.getAll(page, search)
        .then((result) => {
          setLoading(false);
          if(result instanceof Error){
            alert(result.message)
          }else{
            const { data:{ok, msg, count, registers} } = result;

            if(ok){
              setRowsCount(count)
              setClients(registers);
            }else{
              alert(msg)
            }
          }
        })
  
      } catch (error) {
        alert(error);
      }
    });
  },[search, page, updatedRows]);

  return (
    <LayoutAdminDefault
      title="Clientes"
    >
      
      {!!formMessage && <AlertCustom open={openFormMessage} setOpen={setOpenFormMessage} formMessage={formMessage}/>}
      <TableGridClients
        rows={clients}
        columns={columnsClients}
        search={search}
        setSearchParams={setSearchParams}
        loading={loading}
        page={page}
        rowsCount={rowsCount}
        onUpdatedRows={setUpdatedRows}
      />
    </LayoutAdminDefault>
  )
}