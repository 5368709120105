import React from 'react'

import { useConsultationContext } from "../../../../../../contexts"
import { TableFilterCustom, TextFieldCustomDate, TextFieldCustomText } from '../../../../../../../../../components'

export function ConsultationTableFilter({}){

  const { statusTable, getAllByFilters, filtersActive, setFiltersActive, handleRemoveFilter} = useConsultationContext()

  const arrConfigFields = [
    {
      key:'data_consulta',
      type:'date',
      fieldName: 'Data da Consulta',
      defaultValues: '',
      dateFormatView: '',
      dateFormatApi: '',
      options: [],
    },
    {
      key:'responsavel',
      type:'text',
      fieldName: 'Responsável',
      defaultValues: '',
      options: [],
    },
    {
      key:'protocolo',
      type:'text',
      fieldName: 'Protocolo',
      defaultValues: '',
      options: [],
    },
  ]

  const FiltersFields = ({control}) => {

    return (
      <>
        <TextFieldCustomDate
					name={"data_consulta"}
					label={"Data da Consulta"}
					control={control}
				/>
        <TextFieldCustomText
          name={"responsavel"}
          label={"Responsável"}
          control={control}
        />
        <TextFieldCustomText
          name={"protocolo"}
          label={"Protocolo"}
          control={control}
        />
      </>
    )
  }

  return (
    <TableFilterCustom
      onApply={async (data={})=>{
        await getAllByFilters(statusTable.currentPage, data)
      }}
      filtersActive={filtersActive}
      setFiltersActive={setFiltersActive}
      arrConfigFields={arrConfigFields}
      handleRemove={handleRemoveFilter}
    >
      {(control) => (
        <FiltersFields
          control={control}
        />
      )}
    </TableFilterCustom>
  )
}