import React from "react";
import { Box } from "@mui/system";
import { Divider, IconButton, Paper, TextField, Tooltip, useTheme, Zoom } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';
import RuleIcon from '@mui/icons-material/Rule';
import PasswordIcon from '@mui/icons-material/Password';

import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../contexts";
import { validatePermission } from "../../../utils/tools";
import { apiUsers } from "../../../services/apiInterface/admin/users";

export function ToolsActionsUsers({textSearch, setTextSearch, rowsSelect, onUpdatedRows}){

  const navigate = useNavigate();

  const theme = useTheme();

  const { userLogged } = useAuthContext();

  const handleClearTextSearch = () => {
    setTextSearch('');
  }

  const handleFormAddUser = () => {
    navigate('/usuarios/adicionar');
  }

  const handleFormEditUser = () => {
    navigate(`/usuarios/${rowsSelect[0].id}/editar`);
  }

  const handleDeleteRow = async () => {
    for (const row of rowsSelect) {
      if (confirm('Deseja realmente excluir este registro?')) {
        const response = await apiUsers.del(row.id);
        if(response.status === 204){
          onUpdatedRows(old => !old);
          alert(`Registro deletado! \n - Nome: ${row.name}`);
        }else{
          alert(response.data.msg)
        }
      }
    }
  };

  const handleFormAttach = () => {
    navigate(`/usuarios/${rowsSelect[0].id}/gruposAcesso`);
  }

  const handleFormChangePass = () => {
    navigate(`/usuarios/${rowsSelect[0].id}/trocarSenha`);
  }

  // const handleAccessHistoryUser = () => {
  //   navigate('/usuarios/:id/historioAcesso', {state: {userSelect: rowsSelect}});
  // }

  return (
    <Box 
      height={theme.spacing(5)} 
      component={Paper} 
      display="flex" 
      alignItems="center"
      padding={1}
      marginX={1}
      paddingX={2}
      gap={1}
    >
      <Box>
        <TextField 
          label="Procurar..." 
          variant="outlined" 
          size="small"
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value)}
        />
        <Tooltip title="Limpar Pesquisa" TransitionComponent={Zoom}>
          <IconButton onClick={handleClearTextSearch}>
            <ClearIcon/>
          </IconButton>
        </Tooltip>
      </Box>
      <Divider orientation="vertical" variant="middle"/>
      <Box>
        <Tooltip title="Adicionar" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleFormAddUser}
            disabled={!validatePermission(userLogged, 'cadastrar_usuario')}
            component="div"
          >
            <AddBoxIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Editar" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleFormEditUser} 
            disabled={!validatePermission(userLogged, 'editar_usuario') === false ? (rowsSelect.length === 1 ? false: true): true}
            component="div"
          >
            <EditIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Remover" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleDeleteRow}
            disabled={!validatePermission(userLogged, 'excluir_usuario') === false ? (rowsSelect.length === 1 ? (userLogged.id === rowsSelect[0].id ? true: false) : true): true}
            component="div"
          >
            <DeleteForeverIcon/>
          </IconButton>
        </Tooltip> 
      </Box>
      <Divider orientation="vertical" variant="middle"/>
      <Box>
        <Tooltip title="Anexar Grupo de Acesso" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleFormAttach}
            disabled={!validatePermission(userLogged, 'vincular_papeis') === false ? (rowsSelect.length === 1 ? false: true): true}
            component="div"
          >
            <RuleIcon/>
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title="Trocar Senha" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleFormChangePass}
            disabled={!validatePermission(userLogged, 'trocar_senha') === false ? (rowsSelect.length === 1 ? false: true): true}
            component="div"
          >
            <PasswordIcon/>
          </IconButton>
        </Tooltip>
      </Box>
      {/* <Box>
        <Tooltip title="Historico de Acesso" TransitionComponent={Zoom}>
          <IconButton 
            onClick={handleAccessHistoryUser}
            disabled={!validatePermission(userLogged, 'historico_acesso_usuarios') === false ? (rowsSelect.length === 1 ? (userLogged.id === rowsSelect[0].id ? true: false) : true): true}
            component="div"
          >
            <PasswordIcon/>
          </IconButton>
        </Tooltip>
      </Box> */}
    </Box>
  )
}