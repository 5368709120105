import React from "react";
import { ButtonSwitchTheme } from '../../../../components';
import { useThemeContext } from "../../../../contexts";
import { LayoutAdminDefault } from "../../../../layouts";

export function PreferencesAdmin(){

  const { handleThemePreferences } = useThemeContext();

  return(
    <LayoutAdminDefault
      title="Preferências"
    >
      <ButtonSwitchTheme
        label="Alternar Tema"
        onClick={handleThemePreferences}
      />
    </LayoutAdminDefault>
  )
}