import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { TableCustomTreatmentsReorderable } from "./components";
import { ButtonCustomSubmit, PageLayout } from "../../../../../components";
import { useConsultationPageContext } from "../../contexts";

export function BusinessPatientTreatmentPlanProcedements() {
	const navigate = useNavigate();
	const { handleConfirmPlan } = useConsultationPageContext()
	const rows = [
		{ id: 1, nome: "Toxina Botulínica (1ª sessão)", data: undefined },
		{ id: 2, nome: "Toxina Botulínica (2ª sessão)", data: undefined },
		{ id: 3, nome: "Microagulhamento", data: undefined },
		{ id: 4, nome: "Peeling Químico", data: undefined },
		{ id: 5, nome: "Limpeza de Pele (1ª sessão)", data: undefined },
		{ id: 6, nome: "Limpeza de Pele (2ª sessão)", data: undefined },
	];

	const handleConfirmTreatmentPlan = () => {
		handleConfirmPlan()
		navigate("../../assessment");
	};

	return (
		<PageLayout.Container>
			<PageLayout.Header.Container>
				<PageLayout.Header.ButtonBack />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container
				sx={{ maxWidth: "1056px", gap: "8px", paddingBottom:'40px'}}
			>
				{/* <ProfileInfo
					fullName="Lívia Silva"
					dateAndAge="18/02/1996 • 27 anos"
				/> */}
				<PageLayout.Body.Title
					subtitle="Consulta / Plano de Tratamento / Seleção de Tratamentos"
					title="Cronograma"
					description="Aqui, você pode criar um plano de tratamento exclusivo
					que atenda às necessidades e desejos únicos da nossa
					paciente. Com base na avaliação inicial, você tem a
					oportunidade de recomendar procedimentos que maximizem
					os resultados desejados. Lembre-se, seu compromisso com a excelência e a paixão
					por transformar vidas é o coração deste processo. Cada
					seleção que você faz guiará nossa paciente em direção a
					um resultado excepcional. Sua experiência e intuição
					ajudarão a criar uma jornada estética excepcional e sob
					medida para a paciente."
				/>
				<Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							padding: "32px",
							backgroundColor: "#1F2937",
							borderTopLeftRadius: "12px",
							borderTopRightRadius: "12px",
						}}
					>
						<Typography
							variant="body-b"
							sx={{ color: "#FFFFFF" }}
						>
							Tratamentos selecionados
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							padding: "32px",
							backgroundColor: "#FFFFFF",
							borderBottomLeftRadius: "12px",
							borderBottomRightRadius: "12px",
							border: "1px solid #1F2937",
						}}
					>
						<Typography variant="big-m" sx={{ color: "#09234A" }}>
							Revise e agende os tratamentos escolhidos para a
							Lívia. Selecione as datas que melhor se encaixam no
							cronograma dela e garanta uma experiência de
							tratamento conveniente.
						</Typography>
						<TableCustomTreatmentsReorderable rows={rows} />
					</Box>
				</Box>
				<Box sx={{mt:'8px'}}>
					<ButtonCustomSubmit
						text={'Confirmar plano de tratamento'}
						onClick={handleConfirmTreatmentPlan}
						sx={{ paddingY: "12px", paddingX: "22px" }}
					/>
				</Box>
			</PageLayout.Body.Container>
		</PageLayout.Container>
	);
}
