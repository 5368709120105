import React from "react";
import { Box, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
	AccordionCustom,
	ButtonCustomSubmit,
	PageLayout,
} from "../../../../../components";
import { assets } from "../../../../../assets/images_sys";
import { CheckboxTreatmentService } from "./components/CheckboxTreatmentService";

export function BusinessPatientTreatmentPlan() {
	const navigate = useNavigate();

	const handleDefineTreatmentPlan = () => navigate("procedements");

	return (
		<PageLayout.Container>
			<PageLayout.Header.Container>
				<PageLayout.Header.ButtonBack />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container
				sx={{ maxWidth: "1056px", gap: "8px", paddingBottom:'40px'}}
			>
				{/* <ProfileInfo
					fullName="Lívia Silva"
					dateAndAge="18/02/1996 • 27 anos"
				/> */}
				<PageLayout.Body.Title
					subtitle="Consulta / Plano de Tratamento"
					title="Seleção de Tratamentos"
					description="Aqui, você pode criar um plano de tratamento exclusivo
					que atenda às necessidades e desejos únicos da nossa
					paciente. Com base na avaliação inicial, você tem a
					oportunidade de recomendar procedimentos que maximizem
					os resultados desejados. Lembre-se, seu compromisso com a excelência e a paixão
					por transformar vidas é o coração deste processo. Cada
					seleção que você faz guiará nossa paciente em direção a
					um resultado excepcional. Sua experiência e intuição
					ajudarão a criar uma jornada estética excepcional e sob
					medida para a paciente."
				/>
				<AccordionCustom headerTitle="Tratamentos Capilares"></AccordionCustom>
				<AccordionCustom headerTitle="Tratamentos Faciais">
					<CheckboxTreatmentService
						title="Toxina Botulínica"
						description="Revitalize sua pele com a aplicação precisa de toxina botulínica. Suavize rugas e linhas de expressão, revelando uma aparência mais jovem e radiante."
						picture={assets.imgs.treatment_plan_facial_1}
					/>
					<Divider />
					<CheckboxTreatmentService
						title="Preenchedores Faciais"
						description="Realce seus traços naturais com preenchedores faciais de alta qualidade. Restaure volume, suavize áreas com perda de elasticidade e alcance um visual rejuvenescido."
						picture={assets.imgs.treatment_plan_facial_2}
					/>
					<Divider />
					<CheckboxTreatmentService
						title="Microagulhamento"
						description="Transforme sua pele com o microagulhamento, um tratamento estimulante. Estimule o colágeno, reduza cicatrizes e melhore a textura, obtendo um brilho saudável."
						picture={assets.imgs.treatment_plan_facial_3}
					/>
					<Divider />
					<CheckboxTreatmentService
						title="Peeling Químico"
						description="Renove sua pele com um peeling químico personalizado. Elimine células mortas, reduza manchas e imperfeições, revelando uma pele mais fresca e radiante."
						picture={assets.imgs.treatment_plan_facial_4}
					/>
					<Divider />
					<CheckboxTreatmentService
						title="Limpeza de Pele"
						description="Mime sua pele com uma limpeza profunda e revitalizante. Remova impurezas, desobstrua os poros e desfrute de uma pele mais clara e suave."
						picture={assets.imgs.treatment_plan_facial_5}
					/>
				</AccordionCustom>
				<AccordionCustom headerTitle="Tratamentos Corporais"></AccordionCustom>
				<Box sx={{mt:'8px'}}>
					<ButtonCustomSubmit
						text={'Elaborar cronograma'}
						onClick={handleDefineTreatmentPlan}
						sx={{ paddingY: "12px", paddingX: "22px" }}
					/>
				</Box>
			</PageLayout.Body.Container>
		</PageLayout.Container>
	);
}
