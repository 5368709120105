import React from "react"

import { GeneralContextProvider } from "./context"
import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { TableFilterGeneral, TableGeneral } from "./components"

export function General() {
  return (
    <ContainerLayout
      title="Finanças"
      subtitle="Gastos Gerais"
      description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      sx={{
        width:'800px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <GeneralContextProvider>
        <TableFilterGeneral/>
        <TableGeneral/>
      </GeneralContextProvider>
    </ContainerLayout>
  )
}