import React, { useState, useRef} from 'react';
import './styles.css';
import { Box, Tooltip, Typography, Zoom } from '@mui/material'
import Divider from '@mui/material/Divider';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { scroller} from 'react-scroll'
import banner from '../../../assets/videos/banner.mp4'
import Icon from '@mdi/react';
import { mdiSkype } from '@mdi/js';
import { imagesSP, imagesPR, imagesSC, imagesSpaces} from '../../../assets/images/images'
import {
  logo, keyHole, chefImg, search, 
  graph, manage, paper, vision, mission, values, 
  visionPerson, missionPerson, valuesPerson, chefPerson, 
  result, chefCoffee, bgFooter} 
  from '../../../assets/images';
import {
  philosophyDescription, 
  visionDescription, 
  missionDescription, 
  valuesDescription,
  firstPartAboutChefDescription,
  secondPartAboutChefDescription,
  thirdPartAboutChefDescription,
  firstPartWhatWeDoDescription,
  secondPartWhatWeDoDescription,
  thirdPartWhatWeDoDescription,
  analysisDescription,
  planningDescription,
  actionPlansDescription,
  managementDescription,
  howWeDoDescription,
  coffeeDescription
} from '../../../assets/texts'
import { get, getConfig } from '../../../environment';

export function Site() {

  const currentYear = new Date().getFullYear();

  const [state, setState] = useState(false);
  const [stateBtn, setStateBtn] = useState(false);
  const [stateBtnBlock3, setStateBtnBlock3] = useState(false);
  const [stateBtnBlock4, setStateBtnBlock4] = useState(false);
  const [visionState, setVisionState] = useState(true);
  const [missionState, setMissionState] = useState(false);
  const [valuesState, setValuesState] = useState(false);
  const [option1State, setOption1State] = useState(true);
  const [option2State, setOption2State] = useState(false);
  const [option3State, setOption3State] = useState(false);
  const [option4State, setOption4State] = useState(false);

  const videoRef = useRef(null);
  const [stop, setStop] = useState(true);

  const [bool, setBool] = useState(true);

  const scroll = (sec, time = 1000) => {
    scroller.scrollTo(sec, {
      duration: time,
      smooth: true,
      offset: -120, 
    })
  }

  const handleVideo = () => {
    setStop((e) => !e);
    setBool((e) => !e)
    if (stop === false) {
        videoRef.current.pause();
    } else {
        videoRef.current.play();
    }
  };

  const pageRefresh = () => {
    scroll("begin", 10)
    window.location.reload();
  }

  const handleClick = () => {
    setState((e) => !e)
    scroll("section1")
  }

  const handleClickBtn = () => {
    setStateBtn((e) => !e)
    scroll("section2")  
  }

  const handleClickBtnBlock3 = () => {
    setStateBtnBlock3((e) => !e) 
    scroll("section3")
  }

  const handleClickBtnBlock4 = () => {
    setStateBtnBlock4((e) => !e);
    scroll("section4") 
  }

  const handleClickOptions = (string) => {
    setOption1State(string === "1" ? true : false)
    setOption2State(string === "2" ? true : false)
    setOption3State(string === "3" ? true : false)
    setOption4State(string === "4" ? true : false)
  }

  const handleClickHiddenIcons = (string) => {
    setVisionState(string === "vision" ? true : false)
    setMissionState(string === "mission" ? true : false)
    setValuesState(string === "values" ? true : false)
  }

  return (
    <Box className={"containerSite"}>
      {!!get('ENV')?
        <Box sx={{width: '100%', textAlign:'center', backgroundColor: '#ccc', display:'block'}}>
          <Typography sx={{fontWeight:'bold'}}>
            {get('ENV') === 'DEV'? 'Ambiente de Desenvolvimento':null}
            {get('ENV') === 'HML'? 'Ambiente de Homologação':null}
          </Typography>
        </Box>
      : 
        null
      }
      <Box className={"bxHeader"}>
        <Box className={"header"}>
          <Box className={"bxImage"} onClick={pageRefresh}>
            <img src={logo} alt="Logo" width={"100%"}/>
          </Box>
          <Box className={"bxNavBar"}>
            <Typography className={"txtHeader"} onClick={handleClick}>
              QUEM SOMOS
            </Typography>
            <Typography className={"txtHeader"} onClick={handleClickBtn}>
              O QUE FAZEMOS
            </Typography>
            <Typography className={"txtHeader"} onClick={handleClickBtnBlock3}>
              COMO FAZEMOS
            </Typography>
            <Typography className={"txtHeader"} onClick={handleClickBtnBlock4}>
              QUEM JÁ DEGUSTOU
            </Typography>
            <Tooltip title="Login ADMIN" TransitionComponent={Zoom}>
              <a href={getConfig('URL_LOGIN_ADMIN')}>
                <img src={keyHole} alt="keyHole" className={"imgKeyHole"}/>
              </a>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Box className={"bxContent"} id={"begin"}>
        <video ref={videoRef} autoPlay muted={bool} src={banner} onClick={handleVideo} className={"video"}/> 
        <Box className={"bxFirstBlock"}>
          <Box className={"chefImg"}>
            <img src={chefImg} alt="Logo"/>
          </Box>
          <Box className={"bxDescription"}>
            <Typography className={"txtTittle"}>
              QUEM SOMOS
            </Typography>
            <Typography className={"txtDescription"}>
              Somos uma consultoria <span className={"txtSpan"}>mão na massa</span>, focada em resultados financeiros de curto
              e médio prazo para os nossos clientes.
            </Typography>
            <button className={"btnMore"} onClick={handleClick}>MAIS</button>
          </Box>
        </Box>

        <Box id={"section1"}>
        {
          state ? 
            <Box className={"firstSectionHidden"}>
              <Typography className={"txtSectionHidden"}>QUEM SOMOS</Typography>
              <Typography className={"txtSectionHiddenTittle"}>FILOSOFIA</Typography>
              <Typography className={"txtSectionHiddenDescription"}>
                {philosophyDescription}
              </Typography>
              <Typography className={"txtSectionHiddenDescription"}>
                Sabemos que a sua experiência somada a um olhar autêntico e especializado é a harmonização perfeita para a gestão do seu negócio.
              </Typography>

              <Box className={"sectionHiddenIcons"}>
                <Box className={"hiddenIcons"}>
                  <img src={vision} alt="Vision" onClick={() => handleClickHiddenIcons('vision')} className={"imgHiddenIcons"}/>
                  <Typography className={"visionIcon"}>Visão</Typography>
                  <Box className={"boxHiddenTriangle"}>
                    {
                      visionState ? 
                        <Box className={"hiddenTriangleVision"}/>
                      :
                      null
                    } 
                  </Box>
                </Box>
                <Box className={"hiddenIcons"}>
                  <img src={mission} alt="Mission" onClick={() => handleClickHiddenIcons('mission')} className={"imgHiddenIcons"}/>
                  <Typography className={"missionIcon"}>Missão</Typography>
                  <Box className={"boxHiddenTriangle"}>
                    {
                      missionState ?
                        <Box className={"hiddenTriangleMission"}/>
                      :
                      null
                    }
                  </Box>
                </Box>
                <Box className={"hiddenIcons"}>
                  <img src={values} alt="Values"  onClick={() => handleClickHiddenIcons('values')} className={"imgHiddenIcons"}/>
                  <Typography className={"valuesIcon"}>Valores</Typography>
                  <Box className={"boxHiddenTriangle"}>
                    {
                      valuesState ?
                          <Box className={"hiddenTriangleValues"}/>
                      :
                      null
                    }
                  </Box>
                </Box>
              </Box>

              {
                visionState ? 
                  <Box className={"bxVision"}>
                    <Typography className={"descVision"}>
                      {visionDescription}
                    </Typography>
                    <img src={visionPerson} alt="VisionPerson" className={"imgVisionPerson"} />
                  </Box>
                :
                null
              }
              {
                missionState ?
                  <Box className={"bxMission"}>
                    <img src={missionPerson} alt="MissionPerson" className={"imgMissionPerson"}/>
                    <Box className={"bxTxtMissionDesc"}>
                      <Typography className={"firstTxtMission"}>
                        Dar aos nossos clientes o fermento para que cresçam fortes.
                      </Typography>
                      <Typography className={"secondTxtMission"}>
                        {missionDescription}
                      </Typography>
                    </Box>
                  </Box>
                :
                null
              }
              {
                valuesState ?
                  <Box className={"bxValues"}>
                    <img src={valuesPerson} alt="ValuesPerson" className={"imgValuesPerson"}/>
                    <Box className={"bxTxtValuesDesc"}>
                      <Typography className={"firstTxtValues"}>
                        Amar, criar e revolucionar são as diretrizes que movem o Chef Financeiro.
                      </Typography>
                      <Typography className={"secondTxtValues"}>
                        {valuesDescription}
                      </Typography>
                    </Box>
                  </Box>
                  :
                null
              } 

              <Box className={"bxTxtTittleChef"}>
                <Typography className={"txtTittleChef"}>
                  QUEM É O CHEF FINANCEIRO
                </Typography>
              </Box>
              <Box className={"bxDescTotal"}>
                <img src={chefPerson} alt="ChefPerson" className={"imgChefPerson"}/>
                <Box className={"descTotal"}>
                  <Typography className={"firstPartTxtChef"}>
                    Jefferson Carlos, especialista em finanças gastronômicas, muito prazer.
                  </Typography>
                  <Typography className={"secondPartTxtChef"}>
                    {firstPartAboutChefDescription}
                  </Typography>
                  <Typography className={"thirdPartTxtChef"}>
                    {secondPartAboutChefDescription}
                  </Typography>
                  <Typography className={"fourthPartTxtChef"}>
                    {thirdPartAboutChefDescription}
                  </Typography>
                </Box>
              </Box>

            </Box>
          : 
            null
        }
        </Box>

        <Box className={"bxSecondBlock"}>
          <Typography className={"txtTittleSecondBlock"}>O QUE FAZEMOS</Typography>
          <Box className={"bxIcons"}>
            <Box className={"bxIconsHalf"}>
              <img src={search} alt="search"/>
              <Typography className={"txtIcons"}>Análise e identificação das oportunidades de melhoria</Typography>
            </Box>
            <Box className={"bxIconsHalf"}>
              <img src={graph} alt="graph"/>
              <Typography className={"txtIcons"}>Planejamento de resultados</Typography>
            </Box>
          </Box> 
          <Box className={"bxIcons"}>
            <Box className={"bxIconsHalf"}>
              <img src={paper} alt="paper"/>
              <Typography className={"txtIcons"}>Planos de ação</Typography>
            </Box>
            <Box className={"bxIconsHalf"}>
              <img src={manage} alt="manage"/>
              <Typography className={"txtIcons"}>Gerenciamento de resultados</Typography>
            </Box>
          </Box> 
          <button className={"secondBtnMore"} onClick={handleClickBtn}>MAIS</button>
        </Box>

        <Box id={"section2"}>
        {
          stateBtn ? 
            <Box className={"bxWhatWeDoHidden"}>
              <Box className={"whatWeDoFirstSection"}>
                <Typography className={"txtTittleWhatWeDoHidden"}>O QUE FAZEMOS</Typography>
                <Typography className={"secondTxtWhatWeDoHidden"}>
                  {firstPartWhatWeDoDescription}
                </Typography>
                <Typography className={"thirdTxtWhatWeDoHidden"}>
                  {secondPartWhatWeDoDescription}
                </Typography>
                <Typography className={"thirdTxtWhatWeDoHidden"}>
                  {thirdPartWhatWeDoDescription}
                </Typography>
              </Box>

              <Box className={"whatWeDoSecondSection"}>
                <Box className={"imgWhatWeDoSecondSection"}>
                  <img src={search} alt="Search" className={"imgSecondSection"}/>
                </Box>
                <Box className={"firstBxDescWhatWeDoSecondSection"}>
                  <Typography className={"txtTittleSecondSectionWhatWeDoHidden"}>
                    Análise e identificação das oportunidades de melhoria
                  </Typography>
                  <Typography className={"secondSectionTxtWhatWeDoHidden"}>
                    {analysisDescription}
                  </Typography>
                </Box>
                <Box className={"imgWhatWeDoSecondSection"}>
                  <img src={graph} alt="Graph" className={"imgSecondSection"}/>
                </Box>
                <Box className={"secondBxDescWhatWeDoSecondSection"}>
                  <Typography className={"secondTxtTittleSecondSectionWhatWeDoHidden"}>
                    Planejamento de resultados
                  </Typography>
                  <Typography className={"secondSectionSecondTxtWhatWeDoHidden"}>
                    {planningDescription}
                  </Typography>
                </Box>
                <Box className={"imgWhatWeDoSecondSection"}>
                  <img src={paper} alt="Paper" className={"imgSecondSection"}/>
                </Box>
                <Box className={"thirdBxDescWhatWeDoSecondSection"}>
                  <Typography className={"thirdTxtTittleSecondSectionWhatWeDoHidden"}>
                    Planos de ação
                  </Typography>
                  <Typography className={"secondSectionThirdTxtWhatWeDoHidden"}>
                    {actionPlansDescription}
                  </Typography>
                </Box>
                <Box className={"imgWhatWeDoSecondSection"}>
                  <img src={manage} alt="Manage" className={"imgSecondSection"}/>
                </Box>
                <Box className={"fourthBxDescWhatWeDoSecondSection"}>
                  <Typography className={"fourthTxtTittleSecondSectionWhatWeDoHidden"}>
                    Gerenciamento dos resultados
                  </Typography>
                  <Typography className={"secondSectionFourthTxtWhatWeDoHidden"}>
                    {managementDescription}
                  </Typography>
                </Box>
              </Box>
            </Box>
          :
            null
        }
        </Box>

        <Box className={"bxThirdBlock"}>
          <Typography className={"txtTittleThirdBlock"}>
            COMO FAZEMOS
          </Typography>
          <Typography className={"txtThirdBlock"}>
            {firstPartWhatWeDoDescription}
          </Typography>
          <img src={result} alt="Result" className={"imgResult"}/>
          <button className={"thirdBtnMore"} onClick={handleClickBtnBlock3}>MAIS</button>
        </Box>

        <Box id={"section3"}>
        {
          stateBtnBlock3 ?
            <Box className={"bxThirdBlockHidden"}>
              <Box className={"howWeDoFirstSection"}>
                  <Typography className={"txtTittleHowWeDoHidden"}>COMO FAZEMOS</Typography>
                  <Typography className={"txtHowWeDoHiddenTittle"}>METODOLOGIA</Typography>
                  <Typography className={"txtHowWeDoHidden"}>
                    {howWeDoDescription}
                  </Typography>
              </Box>
              <Box className={"sectionHiddenOptions"}>
                <Box className={"hiddenOptions"}>
                    <Box className={"hiddenOptionsCircle1"} onClick={() => handleClickOptions('1')}>
                      1
                    </Box>
                    <Typography className={"option1"}>Análise</Typography>
                    <Box className={"boxHiddenTriangle"}>
                      {
                        option1State ?
                          <Box className={"hiddenTriangleOptions1"}/>
                        :
                        null
                      }
                    </Box>
                </Box>
                <Box className={"hiddenOptions"}>
                    <Box className={"hiddenOptionsCircle2"} onClick={() => handleClickOptions('2')}>
                      2
                    </Box>
                    <Typography className={"option2"}>Planejamento</Typography>
                    <Box className={"boxHiddenTriangle"}>
                      {
                        option2State ?
                          <Box className={"hiddenTriangleOptions2"}/>
                        :
                        null
                      }
                    </Box>
                </Box>
                <Box className={"hiddenOptions"}>
                    <Box className={"hiddenOptionsCircle3"} onClick={() => handleClickOptions('3')}>
                      3
                    </Box>
                    <Typography className={"option3"}>Plano de Ação</Typography>
                    <Box className={"boxHiddenTriangle"}>
                      {
                        option3State ?
                          <Box className={"hiddenTriangleOptions3"}/>
                        :
                        null
                      }
                    </Box>
                </Box>
                <Box className={"hiddenOptions"}>
                    <Box className={"hiddenOptionsCircle4"} onClick={() => handleClickOptions('4')}>
                      <img src={manage} alt="Manage" className={"imgHiddenOptions"}/>
                    </Box>
                    <Typography className={"option4"}>Gerenciamento</Typography>
                    <Box className={"boxHiddenTriangle"}>
                      {
                        option4State ?
                          <Box className={"hiddenTriangleOptions4"}/>
                        :
                        null
                      }
                    </Box>
                </Box>
              </Box>

              {
                option1State ?
                  <Box className={"bxOption1"}>
                    <Typography className={"txtOptions"}>Conhecer os artistas do negócio</Typography>
                    <Typography className={"txtOptions"}>Análise do modelo de negócio</Typography>
                    <Typography className={"txtOptions"}>Entendimento dos processos diários do negócio</Typography>
                    <Typography className={"txtOptions"}>Análise dos dados coletados visando resultado para o negócio</Typography>
                  </Box>
                :
                null
              }
              {
                option2State ?
                  <Box className={"bxOption2"}>
                    <Typography className={"txtOptions"}>Oportunidades de receita</Typography>
                    <Typography className={"txtOptions"}>Planejamento de redução de custos, despesas e impostos</Typography>
                    <Typography className={"txtOptions"}>Planejamento dos processos (caso necessário)</Typography>
                    <Typography className={"txtOptions"}>Definições de quais ferramentas serão implementadas</Typography>
                  </Box>
                :
                null
              }
              {
                option3State ?
                  <Box className={"bxOption3"}>
                    <Typography className={"txtOptions"}>Desenvolvimento e aplicação das ferramentas</Typography>
                    <Typography className={"txtOptions"}>Treinamento para as pessoas que vão utilizar as ferramentas</Typography>
                    <Typography className={"txtOptions"}>Negociação com credores</Typography>
                    <Typography className={"txtOptions"}>Organização e controle das rotinas diárias</Typography>
                  </Box>
                :
                null
              }
              {
                option4State ?
                  <Box className={"bxOption4"}>
                    <Typography className={"txtOptions"}>Apresentação dos resultados: D.R.E. & Fluxo de Caixa</Typography>
                    <Typography className={"txtOptions"}>Desenvolvimento de metas</Typography>
                    <Typography className={"txtOptions"}>Gerenciamento dos planos de ações</Typography>
                    <Typography className={"txtOptions"}>Os resultados serão sempre analisados mensalmente em um processo contínuo de aprimoramento</Typography>
                  </Box>
                :
                null
              }
            </Box>
            :
            null
        }
        </Box>

        <Box className={"bxFourthBlock"}>
          <Typography className={"txtTittleFourthBlock"}>QUEM JÁ DEGUSTOU</Typography>
          <Box className={"bxMiddleFourthBlock"}>
            <Box className={"bxTxtMiddleFourthBlock"}>
              <Typography className={"firstTxtMiddleFourthBlock"}>{`"A partir dele eu consegui ver meu negócio de uma outra maneira"`}</Typography>
              <Typography className={"secondTxtMiddleFourthBlock"}>Cristina e Olívia Brener Officina Restô Bar</Typography>
            </Box>
            <iframe src="https://www.youtube.com/embed/t18RCkmAdGM" tittle="youtube" className='bxVideoYoutube'/>
          </Box>
          <button className={"fourthBtnMore"} onClick={handleClickBtnBlock4}>MAIS</button>
        </Box>

        <Box id={"section4"}>
        {
          stateBtnBlock4 ?
            <Box className={"bxFourthBlockHidden"}>
              <Typography className={"txtTittleFourthBlockHidden"}>Espaços Gastronômicos</Typography>
              <Box className={"bxStatesSP"}>
                <Typography className={"txtHeaderFourthBlockSp"}>São Paulo</Typography>
                {
                  imagesSP.map((index) =>{
                    return(
                      <Box className={"bxImgStates"} key={index.id}>
                        <Box className={"subBxImgStates"}>
                          <img src={index.image} alt="" className={"imgStates"}/>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
              <Box className={"bxStatesPR"}>
                <Typography className={"txtHeaderFourthBlockPr"}>Paraná</Typography>
                {
                  imagesPR.map((index) =>{
                    return(
                      <Box className={"bxImgStates"} key={index.id}>
                        <Box className={"subBxImgStates"}>
                          <img src={index.image} alt="" className={"imgStatesPr"}/>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
              <Box className={"bxStatesSC"}>
                <Typography className={"txtHeaderFourthBlockSc"}>Santa Catarina</Typography>
                {
                  imagesSC.map((index) =>{
                    return(
                      <Box className={"bxImgStates"} key={index.id}>
                        <Box className={"subBxImgStates"}>
                          <img src={index.image} alt="" className={"imgStatesPr"}/>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
              <Typography className={"txtTittleFourthBlockHidden"}>Espaços Diversos</Typography>
              <Box className={"bxStatesSpaces"}>
                {
                  imagesSpaces.map((index) =>{
                    return(
                      <Box className={"bxImgStates"} key={index.id}>
                        <Box className={"subBxImgStates"}>
                          <img src={index.image} alt="" className={"imgStatesPr"}/>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
            </Box>
          :
          null
        }
        </Box>

        <Box className={"bxFifthBlock"}>
          <img src={chefCoffee} alt="Coffee"/>
          <Box className={"bxCoffee"}>
            <Typography className={"txtTittleCoffee"}>VAMOS TOMAR UM CAFÉ!</Typography>
            <Box className={"bxElementsCoffee"}>
              <Box className={"bxSocialMediaIcons"}>
                <Box className={"socialMediaIcons"} onClick={() => window.open("https://www.facebook.com/cheffinanceiro")}>
                  <FacebookIcon className={"iconSm"}/>
                </Box>
                <Box className={"socialMediaIcons"} onClick={() => window.open("http://www.linkedin.com/company/chef-financeiro")}>
                  <LinkedInIcon className={"iconSm"}/>
                </Box>
                <Box className={"socialMediaIcons"} onClick={() => window.open("skype:jefferson.mineiro?call")}>
                  <Icon path={mdiSkype}
                  title="Skype"
                  className={"iconSm"}
                />
                </Box>
                <Box className={"socialMediaIcons"} onClick={() => window.open("mailto:jefferson@cheffinanceiro.com.br")}>
                  <EmailIcon className={"iconSm"}/>
                </Box>
                <Box className={"socialMediaIcons"} onClick={() => window.open("tel:041995354439")}>
                  <PhoneIphoneIcon className={"iconSm"}/>
                </Box>
                <Box className={"socialMediaIcons"} onClick={() => window.open("https://wa.me/5541995354439")}>
                  <WhatsAppIcon className={"iconSm"}/>
                </Box>
              </Box>
              <Box className={"bxTxtCoffee"}>
                <Typography className={"txtDescTittleCoffee"}>CADA CASO É UM CASO!</Typography>
                <Typography className={"txtDescCoffee"}>{coffeeDescription}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={"bxFooter"}>
          <Box className={"bxHeaderFooter"}>
            <img src={bgFooter} alt="ImgFooter"/>
          </Box>
          <Box className={"bxMiddleFooter"}>
            <Box className={"subBxMiddleFooter"}>
              <Typography className={"txtFooterFirstBxFocus"}>SOBRE NÓS</Typography>
              <Typography className={"txtFooterFirstBx"} onClick={handleClick}>QUEM SOMOS</Typography>
              <Typography className={"txtFooterFirstBx"} onClick={handleClickBtn}>O QUE FAZEMOS</Typography>
              <Typography className={"txtFooterFirstBx"} onClick={handleClickBtnBlock3}>COMO FAZEMOS</Typography>
              <Typography className={"txtFooterFirstBx"} onClick={handleClickBtnBlock4}>QUEM JÁ DEGUSTOU</Typography>
            </Box>
            <Divider orientation="vertical" className={"divider"}/>
            <Box className={"subBxMiddleFooter"}>
              <Typography className={"txtFooterFirstBxFocus"}>CONTATO</Typography>
              <Box className={"txtFooterFirstBx"} onClick={() => window.open("skype:jefferson.mineiro?call")}>
                <Icon path={mdiSkype}
                  title="Skype"
                  className={"iconsFooter"}
                />
                cheffinanceiro
              </Box>
              <Box className={"txtFooterFirstBx"} onClick={() => window.open("mailto:jefferson@cheffinanceiro.com.br")}>
                <EmailIcon className={"iconsFooter"}/>
                email
              </Box>
              <Box className={"txtFooterFirstBx"} onClick={() => window.open("tel:041995354439")}>
                <PhoneIphoneIcon className={"iconsFooter"}/>
                (41) 99535-4439
              </Box>
              <Box className={"txtFooterFirstBx"} onClick={() => window.open("https://wa.me/5541995354439")}>
                <WhatsAppIcon className={"iconsFooter"}/>
                (11) 93801-5961
              </Box>
            </Box>
            <Divider orientation="vertical" className={"divider"}/>
            <Box className={"subBxMiddleFooter"}>
              <Typography className={"txtFooterFirstBxFocus"}>NOVIDADES</Typography>
              <Box className={"txtFooterFirstBx"} onClick={() => window.open("https://www.facebook.com/cheffinanceiro")}>
                <FacebookIcon className={"iconsFooter"}/>
                (41) Facebook
              </Box>
              <Box className={"txtFooterFirstBx"} onClick={() => window.open("http://www.linkedin.com/company/chef-financeiro")}>
                <LinkedInIcon className={"iconsFooter"}/>
                (11) LinkedIn
              </Box>
            </Box>
          </Box>
          <Box className={"footer"}>{`©2014-${currentYear} - Chef Financeiro`}</Box>
        </Box>

      </Box>
    </Box>
  )
}
