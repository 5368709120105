import { useContext, createContext, useState } from "react";

const TableCustomContext = createContext({});
export const useTableCustomContext = () => useContext(TableCustomContext);

export default function TableCustomContainer({ rows, columns, children }) {
	const [filters, setFilters] = useState({});

	const handleAddFilters = (filters) => {
		setFilters(filters);
	};

	const handleRemoveFilter = (filterName) => {
		const { [filterName]: removedFilter, ...rest } = filters;
		setFilters(rest);
	};

	return (
		<TableCustomContext.Provider
			value={{
				rows,
				columns,
				filters,
				handleAddFilters,
				handleRemoveFilter,
			}}
		>
			{children}
		</TableCustomContext.Provider>
	);
}
