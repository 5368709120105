import { Error } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

function ErrorMessageForm({ errorField }) {
	return (
		errorField && (
			<Stack spacing="5px" direction="row" alignItems="center">
				<Error sx={{ color: "#FF6961" }} />
				<Typography color="#FF6961">{errorField.message}</Typography>
			</Stack>
		)
	);
}

export default ErrorMessageForm;
