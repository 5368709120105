import { getSexo } from "../sexo";
import { api } from "../../../axios_config";
import { getLocalidades } from "../localidade";
import { get } from "../../../../environment";

const getOne = async (id) => {
  const resp = await api.get(`/cadastros/clientes/${id}`);
  return resp.data;
}

const getAll = async (page, search) => {
  const resp = await api.get(`/cadastros/clientes?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&search=${search}`)
  return resp;
}

const getAllUsers = async (id, page, search) => {
  const resp = await api.get(`/cadastros/clientes/${id}/usuarios?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&search=${search}`)
  return resp;
}

const create = async (newRegister) => {
  const resp = await api.post('/cadastros/clientes', newRegister);
  return resp.data;
}

const createSubdomain = async (client_id, subdomain, userDefault) => {
  const resp = await api.post(`/cadastros/clientes/${client_id}/subdominio`, {subdomain, userDefault});
  return resp.data;
}

const update = async (id, client) => {
  const {data: {ok, msg}} = await api.put(`/cadastros/clientes/${id}`, client);
  return { ok: ok, msg: msg}
}

const updatePassAdmin = async (client_id, subdomain_id, userDefault) => {
  const resp = await api.put(`/cadastros/clientes/${client_id}/subdominio/${subdomain_id}`, {userDefault});
  return resp.data;
}

const uploadImg = async (formData) => {
  const configHeader = {     
    headers: { 'content-type': 'multipart/form-data' }
  }
  const resp = await api.post('/cadastros/clientes/uploadImg', formData, configHeader);
  return resp.data;
}

const getOptionsCadastre = async () => {
  const optionsCadastre = {};

  const resp1 = await api.get('/cadastros/categoriasNegocio');
  if(resp1.data.ok){
    const options = [];
    for (const opt of resp1.data.registers) {
      options.push({label: opt.descricao, value: opt.id});
    }
    optionsCadastre.businessCategory = options;
  }else{
    new Error('Erro na busca das opções das categorias de negócio!')
  }
  
  const resp2 = await api.get('/cadastros/classificacaoEmpresa');      
  if(resp2.data.ok){
    const options = [];
    for (const opt of resp2.data.registers) {
      options.push({label: opt.descricao, value: opt.id});
    }
    optionsCadastre.classification = options;
  }else{
    new Error('Erro na busca das opções da classificação da empresa!')
  }

  const optionsLocalidades = await getLocalidades();  
  optionsCadastre.locality = optionsLocalidades;    

  const optionsSexo = await getSexo();   
  optionsCadastre.gender = optionsSexo;       

  return optionsCadastre;
}

const getSubdomainByClientId = async (id) => {
  const resp = await api.get(`/cadastros/clientes/${id}/subdominio`);
  return resp.data;
}

export const apiClient = {
  getOne,
  getAll,
  create,
  createSubdomain,
  update,
  uploadImg,
  getAllUsers,
  updatePassAdmin,
  getOptionsCadastre,
  getSubdomainByClientId
}