import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Box, IconButton, Modal, Slide, Tooltip } from '@mui/material'

import { ButtonCustomAdd } from '../ButtonCustomAdd'
import { TypographyCustom } from '../TypographyCustom'
import { ButtonCustomBack } from '../ButtonCustomBack'

export const ModalCustom = ({ children, title='', subTitle='',isOpen=false, onClose=()=>{}, onCancel=()=>{}, onSubmit=()=>{}, textSubmit='', sx={} }) => {

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}
    >
      <Slide in={isOpen} direction="up">
        <Box sx={{ width:'400px', backgroundColor:'#FFFFFF', borderRadius:'8px', ...sx}}>
          <Box sx={{m:'20px', display:'flex', flexDirection:'column', gap:'12px'}}>
            <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
                  <TypographyCustom
                    text={title}
                    type={'body-m'}
                  />
                </Box>
                <Tooltip title="Fechar">
                  <IconButton onClick={onClose}>
                    <XMarkIcon style={{color: '#4B5563', width:'20px', height:'20px'}}/>
                  </IconButton>
                </Tooltip>
              </Box>
              <TypographyCustom
                text={subTitle}
                type={'subtitle-b-v2'}
              />
            </Box>
            <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'15px'}}>
              {children}
              <Box sx={{ width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between', gap:'20px', mb:'40px' }}>
                <ButtonCustomBack
                  text={'Cancelar'}
                  onClick={onCancel}
                  sx={{
                    width:'30%'
                  }}
                />
                <ButtonCustomAdd
                  text={textSubmit}
                  haveIcon={false}
                  onClick={onSubmit}
                  sx={{
                    width:'70%'
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}