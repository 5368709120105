import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";

import { LayoutAdminDefault } from "../../../../../layouts";
import { apiPermissionsRole } from "../../../../../services/apiInterface/admin/permissionsRole";
import { apiPermissions } from "../../../../../services/apiInterface/admin/permissions";
import {TransferList} from "../../../../../components";
import { removeDuplicate } from "../../../../../utils/tools";

export function AttachPermissionsRole() {

  const params = useParams()

  const navigate = useNavigate();

  const [ allPermissions, setAllPermissions ] = useState([]);
  const [ selectedPermissions, setSelectedPermissions ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  const handleBack = () => {
    navigate('/papeis');
  }

  const sendForm = async () => {
    try {
      const body = {
        roleId: params?.id,
        permissions: selectedPermissions
      }
      const {ok, msg} = await apiPermissionsRole.post(body);

      navigate('/papeis', {state: {type: ok ?'success':'error', msg: msg}});

    } catch (error) {
      navigate('/papeis', {state: {type:'error', msg: error.message}});
    }
  }

  const handleAttach = () => {
    sendForm();
  }

  useEffect(()=>{
    (async () => {
      try {
        setLoading(true);
        const {ok: okPermissionsRole, msg: msgPermissionsRole, permissionsRole} = await apiPermissionsRole.getPermissionsByRoleId(params.id);
  
        if(okPermissionsRole){
          setSelectedPermissions(permissionsRole);
          const {ok: okPermissions, msg: msgPermissions, permissions} = await apiPermissions.getAll();
          
          if(okPermissions){
            setAllPermissions(removeDuplicate(permissionsRole, permissions));
          }else{
            alert(msgPermissions)
          }
        }else{
          alert(msgPermissionsRole)
        }
  
      } catch (error) {
        alert(error);
      } finally{
        setLoading(false);
      }
    })()
  },[]);

  return (
    <LayoutAdminDefault
      title="Vincular permissões do grupo de acesso"
    >
      {!loading ? 
        <>
          <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2, height:'90%'}}>
            <TransferList
              listLeft={allPermissions}
              setListLeft={setAllPermissions}
              listRight={selectedPermissions}
              setListRight={setSelectedPermissions}
            />
          </Box>

          <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2, height:'5%'}}>
            <Button
              variant="contained" 
              onClick={handleAttach}
              sx={{width:"15vw"}}
              color="primary"
            >
              <Typography sx={{fontSize:"18px"}}>Vincular</Typography>
            </Button>

            <Button 
              variant="contained" 
              onClick={handleBack}
              sx={{width:"15vw"}}
              color="error"
            >
              <Typography sx={{fontSize:"18px"}}>Voltar</Typography>
            </Button>
          </Box>
        </>
      :
        <Typography>
          Carregando...
        </Typography>

      }
      
    </LayoutAdminDefault>
  )
}