import { Box, Button, Divider, Typography, RadioGroup, FormControlLabel, Radio, FormControl} from "@mui/material";
import {
	AccordionCustom,
	AccordionInnerCustom,
	PageLayout,
	TextFieldCustomFile,
	Notes,
	ButtonCustomSubmit,
} from "../../../../../components";
import {
	ProfileInfo,
	GalleryHumanFace,
	RadioGroupFacialBiotype,
	RadioGroupFacialBiotypeTypes,
	TreatmentsList,
	TableCustomTreatments,
	TreatmentsPaymentPending,
} from "./components";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { useConsultationPageContext } from "../../contexts";

export function BusinessPatientGeneralConsultation() {
	const location = useLocation();
	const navigate = useNavigate();

	const { confirmPlan, confirmPayment  } = useConsultationPageContext()

	const rows = [
		{ id: 1, nome: "Toxina Botulínica (1ª sessão)", data: "13/11/2023" },
		{ id: 2, nome: "Toxina Botulínica (2ª sessão)", data: "13/11/2023" },
		{ id: 3, nome: "Microagulhamento", data: "13/11/2023" },
		{ id: 4, nome: "Peeling Químico", data: "13/11/2023" },
		{ id: 5, nome: "Limpeza de Pele (1ª sessão)", data: "13/11/2023" },
		{ id: 6, nome: "Limpeza de Pele (2ª sessão)", data: "13/11/2023" },
	];

	const handleGoToTreatmentPlan = () => {
		navigate(`../treatment-plan`);
	};

	const handleAlterTreatment = () => {
		navigate(`../treatment-plan`);
	};

	return (
		<PageLayout.Container>
			<PageLayout.Header.Container>
				<PageLayout.Header.ButtonBack />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container
				sx={{ maxWidth: "1056px", gap: "8px", paddingBottom:'40px'}}
			>
				{/* <ProfileInfo
					fullName="Lívia Silva"
					dateAndAge="18/02/1996 • 27 anos"
				/> */}
				<PageLayout.Body.Title
					subtitle="Consulta"
					title="Consulta e Avaliação Inicial"
					description="A toxina botulínica é indicada para amenizar linhas de
					expressão e rugas profundas. Por exemplo, as linhas
					verticais entre as sobrancelhas; pés-de-galinha nos
					cantos dos olhos; linhas horizontais na testa e nas
					bandas do músculo platisma, conhecido como pescoço de
					peru."
				/>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: 'flex-start',
					}}
				>
					<Typography variant="body-r" sx={{ color: "#374151" }}>
						Jornada do Paciente
					</Typography>
				</Box>
				<AccordionCustom headerTitle="Histórico clínico">
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "24px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "16px",
							}}
						>
							<Typography variant="big-m">
								Está atualmente sob tratamento médico? Se sim,
								qual o motivo?
							</Typography>
							<FormControl>
								<RadioGroup>
									<FormControlLabel
										value={false}
										control={<Radio />}
										label={
											<Typography variant="inputText-r-m">
												Não
											</Typography>
										}
									/>
									<FormControlLabel
										value={true}
										control={<Radio />}
										label={
											<Typography variant="inputText-r-m">
												Sim
											</Typography>
										}
									/>
								</RadioGroup>
							</FormControl>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "16px",
							}}
						>
							<Typography variant="big-m">
								Está tomando algum medicamento
								(anti-inflamatório, antibiótico, corticoide,
								etc.)? Se sim, qual o motivo?
							</Typography>
							<FormControl>
								<RadioGroup>
									<FormControlLabel
										value={false}
										control={<Radio />}
										label={
											<Typography variant="inputText-r-m">
												Não
											</Typography>
										}
									/>
									<FormControlLabel
										value={true}
										control={<Radio />}
										label={
											<Typography variant="inputText-r-m">
												Sim
											</Typography>
										}
									/>
								</RadioGroup>
							</FormControl>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "16px",
							}}
						>
							<Typography variant="big-m">
								Tem alergia a algum medicamento? Se sim,
								especifique qual.
							</Typography>
							<FormControl>
								<RadioGroup>
									<FormControlLabel
										value={false}
										control={<Radio />}
										label={
											<Typography variant="inputText-r-m">
												Não
											</Typography>
										}
									/>
									<FormControlLabel
										value={true}
										control={<Radio />}
										label={
											<Typography variant="inputText-r-m">
												Sim
											</Typography>
										}
									/>
								</RadioGroup>
							</FormControl>
						</Box>
						<Box
							sx={{
								paddingX: "20px",
								paddingY: "12px",
								display: "flex",
								backgroundColor: "#F9FAFB",
								border: "1px solid #6B7280",
								borderRadius: "8px",
							}}
						>
							<Typography
								variant="big-m"
								sx={{ color: "#374151", lineHeight: "24px" }}
							>
								Tenho alergia a penicilina. Descobri essa
								alergia na adolescência, quando precisei tomar
								um medicamento contendo penicilina e tive uma
								reação alérgica grave.
							</Typography>
						</Box>
					</Box>
				</AccordionCustom>
				<AccordionCustom
					headerTitle="Queixas"
					headerLabelEnd="14 Ago, 2023"
				>
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				<AccordionCustom
					headerTitle="Fotos"
					headerLabelEnd="14 Ago, 2023"
				>
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-start",
						mt:'12px'
					}}
				>
					<Typography variant="body-r" sx={{ color: "#374151" }}>
						Avaliação Inicial
					</Typography>
				</Box>
				<AccordionCustom
					headerTitle="Consulta e Avaliação Inicial"
					headerLabelEnd="14 Ago, 2023"
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "8px",
							marginY: "8px",
						}}
					>
						<AccordionInnerCustom headerTitle="Avaliação Capilar">
							<Typography>Work in progress</Typography>
							<Divider />
							<AccordionInnerCustom headerTitle="Fotos Capilar">
								<Typography>Work in progress</Typography>
							</AccordionInnerCustom>
						</AccordionInnerCustom>
						<Divider />
						<AccordionInnerCustom headerTitle="Avaliação Facial">
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "32px",
								}}
							>
								<Typography
									variant="big-b"
									sx={{ color: "#374151" }}
								>
									Morfometria / Proporções da face
								</Typography>
								<GalleryHumanFace />
								<Notes value={true} />
								<Divider />
								<Typography
									variant="big-b"
									sx={{ color: "#374151" }}
								>
									Perfiloplastia / Proporções verticais
								</Typography>
								<GalleryHumanFace useSideFace />
								<Notes />
								<Divider />
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										gap: "8px",
									}}
								>
									<Typography
										variant="big-b"
										sx={{ color: "#374151" }}
									>
										Biotipo facial
									</Typography>
									<Typography
										variant="body-r-v2"
										sx={{ color: "#374151" }}
									>
										Os Biotipos Faciais tem uma relação
										muito grande com padrões de cranio.
										Rostos mais longos, os dolicofaciais,
										buscam a iluminação de modo que diminua
										as dimensões verticais, rostos mais
										largos, ou braquifaciais, buscam fazer
										com que a iluminação aumente as mesmas
										dimensões verticias.
									</Typography>
									<RadioGroupFacialBiotype />
									<Notes />
								</Box>
								<Divider />
								<Box
									sx={{
										display: "flex",
										flex: 1,
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									<Typography
										variant="big-b"
										sx={{ color: "#374151" }}
									>
										Biotipo facial / Braquifacial,
										mesofacial ou dolicofacial
									</Typography>
									<Button variant="outlined">
										<Typography variant="button-s-v2">
											Mostrar cálculo do crânio
										</Typography>
									</Button>
								</Box>
								<RadioGroupFacialBiotypeTypes />
								<Notes />
							</Box>
							<Divider />
							<AccordionInnerCustom headerTitle="Fotos Facial">
								<Typography>Work in progress</Typography>
							</AccordionInnerCustom>
						</AccordionInnerCustom>
						<Divider />
						<AccordionInnerCustom headerTitle="Avaliação Corporal">
							<Typography>Work in progress</Typography>
							<Divider />
							<AccordionInnerCustom headerTitle="Fotos Corporal">
								<Typography>Work in progress</Typography>
							</AccordionInnerCustom>
						</AccordionInnerCustom>
					</Box>
				</AccordionCustom>
				{confirmPlan &&
					<>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: (confirmPlan && !confirmPayment) ? "space-between" : 'flex-start',
								mt:'12px'
							}}
						>
							<Typography variant="body-r" sx={{ color: "#374151" }}>
								Plano de Tratamento
							</Typography>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end",
								}}
							>
								{(confirmPlan && !confirmPayment) &&
									<Box>
										<ButtonCustomSubmit
											text={'Alterar tratamento'}
											onClick={handleAlterTreatment}
											sx={{ paddingY: "10px", paddingX: "18px" }}
										/>
									</Box>
								}
							</Box>
						</Box>
						<AccordionCustom headerTitle={`${confirmPayment ? '' : `Orçamento - `}Plano de Tratamento #XF45J`}>
							{confirmPayment ? 
								<TreatmentsList />
							:
								<>
									<TreatmentsPaymentPending />
									<TableCustomTreatments rows={rows} />
								</>
							}
						</AccordionCustom>
					</>
				}
				{(confirmPlan && confirmPayment) && 
					<>
						<AccordionCustom
							headerTitle="Exames"
							headerLabelEnd="14 Ago, 2023"
						>
							<Typography>Conteúdo aqui</Typography>
						</AccordionCustom>
						<AccordionCustom
							headerTitle="Recibo"
							headerLabelEnd="14 Ago, 2023"
						>
							<Typography>Conteúdo aqui</Typography>
						</AccordionCustom>
						<AccordionCustom
							headerTitle="Contrato de Prestação de Serviços"
							headerLabelEnd="14 Ago, 2023"
						>
							<Typography>Conteúdo aqui</Typography>
						</AccordionCustom>
					</>
				}
				{!confirmPlan && 
					<Box sx={{mt:'8px'}}>
						<ButtonCustomSubmit
							text={'Definir plano de tratamento'}
							onClick={handleGoToTreatmentPlan}
							sx={{ paddingY: "10px", paddingX: "18px" }}
						/>
					</Box>
				}
			</PageLayout.Body.Container>
		</PageLayout.Container>
	);
}
