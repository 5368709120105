import React, { useEffect } from "react";
import { ReactSVG } from "react-svg";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

import { assets } from "../../../../../assets/images_sys";

export function OnboardingProcessingResultClients() {
	const {state} = useLocation();

	const navigate = useNavigate();

	useEffect(() => {
    const timer = setTimeout(() => {
      // Redirect to another screen after 3 seconds (3000 milliseconds)
      navigate('/onboarding/resultProfile', {state: state});
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

	const [ setIsAnswerLaterButtonActive ] = useOutletContext();

  useEffect(()=>{
    setIsAnswerLaterButtonActive(false);
  },[]);

	return (
		<Box sx={{ display:'flex', width: "100vw", height:'94%', backgroundColor: "#F3F4F6"}}>
			<Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', zIndex:1}}>
				<Box sx={{width:'526.77px', zIndex:2}}>
					<Box sx={{width:'100%'}}>
						<ReactSVG src={assets.svgs.svg_processando_perfil} style={{width: '90%', height: 'auto'}}/>
					</Box>
					<Typography variant="h1" sx={{ mt:'48px', textAlign:'center', fontSize:'32px', fontWeight:700, lineHeight:'120%', color:'#374151', fontStyle:'normal', letterSpacing:'0%', fontFamily:'Inter'}}>
						Estamos analisando e construindo seu perfil.
					</Typography>
					<Typography sx={{ mt:'27px', textAlign:'center', fontSize:'20px', fontWeight:400, lineHeight:'150%', color:'#6B7280', fontStyle:'normal', letterSpacing:'0%', fontFamily:'Inter'}}>
						Aguarde, é rapidinho...
					</Typography>
				</Box>
				<Box sx={{position: 'absolute', zIndex:9999, marginBottom:'300px'}}>
					<CircularProgress color="info" sx={{mt:'27px'}}/>
				</Box>
			</Box>
		</Box>
	);
}
