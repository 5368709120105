import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useComplaintsContext } from "../..";

export default function CheckboxGroup({ label, id, options = [] }) {
	const { handleToggleCheckedComplaint } = useComplaintsContext();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				backgroundColor: "#FFFFFF",
				padding: "28px",
				borderRadius: "12px",
				gap: "8px",
			}}
		>
			<Typography sx={{ color: "#09234A" }} variant="body-b">
				{label}
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				{options.map((option) => {
					const optionId = option.id;
					const label = option.label;

					return (
						<FormControlLabel
							key={`chgroup-${id}-${optionId}`}
							control={
								<Checkbox
									onChange={(_, checked) =>
										handleToggleCheckedComplaint(
											id,
											option.id,
											checked
										)
									}
								/>
							}
							label={
								<Typography
									sx={{ color: "#374151" }}
									variant="label-m"
								>
									{label}
								</Typography>
							}
						/>
					);
				})}
			</Box>
		</Box>
	);
}
