import React from "react";
import { Route, Routes } from "react-router-dom"
import ProtectedRouters from "../routers/ProtectedRouters"

import { ClientsLogin, NotFound } from "../../pages/publics"
import { AppAuthProvider, AppInfoProvider } from "../../contexts"

import { ModuleBusinessRouters } from "../../modules/private/bussiness/routers"
import { ModulePlanningRouters } from "../../modules/private/planning/routers"
import { ModuleDiagnosticRouters } from "../../modules/private/diagnostic/routers"
import { ModuleOnboardingRouters } from "../../modules/private/onborading/routers"
import { ModuleAgendaPublicRouters } from "../../modules/public/agendaPublic/routers"

export const AppClientRouters = () => {

  return (
		<Routes>
			<Route path="/">
				<Route
					index
					element={
						<AppInfoProvider>
							<AppAuthProvider>
								<ProtectedRouters>
									<ClientsLogin />
								</ProtectedRouters>
							</AppAuthProvider>
						</AppInfoProvider>
					}
				/>

				{ModuleAgendaPublicRouters}
				{ModuleOnboardingRouters}
				{ModuleDiagnosticRouters}
				{ModulePlanningRouters}
				{ModuleBusinessRouters}
				<Route path="*" element={<NotFound />} />
			</Route>

		</Routes>
	)
}