import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { ButtonCustomClear, ButtonCustomRemove, TableButtonRemove } from "../../../../../../../components";

export function TableCustomTreatments({ rows = [] }) {
	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>
						<Typography
							variant="body-r-v2"
							sx={{ color: "#4B5563" }}
						>
							Nome
						</Typography>
					</TableCell>
					<TableCell>
						<Typography
							variant="body-r-v2"
							sx={{ color: "#4B5563" }}
						>
							Data
						</Typography>
					</TableCell>
					<TableCell>
						<Typography
							variant="body-r-v2"
							sx={{ color: "#4B5563" }}
						>
							Ações
						</Typography>
					</TableCell>
					<TableCell />
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((row) => {
					return (
						<TableRow key={row.id}>
							<TableCell>
								<Typography
									variant="big-m"
									sx={{ color: "#3466B2" }}
								>
									{row.nome}
								</Typography>
							</TableCell>
							<TableCell>
								<Typography
									variant="big-m"
									sx={{ color: "#3466B2" }}
								>
									{row.data}
								</Typography>
							</TableCell>
							<TableCell>
								<TableButtonRemove/>
							</TableCell>
							<TableCell>
								{row.picture && (
									<img
										src={row.picture}
										alt="proceedment picture"
									/>
								)}
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
}
