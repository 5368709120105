import React, { useEffect, useState } from 'react';
import { styled, useTheme} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { ReactSVG } from 'react-svg';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Divider, IconButton, Button  } from '@mui/material';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/solid';
import { KeyboardBackspace, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'

import { useDrawer } from './hook/useDrawer';
import { assets } from '../../assets/images_sys';
import { TypographyCustom } from '../TypographyCustom';

const drawerWidth = '300px';

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeaderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export function DrawerCustom({children, disableHeader = false, module = ''}) {
  
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(true);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  const { getOptions, getTitle } = useDrawer({ module: module});

  const listDrawer = getOptions();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const DrawerHeader = () => {
    return (
      <DrawerHeaderStyle>
        {!open ?
          <Box sx={{p:'8px', width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <ReactSVG 
              src={assets.svgs.svg_logo_white_clear}
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 40px')
              }}
            />
            <IconButton onClick={handleDrawerOpen}>
              <ChevronDoubleRightIcon style={{width:'20px', height:'20px'}} color={'#FFFFFF'}/>
            </IconButton>
          </Box>
          :
          <Box sx={{width:'100%', display:'flex', alignItems:'center'}}>
            <Box sx={{p:'8px', width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <ReactSVG 
                src={assets.svgs.svg_logo_white_clear}
                beforeInjection={(svg) => {
                  svg.setAttribute('style', 'width: 40px')
                }}
              />
              <TypographyCustom
                text={getTitle()}
                type='body-b'
                colorText='#FFFFFF'
              />
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? 
                  <ChevronDoubleLeftIcon style={{width:'20px', height:'20px'}} color={'#FFFFFF'}/> 
                  :
                  <ChevronDoubleLeftIcon style={{width:'20px', height:'20px'}} color={'#FFFFFF'}/>
                  // <KeyboardDoubleArrowLeft/> : 
                  // <KeyboardDoubleArrowLeft sx={{
                  // color: '#FFFFFF'
                }
              </IconButton>
            </Box>
          </Box>
        }
      </DrawerHeaderStyle>
    )
  }

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const autoSelectItemOnLoad = () => {
    const { pathname } = location;
    let found = false;

    for (let i = 0; i < listDrawer.length && !found; i++) {
      const { id, children } = listDrawer[i];

      if(!!children && Array.isArray(children)){
        for (let j = 0; j < children.length && !found; j++) {
          const { id: subId, path } = children[j];
  
          if (pathname === path) {
            found = true;
            setExpanded([id]);
            setSelected(subId);
          }
        }
      }
    }
  }

  const renderTree = (nodes) => {
    return nodes.map((node, index)=>{
      let styleTypography = {};
      let colorText = selected == node.id ? '#EFF5FF':'#A7CAFF';
      if(!!node.children){
        styleTypography = {
          fontSize:'14px', fontWeight:500, lineHeight:'150%', color:'#FFFFFF', fontFamily:'Inter'
        }
      }else{
        styleTypography = {
          fontSize:'12px', fontWeight:500, lineHeight:'140%', color:colorText, fontFamily:'Inter'
        }
      }
      return (
        <TreeItem 
          key={index} 
          nodeId={node.id}
          label={
            <>
              <Box sx={{ width:'100%', height:'45px', display:'flex', alignItems:'center', justifyContent:'space-between', color: '#D1D5DB'}}>
                <Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
                  {!!node.children && node.icon}
                  <Typography sx={styleTypography}>
                    {node.label}
                  </Typography>
                </Box>
                <Box sx={{display:'flex', gap:'8px'}}>
                  {!!node.children ? 
                    <>
                      <Typography sx={styleTypography}>
                        {node.percentFinish}
                      </Typography>
                      {expanded.find(item => item == node.id) ?
                        <KeyboardArrowUp sx={{color: '#D1D5DB'}}/>
                      :
                        <KeyboardArrowDown sx={{color: '#D1D5DB'}}/>
                      }
                    </>
                    : 
                      <>
                        {/* {selected == node.id && node.icon} */}
                        {node.icon}
                      </>
                  }
                </Box>
              </Box>
              {/* <Divider sx={{backgroundColor: '#374151'}}/> */}
            </>
          }
          onClick={()=> {!node.children && node.onClick()}}
        >
          {Array.isArray(node.children)
            ? renderTree(node.children)
            : null
          }
          
        </TreeItem>
      )
    });
  };

  const DrawerBodyTree = () => {
    return (
      <Box sx={{ display:'flex', width:'100%' }}>
        {
          open ?
            <Box sx={{width:'100%', maxHeight:'100%', display:'flex', flexDirection:'column', py:'8px', pr:'8px' }}>
              <TreeView
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
              >
                {renderTree(listDrawer)}
              </TreeView>
            </Box>
          :
            <Box sx={{width:'100%', maxHeight:'100%', display:'flex', flexDirection:'column', py:'8px', px:'6px'}}>
              {listDrawer.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton sx={{ width:'100%', height:'43px', display:'flex', alignItems:'center', justifyContent:'center'}} onClick={handleDrawerOpen}>
                    <ListItemIcon sx={{ width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                      {/* {index % 2 === 0 ? <GroupsIcon sx={{color: '#f3f4f6'}}/> : <MailIcon sx={{color: '#f3f4f6'}}/>} */}
                      {item.icon}
                    </ListItemIcon> 
                  </ListItemButton>
                  {/* <Divider sx={{backgroundColor: '#374151'}}/> */}
                </ListItem>
              ))}
            </Box>
        }
      </Box>
    )
  }

  const HeaderBody = () => {
    return (
      <Box sx={{width:'100%', height:'72px', display:'flex', alignItems:'center'}}>
        <Button 
          sx={{textTransform:'none', ml:'150px', width:'250px', height:'32px'}}
          startIcon={<KeyboardBackspace sx={{color:'#1A417D'}}/>}
          onClick={()=>{navigate('/diagnostic/menu')}}
        >
          <Typography sx={{fontSize:'14px', fontWeight:600, lineHeight:'100%', color:'#1A417D', fontFamily:'Inter'}}>
            {/* Responder depois */}
            Retornar ao menu principal
          </Typography>
        </Button>
      </Box>
    )
  }

  useEffect(autoSelectItemOnLoad, [location.pathname]);

  return (
    <Box sx={{ display: 'flex', width:'100%', height:'100%'}}>
      <Drawer variant="permanent" open={open} PaperProps={{ sx: {backgroundColor: "#1F2937"}}}>

        <DrawerHeader/>

        <Divider sx={{backgroundColor: '#374151'}}/>

        <DrawerBodyTree/>

      </Drawer>
      <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
        {disableHeader && <HeaderBody/>}
        {children}
      </Box>
    </Box>
  );
}