import { Box, useTheme } from "@mui/material";

export function PageContainer({ children }) {
	const theme = useTheme();

	return (
		<Box
			sx={{
				minHeight: "100vh",
				display: "flex",
				flex: 1,
				flexDirection: "column",
				backgroundColor: theme.palette.background.default,
			}}
		>
			{children}
		</Box>
	);
}
