import React from "react";

import { LayoutAdminDefault } from "../../../../layouts";
import { Typography } from "@mui/material";

export const HomeAdmin = () => {

  return (
    <LayoutAdminDefault
      title="Home"
    >
      <Typography sx={{m:1}}>
        BEM VINDO AO SISTEMA
      </Typography>
    </LayoutAdminDefault>
  )
}