import React from "react"
import { Controller } from "react-hook-form"
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material"

import { TypographyCustom } from '../TypographyCustom'
import { ErrorMessageForm } from "../ErrorMessageForm"

export function TextFieldCustomRadio({
	name,
	control,
	label = "",
	disabled = false,
	required = false,
	options = [{
		label: 'label',
		value: 'value'
	}]
}) {

	const Container = ({children}) => {
		return (
			<Box sx={{display:'flex', flexDirection:'column', gap:'4px'}}>
				{label !== '' &&
					<TypographyCustom
						text={`${label}${required ? "*" : ""}`}
						type='label-s'
					/>
				}
				{children}
			</Box>
		)
	}

	const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#374151",
    fontFamily: "Inter",
  }

	return (
		<Container>
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value }, fieldState: { error } }) => {
					return (
						<Box>
							<RadioGroup
								defaultValue={options[0].value}
								value={value}
								onChange={onChange}
							>
								{options.map((item)=>(
									<FormControlLabel
										value={item.value}
										label={item.label}
										control={<Radio />}
										disabled={disabled}
										componentsProps={{
											typography: styleTypography
										}}
									/>
								))}
							</RadioGroup>
							{!!error && <ErrorMessageForm errorField={error}/>}
						</Box>
					)
				}}
				rules={{
					required: required,
				}}
			/>
		</Container>
	)
}
