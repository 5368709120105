import { WalletIcon } from "@heroicons/react/20/solid";
import { Box, Typography } from "@mui/material";
import { ButtonCustomBack, ButtonCustomSubmit } from "../../../../../../../components";
import { useConsultationPageContext } from "../../../../contexts";

export function TreatmentsPaymentPending({ pendentPrice }) {
	const { handleConfirmPayment } = useConsultationPageContext()

	return (
		<Box
			sx={{
				padding: "18px",
				gap: "8px",
				display: "flex",
				flexDirection: "column",
				backgroundColor: "#FFF8F1",
				border: "1px solid #FFDBB4",
				borderRadius: "4px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					width: "38px",
					height: "38px",
					backgroundColor: "#FFDBB4",
					borderRadius: "50%",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<WalletIcon
					style={{ width: "24px", height: "24px", color: "#0F172A" }}
				/>
			</Box>
			<Typography variant="body-b-v2" sx={{ color: "#09234A" }}>
				Confirmação mediante Pagamento do valor de R$ 2.050
			</Typography>
			<Typography variant="small-m" sx={{ color: "#374151" }}>
				Parabéns, Giovana! O plano de tratamento personalizado para a
				Lívia está pronto para ser finalizado. Lembre a Lívia de que o
				tratamento será oficialmente confirmado após a realização do
				pagamento
			</Typography>
			<Box sx={{display:'flex', gap:'8px', alignItems:'center'}}>
				<Box>
					<ButtonCustomSubmit
						text={'Confirmar Pagamento'}
						onClick={handleConfirmPayment}
						sx={{ paddingY: "12px", paddingX: "22px" }}
						haveIcon={false}
					/>
				</Box>
			</Box>
		</Box>
	);
}
