import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/solid"
import { Button, Typography, IconButton } from "@mui/material";

export function PageHeaderButtonBack({ label = "", onClick = undefined }) {
	const navigate = useNavigate()

	const handleOnClickDefault = () => {
		navigate(-1);
	}

	return (
		label !== '' ? 
			<Button
				onClick={onClick ?? handleOnClickDefault}
				sx={{
					textTransform: "none",
					width: "155px",
					alignItems: "center",
				}}
				startIcon={
					<ArrowLeftIcon
						style={{ width: "20px", height: "20px" }}
						color={"#1A417D"}
					/>
				}
			>
				<Typography variant="small-r-v2">{label}</Typography>
			</Button>
		:
			<IconButton onClick={onClick ?? handleOnClickDefault}>
				<ArrowLeftIcon
					style={{ width: "20px", height: "20px" }}
					color={"#1A417D"}
				/>
			</IconButton>
	);
}
