import { Error } from "@mui/icons-material"
import { Stack } from "@mui/material"
import { TypographyCustom } from "../TypographyCustom"

export function ErrorMessageForm({ errorField }) {
	return (
		errorField && (
			<Stack spacing="5px" direction="row" alignItems="center">
				<Error sx={{ color: "#FF6961" }} fontSize="small"/>
				<TypographyCustom
					text={errorField.message}
					type="small-b"
					colorText={"#FF6961"}
				/>
			</Stack>
		)
	);
}
