import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import {
	CalendarDaysIcon,
	CurrencyDollarIcon,
	IdentificationIcon,
	MegaphoneIcon,
	Squares2X2Icon,
	UserGroupIcon,
	Cog8ToothIcon
} from "@heroicons/react/24/solid"

import { DrawerMenuBusinessContextProvider, useDrawerMenuBusinessContext } from "../../contexts"
import { DrawerNavigation } from "../../../../../components/DrawerNavigation"

export function DrawerMenuBusiness() {
	const BASE_URL = "/business"

	const DrawerNav = () => {
		const { isOpenDrawerMenu } = useDrawerMenuBusinessContext()

		return (
			isOpenDrawerMenu && 
			<DrawerNavigation.Container >
				<DrawerNavigation.Header title="Gestão do Negócio" />
				<DrawerNavigation.Body>
					<DrawerNavigation.ItemTree>
						{/* <DrawerNavigation.Item
							nodeId="1"
							label="Tela inicial"
							icon={Squares2X2Icon}
							path={`${BASE_URL}/home`}
						/> */}
						<DrawerNavigation.Item
							nodeId="1"
							label="Agenda"
							icon={CalendarDaysIcon}
							path={`${BASE_URL}/schedule`}
						/>
						<DrawerNavigation.Item
							nodeId="2"
							label="Pacientes"
							icon={IdentificationIcon}
							path={`${BASE_URL}/patients`}
						/>
						<DrawerNavigation.Item
							nodeId="3"
							label="Marketing"
							icon={MegaphoneIcon}
						>
							<DrawerNavigation.Item
								nodeId="3.1"
								label="Gestão de Leads"
								// path={`${BASE_URL}/`}
							/>
							<DrawerNavigation.Item
								nodeId="3.2"
								label="Gestão de Campanhas"
								// path={`${BASE_URL}/`}
							/>
							<DrawerNavigation.Item
								nodeId="3.3"
								label="Gestão de Promoções"
								// path={`${BASE_URL}/`}
							/>
							<DrawerNavigation.Item
								nodeId="3.4"
								label="Portfólio de Vendas"
								// path={`${BASE_URL}/`}
							/>
						</DrawerNavigation.Item>
						<DrawerNavigation.Item
							nodeId="4"
							label="Finanças"
							icon={CurrencyDollarIcon}
						>
							<DrawerNavigation.Item
								nodeId="4.1"
								label="Compras & Estoque"
							>
								<DrawerNavigation.Item
									nodeId="4.1.1"
									label="Gestão de Compras"
									// path={`${BASE_URL}/`}
								/>
								<DrawerNavigation.Item
									nodeId="4.1.2"
									label="Gestão de Fornecedores"
									// path={`${BASE_URL}/`}
								/>
								<DrawerNavigation.Item
									nodeId="4.1.3"
									label="Gestão de Estoque"
									// path={`${BASE_URL}/`}
								/>
								<DrawerNavigation.Item
									nodeId="4.1.4"
									label="Gestão de Equipamentos"
									// path={`${BASE_URL}/`}
								/>
							</DrawerNavigation.Item>
							<DrawerNavigation.Item
								nodeId="4.2"
								label="Gestão de Gastos"
							>
								<DrawerNavigation.Item
									nodeId="4.2.1"
									label="Gestão de Gastos Gerais"
									// path={`${BASE_URL}/`}
								/>
								<DrawerNavigation.Item
									nodeId="4.2.2"
									label="Gestão de Empréstimos"
									// path={`${BASE_URL}/`}
								/>
								<DrawerNavigation.Item
									nodeId="4.2.3"
									label="Gestão de Contas a Pagar"
									// path={`${BASE_URL}/`}
								/>
							</DrawerNavigation.Item>
							<DrawerNavigation.Item
								nodeId="4.3"
								label="Gestão de Vendas"
							>
								<DrawerNavigation.Item
									nodeId="4.3.1"
									label="Gestão de Contas a Receber"
									// path={`${BASE_URL}/`}
								/>
							</DrawerNavigation.Item>
							<DrawerNavigation.Item
								nodeId="4.4"
								label="Contas Bancárias & Maquininhas"
								// path={`${BASE_URL}/`}
							/>
						</DrawerNavigation.Item>
						<DrawerNavigation.Item
							nodeId="5"
							label="Pessoas"
							icon={UserGroupIcon}
						>
							<DrawerNavigation.Item
								nodeId="5.1"
								label="Gestão de Funcionários"
								// path={`${BASE_URL}/`}
							/>
						</DrawerNavigation.Item>
						<DrawerNavigation.Item
							nodeId="6"
							label="Configurações"
							icon={Cog8ToothIcon}
							// path={`${BASE_URL}/`}
						>
							<DrawerNavigation.Item
								nodeId="6.1"
								label="Gestão de Usuários"
								// path={`${BASE_URL}/`}
							/>
							<DrawerNavigation.Item
								nodeId="6.2"
								label="Grupos de Permissões"
								// path={`${BASE_URL}/`}
							/>
						</DrawerNavigation.Item>
					</DrawerNavigation.ItemTree>
				</DrawerNavigation.Body>
			</DrawerNavigation.Container>
		)
	}

	return (
		<Box sx={{ display: "flex", flex: 1 }}>
			<DrawerMenuBusinessContextProvider>
				<DrawerNav />
				<Outlet/>
			</DrawerMenuBusinessContextProvider>
		</Box>
	)
}
