import React, { useState, useEffect } from "react";

import { LayoutAdminDefault } from "../../../../../layouts";
import { Box, Button, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import {TransferList} from "../../../../../components";
import { apiRoles } from "../../../../../services/apiInterface/admin/roles";
import { removeDuplicate } from "../../../../../utils/tools";

export function AttachRolesUser() {

  const params = useParams()

  const navigate = useNavigate();

  const [ allRoles, setAllRoles ] = useState([]);
  const [ selectedRoles, setSelectedRoles ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  const handleBack = () => {
    navigate('/usuarios');
  }

  const sendForm = async () => {
    try {
      const userRoles = {
        userId: params.id,
        roles: selectedRoles
      }
      const {ok, msg} = await apiRoles.updateRolesUser(userRoles);
      navigate('/usuarios', {state: {type:ok?'success':'error', msg: msg}});

    } catch (error) {
      navigate('/usuarios', {state: {type:'error', msg: error.message}});
    }
  }

  const handleAttach = () => {
    sendForm();
  }

  useEffect(()=>{
    (async () => {
      try {
        setLoading(true);
        const {data: {ok: okUserRoles, msg: msgUserRoles, userRoles}} = await apiRoles.getAllByUser(params.id);
        
        if(okUserRoles){
          setSelectedRoles(userRoles);
          const {data: {ok: okRoles, msg: msgRoles, roles}} = await apiRoles.getAllForAttach();
          
          if(okRoles){
            setAllRoles(removeDuplicate(userRoles, roles));
          }else{
            alert(msgRoles)
          }
        }else{
          alert(msgUserRoles)
        }
  
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    })()
  },[]);

  return (
    <LayoutAdminDefault
      title="Vincular grupo de acesso no usuário"
    >
      {!loading ?
        <>
          <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2, height:'90%'}}>
            <TransferList
              listLeft={allRoles}
              setListLeft={setAllRoles}
              listRight={selectedRoles}
              setListRight={setSelectedRoles}
            />
          </Box>

          <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2, height:'5%'}}>
            <Button
              variant="contained" 
              onClick={handleAttach}
              sx={{width:"15vw"}}
              color="primary"
            >
              <Typography sx={{fontSize:"18px"}}>Vincular</Typography>
            </Button>

            <Button 
              variant="contained" 
              onClick={handleBack}
              sx={{width:"15vw"}}
              color="error"
            >
              <Typography sx={{fontSize:"18px"}}>Voltar</Typography>
            </Button>
          </Box>
        </>
      :
        <Typography>
          Carregando...
        </Typography>
      }
      
      
    </LayoutAdminDefault>
  )
}