import React from 'react'
import { Box } from "@mui/material"

export const BoxCustomLarge = ({ key=0, children,}) => {
  return (
    <Box
      key={key}
      sx={{
          border: '1px solid transparent',
          backgroundColor:'#FFFFFF', 
          width:'100%', 
          height:'104px', 
          borderRadius:'8px',
          display:'flex',
          alignItems:'center'
      }}
    >
      {children}
    </Box>
  )
}