import React, { createContext, useCallback, useContext, useState, useEffect } from "react";

import { useAuthContext } from "./AuthContext";
import { usePermissionsAdminOptions } from "../hooks";

import SettingsIcon from '@mui/icons-material/Settings';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const DrawerAdminContext = createContext();

export const useDrawerAdminContext = ()=>{
  return useContext(DrawerAdminContext);
}

export function AppDrawerAdminProvider({children}) {

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [drawerOptions, setDrawerOptions] = useState([]);
  const [drawerUserOptions, setDrawerUserOptions] = useState([]);

  // const { authenticated, userLogged } = useAuthContext();
  const { userLogged } = useAuthContext();

  const { gatePermissionsOptions } = usePermissionsAdminOptions();

  // useEffect(()=>{
  //   if(!authenticated){
  //     setIsDrawerOpen(false);
  //   }else{
  //     setIsDrawerOpen(true);
  //   }
  // },[authenticated]);

  useEffect(()=>{

    const options = gatePermissionsOptions();

    setDrawerOptions(options);
    setDrawerUserOptions([
      {
        label:"Perfil",
        icon: ()=>(<PersonOutlineIcon/>),
        path:"/perfil"
      },
      {
        label:"Preferências",
        icon: ()=>(<SettingsIcon/>),
        path:"/preferencias"
      }
    ]);
  },[setDrawerOptions, setDrawerUserOptions, userLogged])
  
  const toggleDrawerOpen = useCallback(()=>{
    setIsDrawerOpen(oldValue => !oldValue);
  },[]);

  const handleDrawerOptions = useCallback((newDrawerOptions)=>{
    setDrawerOptions(newDrawerOptions);
  },[]);

  const handleDrawerUserOptions = useCallback((newDrawerUserOptions)=>{
    setDrawerUserOptions(newDrawerUserOptions);
  },[]);

  return (
    <DrawerAdminContext.Provider value={{toggleDrawerOpen, isDrawerOpen, drawerOptions, setDrawerOptions: handleDrawerOptions, drawerUserOptions, setDrawerUserOptions: handleDrawerUserOptions}}>
      {children}
    </DrawerAdminContext.Provider>
  )
}