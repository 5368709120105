import React from "react"

import { CompanyDebtContextProvider } from "./context"
import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { TableCompanyDebt, TableFilterCompanyDebt } from "./components"

export function CompanyDebt() {

  return (
    <ContainerLayout
      title="Informações Iniciais"
      subtitle="Dívidas da Empresa"
      description="Cadastre os equipamentos da sua clínica de estética. Dessa forma, você terá um controle completo dos seus recursos e poderá gerenciá-los de maneira eficiente."
      sx={{
        width:'800px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <CompanyDebtContextProvider>
        <TableFilterCompanyDebt/>
        <TableCompanyDebt/>
      </CompanyDebtContextProvider>
    </ContainerLayout>
  )
}