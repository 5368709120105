import React from 'react';
import { Typography } from "@mui/material";

// /**
//  * 
//  * @param {"title-h1" | "title-h2" | "title-h3" | "title-h4" | "title-h4-v2" | "title-h5" | 
//  *         "subtitle-b" | "subtitle-m" | "subtitle-r" |
//  *         "body-b" | "body-b-v2" | "body-m" | "body-r" | "body-l" |
//  *         "small-b" | "small-m" | "small-r" | "small-l" |
//  *         "big-b" | "big-m" | "big-r" | "big-l" |
//  *         "button-l" | "button-m" | "button-s" |
//  *         "label-l" | "label-m" | "label-m-v2"| "label-s" |
//  *         "inputText-r-l" | "inputText-r-m" | "inputText-r-s" | "inputText-m-l" | "inputText-m-m" | "inputText-m-s"} type
//  * @param { string } text
//  * @param { "right" | "left" | "center" } textAlign
//  * @param { string } colorText
//  * @param { object } sx
//  * @returns 
//  */

export const TypographyCustom = ({ type='title-h1', text='', textAlign = 'left', colorText='#4B5563', sx={} }) => {

  let component = <></>;

  switch (type) {
    case 'title-h1':
      component = <Typography sx={{ fontSize:'58px', lineHeight:'120%', color:colorText, fontFamily:'InterBold', letterSpacing: '-2%', textAlign: textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'title-h2':
      component = <Typography sx={{ fontSize:'50px', lineHeight:'120%', color:colorText, fontFamily:'InterBold', letterSpacing: '-2%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'title-h3':
      component = <Typography sx={{ fontSize:'38px', lineHeight:'120%', color:colorText, fontFamily:'InterBold', letterSpacing: '-2%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'title-h4':
      component = <Typography sx={{ fontSize:'25px', lineHeight:'120%', color:colorText, fontFamily:'InterBold', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'title-h4-v2':
      component = <Typography sx={{ fontSize:'26px', fontWeight:500, lineHeight:'120%', color:colorText, fontFamily:'Inter', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'title-h5':
      component = <Typography sx={{ fontSize:'25px', lineHeight:'120%', color:colorText, fontFamily:'InterMedium', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'subtitle-b':
      component = <Typography sx={{ fontSize:'20px', lineHeight:'130%', color:colorText, fontFamily:'InterBold', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'subtitle-b-v2':
      component = <Typography sx={{ fontSize:'22px', fontWeight:500, lineHeight:'150%', color:colorText, fontFamily:'Inter', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'subtitle-m':
      component = <Typography sx={{ fontSize:'20px', lineHeight:'130%', color:colorText, fontFamily:'InterMedium', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'subtitle-r':
      component = <Typography sx={{ fontSize:'20px', lineHeight:'130%', color:colorText, fontFamily:'InterRegular', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'body-b':
      component = <Typography sx={{ fontSize:'16px', lineHeight:'140%', color:colorText, fontFamily:'InterBold', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'body-b-v2':
      component = <Typography sx={{ fontSize:'16px', fontWeight:700, lineHeight:'140%', color:colorText, fontFamily:'Inter', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'body-m':
      component = <Typography sx={{ fontSize:'14px', fontWeight:500, lineHeight:'140%', color:colorText, fontFamily:'Inter', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'body-r':
      component = <Typography sx={{ fontSize:'16px', lineHeight:'140%', color:colorText, fontFamily:'InterRegular', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'body-r-v2':
      component = <Typography sx={{ fontSize:'14px', fontWeight:700, lineHeight:'140%', color:colorText, fontFamily:'Inter', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'body-l':
      component = <Typography sx={{ fontSize:'16px', lineHeight:'140%', color:colorText, fontFamily:'InterLight', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'small-b':
      component = <Typography sx={{ fontSize:'11px', lineHeight:'150%', color:colorText, fontFamily:'InterBold', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'small-m':
      component = <Typography sx={{ fontSize:'11px', lineHeight:'150%', color:colorText, fontFamily:'InterMedium', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'small-r':
      component = <Typography sx={{ fontSize:'14px', fontWeight:400, lineHeight:'150%', color:colorText, fontFamily:'Inter', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'small-r-v2':
      component = <Typography sx={{ fontSize:'14px', fontWeight:600, lineHeight:'150%', color:colorText, fontFamily:'Inter', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'small-l':
      component = <Typography sx={{ fontSize:'11px', lineHeight:'150%', color:colorText, fontFamily:'InterLight', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'big-b':
      component = <Typography sx={{ fontSize:'16px', lineHeight:'150%', color:colorText, fontFamily:'InterBold', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'big-b-v2':
      component = <Typography sx={{ fontSize:'20px', fontWeight:700, lineHeight:'150%', color:colorText, fontFamily:'Inter', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'big-m':
      component = <Typography sx={{ fontSize:'16px', lineHeight:'150%', color:colorText, fontFamily:'InterMedium', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'big-r':
      component = <Typography sx={{ fontSize:'16px', lineHeight:'150%', color:colorText, fontFamily:'InterRegular', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'big-l':
      component = <Typography sx={{ fontSize:'16px', lineHeight:'150%', color:colorText, fontFamily:'InterLight', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'button-l':
      component = <Typography sx={{ fontSize:'16px', lineHeight:'100%', color:colorText, fontFamily:'InterSemiBold', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'button-m':
      component = <Typography sx={{ fontSize:'12px', lineHeight:'100%', color:colorText, fontFamily:'InterSemiBold', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'button-m-v3':
      component = <Typography sx={{ fontSize:'12px', fontWeight:600, lineHeight:'100%', color:colorText, fontFamily:'Inter', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'button-m-v2':
      component = <Typography sx={{ fontSize:'16px', fontWeight:600, lineHeight:'150%', color:colorText, fontFamily:'Inter', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'button-s':
      component = <Typography sx={{ fontSize:'11px', lineHeight:'100%', color:colorText, fontFamily:'InterSemiBold', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'button-s-v2':
      component = <Typography sx={{ fontSize:'14px', fontWeight:600, lineHeight:'150%', color:colorText, fontFamily:'Inter', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'label-l':
      component = <Typography sx={{ fontSize:'16px', lineHeight:'100%', color:colorText, fontFamily:'InterSemiBold', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'label-m':
      component = <Typography sx={{ fontSize:'12px', lineHeight:'100%', color:colorText, fontFamily:'InterSemiBold', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'label-m-v2':
      component = <Typography sx={{ fontSize:'12px', fontWeight:600, lineHeight:'100%', color:colorText, fontFamily:'Inter', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'label-s':
      component = <Typography sx={{ fontSize:'11px', lineHeight:'100%', color:colorText, fontFamily:'InterSemiBold', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'inputText-r-l':
      component = <Typography sx={{ fontSize:'16px', lineHeight:'100%', color:colorText, fontFamily:'InterRegular', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'inputText-r-m':
      component = <Typography sx={{ fontSize:'12px', lineHeight:'100%', color:colorText, fontFamily:'InterRegular', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'inputText-r-s':
      component = <Typography sx={{ fontSize:'11px', lineHeight:'100%', color:colorText, fontFamily:'InterRegular', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'inputText-m-l':
      component = <Typography sx={{ fontSize:'16px', lineHeight:'100%', color:colorText, fontFamily:'InterMedium', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'inputText-m-m':
      component = <Typography sx={{ fontSize:'12px', lineHeight:'100%', color:colorText, fontFamily:'InterMedium', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
    case 'inputText-m-s':
      component = <Typography sx={{ fontSize:'11px', lineHeight:'100%', color:colorText, fontFamily:'InterMedium', letterSpacing: '0%', textAlign:textAlign, ...sx}}>
                    {text}
                  </Typography>
      break;
  
    default:
      component = <Typography>
                    {text}
                  </Typography>
      break;
  }

  return component;
}


