import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

export const useErrorHandler = () => {

  const navigate = useNavigate();

  const handleError = (error) => {
    if(error instanceof AxiosError){
      // console.error(error)
      if(error.code == 'ERR_CANCELED'){
        return
      }
      alert(error?.message)
      navigate('/');
    }else if(error instanceof Error){
      alert(error?.message);
    }else{
      console.error(error);
    }
  }

  return {
    handleError
  }
}