import { api } from "../../../../axios_config"

const getAllByServicesId = async (servicesId) => {
  const resp = await api.get(`/diagnostic/servicos_anmenese/${servicesId}`)
  return resp.data
}

const create = async (obj) => {
  const resp = await api.post(`/diagnostic/servicos_anmenese`, obj)
  return resp.data
}

const del = async (servicesId) => {
  const resp = await api.delete(`/diagnostic/servicos_anmenese/${servicesId}`)
  return resp.data
}

export const apiSalesPortfolioAnamnesis = {
  getAllByServicesId,
  create,
  del
}