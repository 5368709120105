
export const philosophyDescription = `Somos apaixonados por empreendedorismo e 
desafios. Acreditamos que aplicar as ferramentas de gestão e administração de 
forma tecnológica e eficiente são as chaves para que os empreendimentos prosperem.`

export const visionDescription = `Cada trabalho realizado com excelência, dedicação e carinho nos deixa mais perto do
nosso objetivo: ser referência em finanças gastronômicas em Curitiba até Dezembro de 2020.
Sucesso esse que será alcançado graças aos resultados positivos e a satisfação dos nossos clientes.`

export const missionDescription = `Os ingredientes da receita de sucesso são as análises financeiras, treinamentos,
controles e ferramentas de gestão que fornecemos para que você possa administrar seu
negócio com tranquilidade e tempo para criar obras de artes gastronômicas.`

export const valuesDescription = `A paixão pelo setor gastronômico fomentou a busca e desenvolvimento de ferramentas de
gestão que facilitem a vida de nossos clientes e lhes deem tempo para criar obras de artes
culinárias. Somos transparentes, objetivos e sinceros. Características primordiais na construção da
confiança mútua e que geram prazer no trabalho em equipe.`

export const firstPartAboutChefDescription = `Sou formado em administração de empresa pela FAPPES, e sou especialista em Gestão
de Negócios pela Fundação Dom Cabral. Trabalho no setor alimentício desde muito cedo.
Quando criança comecei a plantar verduras e legumes para vender para os comerciantes e
moradores locais de Moema, Minas Gerais. Aos 19 anos me mudei para Belo Horizonte para
trabalhar como conferente no Ceasa. Com 21 anos fui para São Paulo começar o meu curso de graduação.`

export const secondPartAboutChefDescription = `Atuando com finanças diretamente com o cliente descobri minha paixão pelo mundo dos negócios.
Após estágios e trainees em empresas como Plano 1 e Fernandez Mera, tive a oportunidade de
trabalhar com planejamento estratégico na Coca-Cola. Depois de 9 anos imersos no setor privado,
decidi que era momento de abrir o meu próprio negócio, ou melhor, era o momento de ajudar as
pessoas a concretizarem seus sonhos. Assim surgiu o Chef Financeiro, uma metodologia exclusiva e única de ser.`

export const thirdPartAboutChefDescription = `Quando decidi aplicá-la à área gastronômica, achei necessário conhecer todos os processos para
oferecer um trabalho com excelência. Fiz curso de Chef de Cozinha, Barman, Sommelier, Etiqueta,
Gerente A&B e claro, não podia faltar, Garçom e Cumin. Onde mais você encontra um especialista 
em finanças que pode te servir um prato?`

export const  firstPartWhatWeDoDescription = `Agregamos valor ao seu negócio te ajudando a fazer o que gosta sem preocupações.
Inicie pela parte que mais entende: a arte do seu negócio, e deixe o resto com a gente.`

export const secondPartWhatWeDoDescription = `Nossos resultados aparecem a curto e médio prazo e são voltados para quem está iniciando 
seu negócio, para quem é empresário e precisa de uma luz no controle e organização de seu empreendimento ou quem está vendo seu negócio 
tão promissor desandar e precisa de ajuda para concretizar seu sonho, bem como àqueles empresários que passam por uma ampliação desordenada 
de seus negócios e sofrem com as dores do crescimento.`

export const thirdPartWhatWeDoDescription = `Nós te ajudamos a organizar, estruturar e vencer os desafios de sua atividade empresarial através 
de uma metodologia que leva em conta os cinco pilares imprescindíveis para um negócio crescer: planejamento, marketing, operação, pessoas e finanças. 
Estaremos ao seu lado até que você domine a arte da gestão.`

export const analysisDescription = `Primeiro de tudo, queremos conhecer você e seu negócio, o que o motivou a abrir, como foi
esse processo, como é o dia a dia do seu negócio. Entendendo os processos diários do seu
espaço gastronômico e coletando dados necessários, geramos uma análise histórica e a partir
dela traçamos a estratégia necessária para obter o melhor resultado possível.`

export const planningDescription = `Traçamos uma linha de planejamento do abstrato ao concreto, esmiuçando cada detalhe do negócio,
através das análises dos dados coletados e os passamos por uma peneira visando aprimorar os processos,
caso isso seja necessário, e determinamos as ferramentas mais apropriadas para a gestão do seu negócio.`

export const actionPlansDescription = `Hora de colocar a mão na massa. Num primeiro momento você vai focar nas suas habilidades e no coração do seu negócio, 
enquanto isso, nós cuidaremos da parte técnica e colocamos tudo nos eixos. Desde a criação de estudos de custos e despesas, impostos e investimentos, separação 
dos gastos pessoais e empresa, negociação com credores e bancos, como e onde buscar novas fontes de receita, entre outros por  menores que o ajudará a lucrar mais.`

export const managementDescription = `Em um curto e médio prazo os resultados estarão palpáveis, e então, chegará o momento de analisarmos o cenário para que possamos 
tomar as decisões acertadas a fim de obter o melhor lucro possível. Isso será um novo hábito na sua rotina. Estudos científicos comprovam que levamos entre 21 e 66 dias 
para agregar um novo hábito. O seu virá através das reuniões mensais e dos painéis de controle fáceis de entender e mais ainda de mexer. Estamos sempre à sua disposição 
para ensinar e dar suporte no que você precisar.`

export const howWeDoDescription = `Nossa metodologia é exclusiva e geram resultados de curto e médio prazo, comprovadas na prática com nossos clientes. Escolha ideal para 
quem precisa organizar a gestão, finanças e administração de seu negócio agora mesmo. Nossa metodologia é exclusiva e segue as seguintes etapas:`

export const coffeeDescription = `Entendemos que nada substitui uma boa conversa, então que tal nos chamar para um café, 
teremos muito prazer em te explicar como tudo isso funciona na prática.`