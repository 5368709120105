import { createContext, useContext, useState } from "react";

const PlanningContext = createContext();

export const usePlanningContext = () => useContext(PlanningContext);

export function PlanningContextProvider({ children }) {
	const [isPageDrawerShown, setIsPageDrawerShown] = useState(false);

	return (
		<PlanningContext.Provider
			value={{
				isPageDrawerShown,
				setIsPageDrawerShown,
			}}
		>
			{children}
		</PlanningContext.Provider>
	);
}
