import { get } from "../../../../environment";
import { api } from "../../../axios_config";

const getOne = async (id) => {
  const resp = await api.get(`/cadastros/usuarios/${id}`);
  return resp.data;
}

const getAll = async (page, search) => {
  const resp = await api.get(`/cadastros/usuarios?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&search=${search}`)
  return resp;
}

const create = async (newRegister) => {
  const resp = await api.post('/cadastros/usuarios', newRegister);
  return resp.data;
}

const update = async (id, user_data) => {
  const resp = await api.put(`/cadastros/usuarios/${id}`, user_data);
  return resp.data;
}

const updateChangePass = async (id, user_data) => {
  const resp = await api.put(`/cadastros/usuarios/atualizarSenha/${id}`, user_data);
  return resp.data;
}

const del = async (id) => {
  const resp = await api.delete(`/cadastros/usuarios/${id}`);
  return resp;
}

export const apiUsers = {
  del,
  getOne,
  getAll,
  create,
  update,
  updateChangePass,
}