import { Box, Avatar, Typography } from '@mui/material'
import { useDrawerNavigationContext } from '../../../../../components/DrawerNavigation/DrawerNavigationContainer';

export const DrawerPatientDetails = () => {
  const { open,  } = useDrawerNavigationContext()
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",
        marginY: "10px",
      }}
    >
      {open ? 
        <>
          <Avatar sx={{ width: "38px", height: "38px" }} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="body-b-v2"
              sx={{ fontWeight: "500", color: "#FFFFFF" }}
            >
              Lívia Silva
            </Typography>
            <Typography
              variant="label-m-v3"
              sx={{ fontWeight: "500", color: "#FFFFFF" }}
            >
              18/02/1996 • 27 anos
            </Typography>
          </Box>
        </>
      :
        <Avatar sx={{ width: "38px", height: "38px" }} />
      }
    </Box>
  );
};