import React from 'react'
import { Box, Tooltip, IconButton } from "@mui/material"
import { TrashIcon } from '@heroicons/react/24/solid'

export const ButtonCustomRemoveIcon = ({ text="", onClick=()=>{}}) => {
  return (
    <Box>
      <Tooltip title={text == '' && 'Remover'}>
        <IconButton
          onClick={onClick}
        >
          <TrashIcon style={{color:'#3466B2', width:'20px', height:'20px'}}/>
        </IconButton>
      </Tooltip>
    </Box>
  )
}
