import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import {
	Box,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { useState } from "react";

export function TableCustomTreatmentsReorderable({
	rows = [],
	onChange = () => {},
}) {
	const [tableRows, setTableRows] = useState(rows);

	const handleMoveUp = (index) => {
		const newTableRows = [...tableRows];
		const currentItem = newTableRows.splice(index, 1)[0];
		newTableRows.splice(index - 1, 0, currentItem);
		setTableRows(newTableRows);
		onChange(newTableRows);
	};

	const handleMoveDown = (index) => {
		const newTableRows = [...tableRows];
		const currentItem = newTableRows.splice(index, 1)[0];
		newTableRows.splice(index + 1, 0, currentItem);
		setTableRows(newTableRows);
		onChange(newTableRows);
	};

	const handleOnChangeDate = (event) => {
		const value = event.target.value;
		onChange(value);
	};

	const isFirstItemOnList = (index) => index <= 0;
	const isLastItemOnList = (index) => index >= tableRows.length - 1;

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell></TableCell>
					<TableCell>
						<Typography
							variant="body-r-v2"
							sx={{ color: "#4B5563" }}
						>
							Nome
						</Typography>
					</TableCell>
					<TableCell>
						<Typography
							variant="body-r-v2"
							sx={{ color: "#4B5563" }}
						>
							Data
						</Typography>
					</TableCell>
					<TableCell />
				</TableRow>
			</TableHead>
			<TableBody>
				{tableRows.map((row, index) => {
					return (
						<TableRow key={row.id}>
							<TableCell
								sx={{
									display: "flex",
									gap: "8px",
									alignItems: "center",
									justifyItems: "space-between",
								}}
							>
								<Box
									sx={{
										display: "flex",
										gap: "4px",
									}}
								>
									<IconButton
										sx={{ padding: "4px" }}
										onClick={() => handleMoveUp(index)}
										disabled={isFirstItemOnList(index)}
										variant="text"
									>
										<ChevronUpIcon
											style={{
												width: "16px",
												height: "16px",
												color: "#374151",
											}}
										/>
									</IconButton>
									<IconButton
										onClick={() => handleMoveDown(index)}
										variant="text"
										disabled={isLastItemOnList(index)}
									>
										<ChevronDownIcon
											style={{
												width: "16px",
												height: "16px",
												color: "#374151",
											}}
										/>
									</IconButton>
								</Box>
								<Typography
									variant="big-m"
									sx={{ color: "#374151" }}
								>
									{index + 1}
								</Typography>
							</TableCell>
							<TableCell>
								<Typography
									variant="big-m"
									sx={{ color: "#3466B2" }}
								>
									{row.nome}
								</Typography>
							</TableCell>
							<TableCell>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<TextField
										onChange={(event) =>
											handleOnChangeDate(event)
										}
										sx={{
											maxHeight: "32px",
											width: "150px",
										}}
									/>
								</Box>
							</TableCell>
							<TableCell>
								{row.picture && (
									<img
										src={row.picture}
										alt="proceedment picture"
									/>
								)}
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
}
