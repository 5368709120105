import { useState, useEffect, useCallback } from 'react'

import { useErrorHandler } from './useErrorHandler'
import { useActiveOptions } from './useActiveOptions'
import { apiClients } from '../services/apiInterface/clients'
import { useAuthContext, useDiagnosticContext, useNotifyContext } from '../contexts'

export const useActive = ({ id=''}) => {

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const { notify } = useNotifyContext()
  const { userLogged } = useAuthContext()
  const { handleError } = useErrorHandler()
  const { updateProgress } = useDiagnosticContext()

  const [ loading, setLoading ] = useState(true)
  const [ actives, setActives ] = useState([])
  const [ dataSelect, setDataSelect ] = useState({})
  const [ dataSelectView, setDataSelectView] = useState({})

  const [ filtersActive, setFiltersActive ] = useState({})
  const [ statusTable, setStatusTable ] = useState(defaultStatusTable)

  const { investmentCategory } = useActiveOptions()
  const optionsFields = {
    investmentCategory
  }

  const normalizeFormData = (data={}) => {
    const normalizeFormData = {
      id: data?.id || -1,
      url_img: data?.url_img || null,
      data_compra: !!data?.data_compra ? new Date(data?.data_compra) : '',
      nome: data?.nome || '',
      registro_anvisa: data?.registro_anvisa || '',
      aux_ci_categoria_investimento_id: data?.aux_ci_categoria_investimento_id || 0,
      valor_total: data?.valor_total || 0,
      observacao: data?.observacao || '',
    }
    return normalizeFormData
  }

  const handleDataSelectForForm = (data={}) => {
    setDataSelect(normalizeFormData(data))
  }

  const getAllByFilters = useCallback(async (pageSelected, filters={}) => {
    try {
      setLoading(true)
      const { ok, msg, registers, countTotalPages, countTotalRows } = await apiClients.apiReviewClients.apiActive.getAllByFilters(pageSelected, filters)
      if(ok){        
        setActives(registers)
        setStatusTable({
          currentPage: pageSelected,
          countTotalPages,
          countTotalRows
        })
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const getOneById = useCallback(async (id) => {
    try {
      setLoading(true)
      const { ok, msg, register } = await apiClients.apiReviewClients.apiActive.getOneById(id)
      if(ok){        
        setDataSelectView(register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  },[])

  const handleUpdate = async (id, { img, ...data }) => {
    
    try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const formData = new FormData()

      formData.append('file', img)
      formData.append('data', JSON.stringify(data))

      const {ok, msg} = await apiClients.apiReviewClients.apiActive.update(id, formData)

      if(ok){
        await getOneById(id)
        notify('success', msg)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleRemoveById = async (id) => {
    let confirmTag = false
    if (confirm('Deseja realmente excluir este registro?')) {
      try {
        confirmTag = true
        setLoading(true)
        const {ok, msg} = await apiClients.apiReviewClients.apiActive.del(id)
        if(ok){
          await getAllByFilters(statusTable.currentPage)
          updateProgress()
          notify('success', msg)
        }else{
          notify('error', msg)
        }
      } catch (error) {
        confirmTag = false
        handleError(error)
      }finally{
        setLoading(false)
      }
    }
    return confirmTag
  }

  const handleCreate = async ({ img, ...data }) => {
		try {
      setLoading(true)
      data.usuarios_id = userLogged.id

      const formData = new FormData()

      formData.append('file', img)
      formData.append('data', JSON.stringify(data))

      const {ok, msg} = await apiClients.apiReviewClients.apiActive.create(formData)
			if(ok){
        await getAllByFilters(statusTable.currentPage)
				updateProgress()
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  const handleSave = async (data) => {
		if(!!data && data?.id === -1){
      await handleCreate(data)
    }else{
      await handleUpdate(data.id, data)
    }
	}

  const handleRemoveFilter = async (filterKey) => {
    const updatedFilters = { ...filtersActive }
    delete updatedFilters[filterKey]
    setFiltersActive(updatedFilters)

    await getAllByFilters(statusTable.currentPage, updatedFilters)
  }

  useEffect(()=>{
    if(id !== ''){
      getOneById(id)
    }else{
      getAllByFilters(statusTable.currentPage)
    }
  },[getAllByFilters, getOneById])

  return {
    loading,
    actives,
    optionsFields,
    dataSelect,
    dataSelectView,
    filtersActive, 
    setFiltersActive,
    statusTable,
    getOneById,
    getAllByFilters,
    handleRemoveById,
    handleSave,
    handleDataSelectForForm,
    handleRemoveFilter,
  }
}