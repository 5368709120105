import { useState, useEffect } from "react"

import { useErrorHandler } from "./useErrorHandler"
import { apiClients } from "../services/apiInterface/clients"
import { useAuthContext, useNotifyContext } from "../contexts"

export const useAgendaAdmin = () => {

  const { handleError } = useErrorHandler()
  const { userLogged } = useAuthContext()
  const { notify } = useNotifyContext()

  const [ loading, setLoading ] = useState(true)
  const [ agendaAdmin, setAgendaAdmin ] = useState()
  
  const getAll = async () => {
    try {
      setLoading(true)
      const {ok, msg, register} = await apiClients.apiReviewClients.apiAgendaAdmin.getAll()
      if(ok){
        setAgendaAdmin(_=>register)
      }else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
  }

  const handleSave = async ({img, ...data}) => {
		try {
      setLoading(true)

      data.usuarios_id = userLogged.id

      const formData = new FormData()

      formData.append('file', img)
      formData.append('data', JSON.stringify(data))

      const {ok, msg} = await apiClients.apiReviewClients.apiAgendaAdmin.create(formData)
			if(ok){
        notify('success', msg)
			}else{
        notify('error', msg)
      }
    } catch (error) {
      handleError(error)
    }finally{
      setLoading(false)
    }
	}

  useEffect(()=>{
    (async()=>{
      await getAll()
    })()
  },[])

  return {
    loading,
    getAll,
    agendaAdmin,
    handleSave
  }
}