import React from "react"
import { Route } from "react-router-dom"

import {
	BusinessHome,
	BusinessPage,
	BusinessPatientPreConsultationClinicalHistory,
	BusinessPatientDescription,
	BusinessPatientGeneralConsultation,
	BusinessPatientPreConsultationLayout,
	BusinessPatientPreConsultationPhotos,
	BusinessPatients,
	BusinessSchedule,
	BusinessPatientComplaints,
	BusinessPatientTreatmentPlan,
	BusinessPatientTreatmentPlanProcedements,
	BusinessPatientConsultationLayout,
	TreatmentToxinaBotulinica,
	TreatmentMicroagulhamento,
	BusinessPatientTreatmentToxinaBotulinica,
	BusinessPatientTreatmentMicroagulhamento,
	Consultation,
} from "../pages"
import { DrawerMenuBusiness, DrawerMenuPatients } from "../components"
import { ConsultationPageContextProvider } from "../contexts"

export const ModuleBusinessRouters = (
  <>
    <Route path="business" element={<BusinessPage/>}>

			<Route index element={<></>} />

			<Route element={<DrawerMenuBusiness/>}>
				<Route path="home" element={<BusinessHome />} />

				<Route path="schedule" element={<BusinessSchedule />} />

				<Route path="patients">

					<Route index element={<BusinessPatients />} />

					<Route path=":patientId" element={<DrawerMenuPatients />}>
						<Route index element={<BusinessPatientDescription />}/>
							<Route
								path="pre-consultation"
							>
								<Route
									path="clinical-history"
									element={
										<BusinessPatientPreConsultationClinicalHistory />
									}
								/>
								<Route
									path="complaint"
									element={<BusinessPatientComplaints />}
								/>
								<Route
									path="photos"
									element={
										<BusinessPatientPreConsultationPhotos />
									}
								/>
							</Route>
							<Route path="consultation" element={<ConsultationPageContextProvider/>}>

								<Route index element={<Consultation />}/>
								<Route
									path="assessment"
									element={
										<BusinessPatientGeneralConsultation />
									}
								/>
								<Route path="treatment-plan">
									<Route
										index
										element={
											<BusinessPatientTreatmentPlan />
										}
									/>
									<Route
										path="procedements"
										element={
											<BusinessPatientTreatmentPlanProcedements />
										}
									/>
								</Route>

								<Route path="treatment">
									<Route
										path="toxina-botulinica"
										element={<TreatmentToxinaBotulinica />}
									/>
									<Route
										path="microagulhamento"
										element={<TreatmentMicroagulhamento />}
									/>
								</Route>

							</Route>
							{/* <Route path="treatment">
								<Route
									element={
										<BusinessPatientTreatmentToxinaBotulinica />
									}
								>
									<Route
										path="toxina-botulinica"
										element={<TreatmentToxinaBotulinica />}
									/>
								</Route>
								<Route
									element={
										<BusinessPatientTreatmentMicroagulhamento />
									}
								>
									<Route
										path="microagulhamento"
										element={<TreatmentMicroagulhamento />}
									/>
								</Route>
							</Route> */}
					</Route>
				</Route>

			</Route>
		</Route>
  </>
)