import { useEffect } from "react"
import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import {
	CalendarDaysIcon,
	CurrencyDollarIcon,
	NewspaperIcon,
	MapIcon,
	IdentificationIcon
	
} from "@heroicons/react/24/solid"

import { useDrawerMenuBusinessContext } from "../../contexts"
import { DrawerPatientDetails } from "../DrawerPatientDetails"
import { DrawerNavigation } from "../../../../../components/DrawerNavigation"

export function DrawerMenuPatients() {
	const BASE_URL = "/business/patients"

	const { handleOpenDrawerMenu, handleCloseDrawerMenu } = useDrawerMenuBusinessContext()

	useEffect(()=>{
		handleCloseDrawerMenu()
		return ()=>{
			handleOpenDrawerMenu()
		}
	},[])

	const DrawerNav = () => {
		return (
			<DrawerNavigation.Container>
				<DrawerNavigation.Header title="Gestão do Negócio" />
				<DrawerNavigation.Body>
					<DrawerPatientDetails />
					<DrawerNavigation.ItemTree>
						<DrawerNavigation.Item
							nodeId="1"
							label="Perfil"
							icon={IdentificationIcon}
							path={`${BASE_URL}/1`}
						/>
						<DrawerNavigation.Item
							nodeId="2"
							label="Agenda"
							icon={CalendarDaysIcon}
							// path={`${BASE_URL}/`}
						/>
						<DrawerNavigation.Item
							nodeId="3"
							label="Consultas"
							icon={NewspaperIcon}
							path={`consultation`}
						/>
						<DrawerNavigation.Item
							nodeId="4"
							label="Tratamentos"
							icon={MapIcon}
							// path={`${BASE_URL}/`}
						/>
						<DrawerNavigation.Item
							nodeId="5"
							label="Financeiro"
							icon={CurrencyDollarIcon}
							// path={`${BASE_URL}/`}
						/>
					</DrawerNavigation.ItemTree>
				</DrawerNavigation.Body>
			</DrawerNavigation.Container>
		)
	}

	return (
		<Box sx={{ display: "flex", flex: 1 }}>
			<DrawerNav />
			<Outlet/>
		</Box>
	)
}
