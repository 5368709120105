import React from 'react';
import { Alert, Collapse } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const AlertCustom = ({open = true, setOpen, formMessage}) => {
  return (
    <Collapse in={open}>
      <Alert
        action={
          !!setOpen &&  <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                if(!!setOpen){
                  setOpen(false);
                }
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
        }
        variant='standard' 
        severity={formMessage.type}
        sx={{marginBottom:1}}
      >
        {formMessage.msg}
      </Alert>
    </Collapse>
  );
}