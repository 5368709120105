import React from "react"

import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { TableEmployee, TableFilterEmployee } from "./components"

export function Employees() {
  return (
    <ContainerLayout
      title="Pessoas"
      subtitle="Funcionários"
      description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      sx={{
        width:'900px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <TableFilterEmployee/>
      <TableEmployee/>
    </ContainerLayout>
  )
}