import { Box, Typography } from "@mui/material";
import { assets } from "../../../../../../../assets/images_sys";
import ImageMarker from "../ImageMarker";
import { useComplaintsContext } from "../..";

export default function ImageFemaleModel() {
	const { complaintGroups } = useComplaintsContext();
	// const { Cabelo, Rosto, Olhos, Nariz, Lábios, Corpo, Pernas } =
	// complaintGroups;

	const getGroupLabel = (groupId) => {
		return complaintGroups.find(
			(complaintGroup) => complaintGroup?.id === groupId
		)?.label;
	};

	const getGroupComplaints = (groupId) => {
		return complaintGroups.find(
			(complaintGroup) => complaintGroup?.id === groupId
		)?.complaints;
	};

	const checkAllOptionsUnchecked = (complaints) => {
		return complaints.every((option) => !option.checked);
	};

	const cabeloLabel = getGroupLabel("cabelo");
	const cabeloComplaints = getGroupComplaints("cabelo");
	const cabeloAllOptionsUnchecked =
		checkAllOptionsUnchecked(cabeloComplaints);

	const rostoLabel = getGroupLabel("rosto");
	const rostoComplaints = getGroupComplaints("rosto");
	const rostoAllOptionsUnchecked = checkAllOptionsUnchecked(rostoComplaints);

	const olhosLabel = getGroupLabel("olhos");
	const olhosComplaints = getGroupComplaints("olhos");
	const olhosAllOptionsUnchecked = checkAllOptionsUnchecked(olhosComplaints);

	const narizLabel = getGroupLabel("nariz");
	const narizComplaints = getGroupComplaints("nariz");
	const narizAllOptionsUnchecked = checkAllOptionsUnchecked(narizComplaints);

	const labiosLabel = getGroupLabel("labios");
	const labiosComplaints = getGroupComplaints("labios");
	const labiosAllOptionsUnchecked =
		checkAllOptionsUnchecked(labiosComplaints);

	const corpoLabel = getGroupLabel("corpo");
	const corpoComplaints = getGroupComplaints("corpo");
	const corpoAllOptionsUnchecked = checkAllOptionsUnchecked(corpoComplaints);

	const pernasLabel = getGroupLabel("pernas");
	const pernasComplaints = getGroupComplaints("pernas");
	const pernasAllOptionsUnchecked =
		checkAllOptionsUnchecked(pernasComplaints);

	return (
		<Box style={{ display: "flex" }}>
			<Box sx={{ position: "relative" }}>
				<img src={assets.imgs.img_female_body_model} alt="Modelo" style={{width:'auto', height:'1000px'}}/>

				{!!cabeloComplaints &&
					cabeloComplaints.length > 0 &&
					!cabeloAllOptionsUnchecked && (
						<ImageMarker posX="230px" posY="70px" mirrored>
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{cabeloLabel}
							</Typography>
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{cabeloComplaints.map((option) => {
									const checked = option.checked;

									return (
										checked && (
											<>
												• {option.label}
												<br
													key={`${cabeloComplaints.id}-${option.id}`}
												/>
											</>
										)
									);
								})}
							</Typography>
						</ImageMarker>
					)}
				{(!rostoAllOptionsUnchecked ||
					!olhosAllOptionsUnchecked ||
					!narizAllOptionsUnchecked ||
					!labiosAllOptionsUnchecked) && (
					<ImageMarker posX="290px" posY="140px">
						{!!rostoComplaints &&
							rostoComplaints.length > 0 &&
							!rostoAllOptionsUnchecked && (
								<>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{rostoLabel}
									</Typography>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{rostoComplaints.map((option) => {
											const checked = option.checked;

											return (
												checked && (
													<>
														• {option.label}
														<br
															key={`${rostoComplaints.id}-${option.id}`}
														/>
													</>
												)
											);
										})}
									</Typography>
								</>
							)}
						{!!olhosComplaints &&
							olhosComplaints.length > 0 &&
							!olhosAllOptionsUnchecked && (
								<>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{olhosLabel}
									</Typography>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{olhosComplaints.map((option) => {
											const checked = option.checked;

											return (
												checked && (
													<>
														• {option.label}
														<br
															key={`${olhosComplaints.id}-${option.id}`}
														/>
													</>
												)
											);
										})}
									</Typography>
								</>
							)}
						{!!narizComplaints &&
							narizComplaints.length > 0 &&
							!narizAllOptionsUnchecked && (
								<>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{narizLabel}
									</Typography>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{narizComplaints.map((option) => {
											const checked = option.checked;

											return (
												checked && (
													<>
														• {option.label}
														<br
															key={`${narizComplaints.id}-${option.id}`}
														/>
													</>
												)
											);
										})}
									</Typography>
								</>
							)}
						{!!labiosComplaints &&
							labiosComplaints.length > 0 &&
							!labiosAllOptionsUnchecked && (
								<>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{labiosLabel}
									</Typography>
									<Typography
										variant="small-m"
										sx={{ color: "#09234A" }}
									>
										{labiosComplaints.map((option) => {
											const checked = option.checked;

											return (
												checked && (
													<>
														• {option.label}
														<br
															key={`${labiosComplaints.id}-${option.id}`}
														/>
													</>
												)
											);
										})}
									</Typography>
								</>
							)}
					</ImageMarker>
				)}
				{!!corpoComplaints &&
					corpoComplaints.length > 0 &&
					!corpoAllOptionsUnchecked && (
						<ImageMarker posX="290px" posY="390px" mirrored>
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{corpoLabel}
							</Typography>
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{corpoComplaints.map((option) => {
									const checked = option.checked;

									return (
										checked && (
											<>
												• {option.label}
												<br
													key={`${corpoComplaints.id}-${option.id}`}
												/>
											</>
										)
									);
								})}
							</Typography>
						</ImageMarker>
					)}
				{!!pernasComplaints &&
					pernasComplaints.length > 0 &&
					!pernasAllOptionsUnchecked && (
						<ImageMarker posX="340px" posY="630px">
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{pernasLabel}
							</Typography>
							<Typography
								variant="small-m"
								sx={{ color: "#09234A" }}
							>
								{pernasComplaints.map((option) => {
									const checked = option.checked;

									return (
										checked && (
											<>
												• {option.label}
												<br
													key={`${pernasComplaints.id}-${option.id}`}
												/>
											</>
										)
									);
								})}
							</Typography>
						</ImageMarker>
					)}
			</Box>
		</Box>
	);
}
