import React from 'react'
import { Box, Button } from "@mui/material"
import { CloudArrowUpIcon } from '@heroicons/react/24/outline'

import { TypographyCustom } from "../TypographyCustom"

export const ButtonCustomUpload = ({ text="", onClick=()=>{}}) => {
  return (
    <Box>
      <Button
        component="label"
        tabIndex={-1}
        onClick={onClick}
        variant="contained"
        sx={{ width:'128px', height:'36px', textTransform:'none', backgroundColor:'#3466B2', borderRadius:'8px', display:'flex', gap:'4px', alignItems:'center', justifyContent:'center'}}
      >
        <CloudArrowUpIcon style={{width: '20px', height: '20px', color:'#FFFFFF'}}/>
        <TypographyCustom
          text={text}
          type={'button-m'}
          colorText={'#FFFFFF'}
        />
      </Button>
    </Box>
  )
}