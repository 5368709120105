import React, { useState } from "react"
import { Controller } from "react-hook-form"
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid"
import { Box, IconButton, InputAdornment, OutlinedInput } from "@mui/material"

import { ErrorMessageForm } from "../ErrorMessageForm"
import { TypographyCustom } from "../TypographyCustom"

export function TextFieldCustomPass({
  label,
  required = false,
  name,
  control,
  placeholder = '',
}) {

  const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#374151",
    fontFamily: "Inter",
  }

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const styleIcons = {
    width:'16px', 
    height:'16px',
    color:'#3466B2',
  }

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap:'4px' }}>
      {label &&
        <TypographyCustom
          text={`${label}${required ? "*" : ""}`}
          type='label-s'
        />
      }
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <OutlinedInput
              placeholder={placeholder}
              variant="outlined"
              value={value}
              defaultValue={''}
              defaultChecked={false}
              onChange={onChange}
              error={!!error}
              type={showPassword ? "text" : "password"}
              sx={{
                borderRadius: '10px',
                height: '44px',
                backgroundColor:'#FFFFFF',
                border: '1px solid #6B7280',
                ...styleTypography,
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <EyeIcon style={styleIcons}/> : <EyeSlashIcon style={styleIcons} />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {!!error && <ErrorMessageForm errorField={error} />}
          </>
        )}
        rules={required && { required: "Este campo é obrigatório"}}
      />
    </Box>
  )
}
