import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";

import { LayoutAdminDefault } from "../../../../../layouts";
import { SelectCustom } from "../../../../../components";
import { formatDateTime } from "../../../../../utils/tools";
import { apiClient } from "../../../../../services/apiInterface/admin/clients";
import { normalizeCNPJNumber, normalizePhoneNumber } from "../../../../../utils/masks";

export function ViewClients() {

  const params = useParams()
  
  const navigate = useNavigate();

  const [client, setClient] = useState();
  const [genderOptions, setGenderOptions] = useState([]);
  const [localityOptions, setLocalityOptions] = useState([]);
  const [classificationOptions, setClassificationOptions] = useState([]);
  const [businessCategoryOptions, setBusinessCategoryOptions] = useState([]);
  
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    (async ()=>{
      try {
        const {ok, register} = await apiClient.getOne(params.id);
        if(ok){
          setClient(register);
        }
      } catch (error) {
        alert('Error: ' + error.message);
      }
    })()
  },[]);
  
  const { register, setValue} = useForm();

  const handleBack = () => {
    navigate('/clientes');
  }

  useEffect(()=>{
    (async ()=>{
      try {
        const optionsCadastre = await apiClient.getOptionsCadastre();
        setLocalityOptions(optionsCadastre.locality);
        setGenderOptions(optionsCadastre.gender);
        setBusinessCategoryOptions(optionsCadastre.businessCategory);
        setClassificationOptions(optionsCadastre.classification);
      } catch (error) {
        alert('Error: ' + error.message);
      }
    })()
  },[]);

  useEffect(()=>{
    if(client !== null && localityOptions.length > 0 && 
      genderOptions.length > 0 &&  businessCategoryOptions.length > 0 && 
      businessCategoryOptions.length > 0 && classificationOptions.length > 0){
      setLoading(false)
    }else{
      setLoading(true)
    }
  },[localityOptions, genderOptions, businessCategoryOptions, businessCategoryOptions, classificationOptions]);

  useEffect(()=>{
    setValue('cnpj', normalizeCNPJNumber(client?.cnpj)); 
    setValue('nome_empresa', client?.nome_empresa); 
    setValue('data_abertura', formatDateTime(client?.data_abertura,'YYYY-MM-DD'));
    setValue('nome_empresario', client?.nome_empresario); 
    setValue('data_nascimento', formatDateTime(client?.data_nascimento, 'YYYY-MM-DD'));
    setValue('email', client?.email); 
    setValue('telefone', normalizePhoneNumber(client?.telefone));  
    setValue('valor_mensalidade', client?.valor_mensalidade);
  },[client]);


  return (
    <LayoutAdminDefault
      title={'Visualizar Cliente'}
    >
      <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2}}>
      {!loading ?
        <>
          <Box sx={{display: "flex", flexDirection:"column", gap:2, m:2}}>
            <Typography>
              Status:
            </Typography>
            <SelectCustom 
              name="status" 
              options={[{label:'Ativo', value:'1'}, {label:'Inativo', value:'0'}]}
              register={register}
              defaultValue={client?.status}
            />

            <TextField
              disabled
              type='text'
              label={'CNPJ'}
              InputLabelProps={{
                shrink: true,
              }}
              {...register("cnpj", { required: true })}
            />
            <TextField
              disabled
              type='text'
              label={'Nome da Empresa'}
              InputLabelProps={{
                shrink: true,
              }}
              {...register("nome_empresa", { required: true })}
            />

            <TextField
              disabled
              required
              label={"Data Abertura"}
              type="date"
              sx={{width:'20%'}}
              InputLabelProps={{
                shrink: true,
              }}
              {...register("data_abertura", { required: true })}
            />

            <Typography>
              Localidade:
            </Typography>
            <SelectCustom 
              name="localidade_id" 
              options={localityOptions}
              register={register}
              defaultValue={client?.localidade_id}
            />

            <TextField
              disabled
              required
              label={"Nome do Empresário"}
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("nome_empresario", { required: true })}
            />

            <TextField
              disabled
              required
              label={"Data nascimento"}
              type="date"
              sx={{width:'20%'}}
              InputLabelProps={{
                shrink: true,
              }}
              {...register("data_nascimento", { required: true })}
            />

            <Typography>
              Sexo:
            </Typography>
            <SelectCustom 
              name="sexo_id" 
              options={genderOptions}
              register={register}
              defaultValue={client?.sexo_id}
            />

            <TextField
              disabled
              required
              label={"E-mail"}
              type="email"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("email", { required: true })}
            /> 

            <TextField
              disabled
              required
              label={"Telefone"}
              type="tel"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("telefone", { required: true })}
            /> 

            <Typography>
              Categoria do Negócio:
            </Typography>
            <SelectCustom 
              name="categoria_negocio_id" 
              options={businessCategoryOptions}
              register={register}
              defaultValue={client?.categoria_negocio_id}
            />

            <TextField
              disabled
              required
              label={"Valor Mensalidade"}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("valor_mensalidade", { required: true })}
            /> 

            <Typography>
              Classificação:
            </Typography>
            <SelectCustom 
              name="classificacao_empresa_id" 
              options={classificationOptions}
              register={register}
              defaultValue={client?.classificacao_empresa_id}
            />
          </Box>
        </>
      :
        <Typography>
          Carregando
        </Typography>
      }
        
      </Box>

      <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2}}>
        <Button 
          variant="contained" 
          onClick={handleBack}
          sx={{width:"15vw"}}
          color="error"
        >
          <Typography sx={{fontSize:"18px"}}>Voltar</Typography>
        </Button>
      </Box>
      
    </LayoutAdminDefault>
  )
}