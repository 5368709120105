import React from "react";

import { useAuthContext } from "../../../../contexts";
import { LayoutAdminDefault } from "../../../../layouts";
import { Button } from "@mui/material";
import { Box } from "@mui/system";

export function ProfileAdmin(){

  const { handleLogout, handleUpdatePermissions } = useAuthContext();

  // ExitToAppIcon

  const handlePermissions = async () => {
    const {success, msg} = await handleUpdatePermissions();
    alert(msg);
  }

  return(
    <LayoutAdminDefault
      title="Perfil"
    >
      <Box sx={{m:1, display:'flex', flexDirection:'column', width:300, gap:2}}>
        <Button variant="contained" onClick={handlePermissions}>
          Atualizar permissões
        </Button>
        <Button variant="contained" onClick={handleLogout}>
          Sair
        </Button>
      </Box>
    </LayoutAdminDefault>
  )
}