import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { CheckedIcon } from "../CheckedIcon";

export function TableTreatmentIndications() {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				border: "1px solid #D1D5DB",
				borderRadius: "12px",
				gap: "8px",
			}}
		>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<Typography
								variant="body-m"
								sx={{ color: "#374151" }}
							>
								Indicação
							</Typography>
						</TableCell>
						<TableCell align="center">
							<Typography
								variant="body-m"
								sx={{ color: "#374151" }}
							>
								≤ 0,3mm
							</Typography>
						</TableCell>
						<TableCell align="center">
							<Typography
								variant="body-m"
								sx={{ color: "#374151" }}
							>
								0,5mm
							</Typography>
						</TableCell>
						<TableCell align="center">
							<Typography
								variant="body-m"
								sx={{ color: "#374151" }}
							>
								0,75mm
							</Typography>
						</TableCell>
						<TableCell align="center">
							<Typography
								variant="body-m"
								sx={{ color: "#374151" }}
							>
								1,0mm
							</Typography>
						</TableCell>
						<TableCell align="center">
							<Typography
								variant="body-m"
								sx={{ color: "#374151" }}
							>
								≥ 1,5mm
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							<Typography
								variant="body-r-v2"
								sx={{ color: "#09234A" }}
							>
								Linhas finas de expressão
							</Typography>
						</TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center"></TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Typography
								variant="body-r-v2"
								sx={{ color: "#09234A" }}
							>
								Marcas de idade e prejuízos do sol
							</Typography>
						</TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Typography
								variant="body-r-v2"
								sx={{ color: "#09234A" }}
							>
								Rugas e marcas de expressão
							</Typography>
						</TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Typography
								variant="body-r-v2"
								sx={{ color: "#09234A" }}
							>
								Marcas de acne e cicatrizes
							</Typography>
						</TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Typography
								variant="body-r-v2"
								sx={{ color: "#09234A" }}
							>
								Cicatrizes intensas e estrias
							</Typography>
						</TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Typography
								variant="body-r-v2"
								sx={{ color: "#09234A" }}
							>
								Celulites variadas
							</Typography>
						</TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Typography
								variant="body-r-v2"
								sx={{ color: "#09234A" }}
							>
								Uso e aplicações de cosméticos e fórmulas
							</Typography>
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Typography
								variant="body-r-v2"
								sx={{ color: "#09234A" }}
							>
								Uso e aplicações de cosméticos e fórmulas
							</Typography>
						</TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
						<TableCell align="center">
							<CheckedIcon />
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Box>
	);
}
