import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";

import { LayoutAdminDefault } from "../../../../layouts";
import { useAuthContext } from "../../../../contexts";

export function ChangePassAdmin() {

  const navigate = useNavigate();

  const schema = yup.object({
    newPass: yup.string().required("Campo obrigatório!"),
    confirmNewPass: yup.string().oneOf([yup.ref("newPass"), null], "A senha de confirmação não confere!").required("Campo obrigatório!")
  }).required();

  const { register, handleSubmit, formState: { errors} } = useForm({resolver: yupResolver(schema)});

  const { handleChangePassByUser } = useAuthContext();

  const handleChange = async (data) =>{
    const { ok, msg } = await handleChangePassByUser(data);
    alert(msg);
    navigate('/home');
  }

  return (
    <LayoutAdminDefault
      title="Alterar senha do usuário"
    >
      <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2}}>
        <TextField
          required
          id="newPass"
          label="Nova Senha"
          type="password"
          autoComplete="current-password"
          {...register("newPass", { required: true })}
        />
        {errors.newPass && <Typography variant="caption" color="#DC1637">{errors.newPass?.message}</Typography>}

        <TextField
          required
          id="confirmNewPass"
          label="Confirme a Nova Senha"
          type="password"
          autoComplete="current-password"
          {...register("confirmNewPass", { required: true })}
        />
        {errors.confirmNewPass && <Typography variant="caption" color="#DC1637">{errors.confirmNewPass?.message}</Typography>}

      </Box>

      <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2}}>
        <Button
          variant="contained" 
          onClick={handleSubmit(handleChange)}
          sx={{width:"15vw"}}
          color="primary"
        >
          <Typography sx={{fontSize:"18px"}}>Atualizar</Typography>
        </Button>
      </Box>
      
    </LayoutAdminDefault>
  )
}