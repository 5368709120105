import { Box, Typography } from "@mui/material";
import {
	AccordionCustom,
	PageLayout,
	ProfileInfo,
} from "../../../../../../components";
import { TableTreatmentIndications } from "./components";
import { assets } from "../../../../../../assets/images_sys";

export function TreatmentMicroagulhamento() {
	return (
		<PageLayout.Container>
			<PageLayout.Header.Container>
				<PageLayout.Header.ButtonBack />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container
				sx={{ maxWidth: "1056px", gap: "8px", paddingBottom:'40px' }}
			>
				{/* <ProfileInfo
					fullName="Lívia Silva"
					dateAndAge="18/02/1996 • 27 anos"
				/> */}
				<PageLayout.Body.Title
					subtitle="Consulta / Tratamentos"
					title="Anamnese / Microagulhamento - 08/09/2023"
					description="O Microagulhamento é uma técnica comprovada que estimula
					a produção natural de colágeno na pele. Isso resulta em
					uma pele mais firme, com textura suave e um brilho
					natural e saudável."
				/>
				{/* <Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "8px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Typography
							variant="title-h4-v2"
							sx={{ color: "#09234A" }}
						>
							Anamnese / Microagulhamento
						</Typography>
						<Typography
							variant="subtitle-m"
							sx={{ color: "#09234A" }}
						>
							08/09/2023
						</Typography>
					</Box>
					<Typography variant="body-r-v2" sx={{ color: "#374151" }}>
						O Microagulhamento é uma técnica comprovada que estimula
						a produção natural de colágeno na pele. Isso resulta em
						uma pele mais firme, com textura suave e um brilho
						natural e saudável.
					</Typography>
				</Box> */}
				<AccordionCustom headerTitle="Diretrizes deste procedimento">
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							paddingY: "16px",
							gap: "32px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "8px",
							}}
						>
							<Typography
								variant="big-b"
								sx={{ color: "#374151" }}
							>
								Profundidades de agulhas
							</Typography>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									border: "1px solid #D1D5DB",
									borderRadius: "12px",
									gap: "8px",
								}}
							>
								<img
									style={{ resize: "both" }}
									src={assets.imgs.needle_depths}
									alt="needle depths"
								/>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										gap: "8px",
										paddingX: "24px",
										paddingY: "16px",
									}}
								>
									<Typography
										variant="body-r-v2"
										sx={{ color: "#374151" }}
									>
										Esta imagem detalha as diferentes
										profundidades de agulhas disponíveis
										para o procedimento de Microagulhamento.
										Cada profundidade é cuidadosamente
										selecionada com base nas necessidades da
										paciente e nas áreas a serem tratadas.
									</Typography>
									<Typography
										variant="body-r-v2"
										sx={{ color: "#374151" }}
									>
										Essa variedade de opções permite uma
										abordagem personalizada, garantindo
										resultados eficazes e precisos. Utilize
										esta referência para determinar a
										profundidade adequada, proporcionando à
										paciente uma experiência segura e
										personalizada.
									</Typography>
								</Box>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "8px",
							}}
						>
							<Typography
								variant="big-b"
								sx={{ color: "#374151" }}
							>
								Indicações de tratamentos
							</Typography>
							<TableTreatmentIndications />
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "8px",
							}}
						>
							<Typography
								variant="big-b"
								sx={{ color: "#374151" }}
							>
								Parâmetros de Rolagem
							</Typography>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									border: "1px solid #D1D5DB",
									borderRadius: "12px",
									gap: "8px",
								}}
							>
								<img
									style={{ resize: "both" }}
									src={assets.imgs.scrolling_parameters}
									alt="scrolling parameters"
								/>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										gap: "8px",
										paddingX: "24px",
										paddingY: "16px",
									}}
								>
									<Typography
										variant="body-r-v2"
										sx={{ color: "#374151" }}
									>
										Esta imagem destaca os parâmetros de
										rolagem essenciais a serem considerados
										durante o procedimento de
										Microagulhamento. Cada movimento
										delicado e preciso da agulha é
										fundamental para garantir resultados
										eficazes e confortáveis para a paciente.
									</Typography>
									<Typography
										variant="body-r-v2"
										sx={{ color: "#374151" }}
									>
										Utilize esta referência visual para
										manter a precisão e a consistência ao
										realizar o tratamento, proporcionando à
										paciente uma experiência de cuidado da
										pele excepcional.
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</AccordionCustom>
				<AccordionCustom headerTitle="Histórico clínico">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				<AccordionCustom headerTitle="Termo de consentimento">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				<AccordionCustom headerTitle="Termo para uso de imagem">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				<AccordionCustom headerTitle="Plano de aplicação">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				<AccordionCustom headerTitle="Cuidado Pós">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
				<AccordionCustom headerTitle="Receita">
					<Typography>Conteúdo aqui</Typography>
				</AccordionCustom>
			</PageLayout.Body.Container>
		</PageLayout.Container>
	);
}
