import React from "react"

import { MarketingContextProvider } from "./context"
import { HeaderBack } from "../../../../../../components"
import { ContainerLayout } from "../../../../../../layouts"
import { TableFilterMarketing, TableMarketing } from "./components"

export function Marketing() {
  return (
    <ContainerLayout
      title="Finanças"
      subtitle="Gastos com Marketing"
      description="Cadastrar seus funcionários em nossa plataforma permite uma gestão mais eficiente do seu negócio, além de facilitar a emissão de relatórios e pagamentos. Mantenha sua equipe organizada e seus negócios em ordem!"
      sx={{
        width:'800px'
      }}
      headerCustom={<HeaderBack/>}
    >
      <MarketingContextProvider>
        <TableFilterMarketing/>
        <TableMarketing/>
      </MarketingContextProvider>
    </ContainerLayout>
  )
}