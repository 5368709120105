import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { LayoutAdminDefault } from "../../../../layouts";
import { TableGridUsers } from "../../../../components/TableGrid/admin";
import { useDebounce } from "../../../../hooks";

import { columnsUsers } from "../../../../data/admin";
// import { api } from "../../../services/axios_config";
import { AlertCustom } from "../../../../components/AlertCustom";
import { apiUsers } from "../../../../services/apiInterface/admin/users";


export function UsersAdmin() {
  const {state} = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updatedRows, setUpdatedRows] = useState(false);

  const [formMessage, setFormMessage] = useState( !!state ? {type:state.type, msg: state.msg}: null);
  const [openFormMessage, setOpenFormMessage] = useState(true);
  const [rowsCount, setRowsCount] = useState(0);

  const { debounce } = useDebounce();

  const search = useMemo(()=>{
    return searchParams.get('search') || '';
  },[searchParams]);

  const page = useMemo(()=>{
    return Number(searchParams.get('page') || '1');
  },[searchParams]);

  useEffect(()=>{

    debounce(()=>{
      try {
        setLoading(true);
        apiUsers.getAll(page, search)
          .then((result) => {
            setLoading(false);
            if(result instanceof Error){
              alert(result.message)
            }else{
              const { data:{ok, msg, count, users} } = result;

              if(ok){
                setRowsCount(count)
                setUsers(users);
              }else{
                alert(msg)
              }
            }
          })
  
      } catch (error) {
        alert(error);
      }
    });
  },[search, page, updatedRows]);

  return (
    <LayoutAdminDefault
      title="Usuários"
    >
      
      {!!formMessage && <AlertCustom open={openFormMessage} setOpen={setOpenFormMessage} formMessage={formMessage}/>}
      <TableGridUsers
        rows={users}
        columns={columnsUsers}
        search={search}
        setSearchParams={setSearchParams}
        loading={loading}
        page={page}
        rowsCount={rowsCount}
        onUpdatedRows={setUpdatedRows}
      />
    </LayoutAdminDefault>
  )
}