import React, { createContext, useContext } from "react"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { TypographyCustom } from "../components"

const NotifyContext = createContext()

export const useNotifyContext = ()=>{
  return useContext(NotifyContext)
}

export function NotifyProvider({children}) {

 /**
   * 
   * @param { "success" | "info" | "error" | "warning" } type
   * @param {string} msg 
   */

 const notify = (type='success', msg='') => {
   switch (type) {
     case 'success':
        toast.success(
          <TypographyCustom
            text={msg}
            type={'small-r'}
          />, 
          { 
            progressStyle: {backgroundColor:'#3466B2'}, icon: ({theme, type}) =>  <CheckCircleIcon color='#3466B2'/>
          }
        )
       break
       case 'info':
       toast.info(
        <TypographyCustom
          text={msg}
          type={'small-r'}
        />, 
          { autoClose: false }
        )
       break
     case 'error':
       toast.error( 
        <TypographyCustom
          text={msg}
          type={'small-r'}
        />, 
        { autoClose: false }
      )
       break
     case 'warning':
       toast.warning(
        <TypographyCustom
          text={msg}
          type={'small-r'}
        />, 
       )
       break
     default:
       break
   }
  }

  return (
    <NotifyContext.Provider value={{notify}}>
      {children}
      <ToastContainer 
        draggablePercent={60} 
        limit={5}
        autoClose={3000}
      />
    </NotifyContext.Provider>
  )
}