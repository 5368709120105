import { Box } from "@mui/material";
import { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CheckboxGroup from "./components/CheckboxGroup";
import ImageFemaleModel from "./components/ImageFemaleModel";
import { ButtonCustomSubmit, PageLayout } from "../../../../../components";

const ComplaintsContext = createContext({});
export const useComplaintsContext = () => useContext(ComplaintsContext);

export function BusinessPatientComplaints() {
	const navigate = useNavigate();
	const location = useLocation();
	const [complaintGroups, setComplaintGroups] = useState([
		{
			id: "cabelo",
			label: "Cabelo",
			complaints: [
				{ id: "queda_capilar", label: "Queda capilar", checked: false },
				{
					id: "coloracao_tintura",
					label: "Coloração ou tintura",
					checked: false,
				},
				{
					id: "corte_estilo",
					label: "Corte ou estilo",
					checked: false,
				},
			],
		},
		{
			id: "rosto",
			label: "Rosto",
			complaints: [
				{
					id: "rugas_linhas_expressao",
					label: "Rugas ou linhas de expressão",
					checked: false,
				},
				{
					id: "manchas_pele",
					label: "Manchas na pele",
					checked: false,
				},
				{ id: "cicatrizes", label: "Cicatrizes", checked: false },
				{ id: "olheiras", label: "Olheiras", checked: false },
				{ id: "sobrancelhas", label: "Sobrancelhas", checked: false },
				{
					id: "acne_sequelas",
					label: "Acne e sequelas",
					checked: false,
				},
			],
		},
		{
			id: "olhos",
			label: "Olhos",
			complaints: [
				{
					id: "palpebras_caidas",
					label: "Pálpebras caídas",
					checked: false,
				},
				{
					id: "olhos_inchados",
					label: "Olhos inchados",
					checked: false,
				},
				{
					id: "delineado_permanente",
					label: "Delineado permanente",
					checked: false,
				},
			],
		},
		{
			id: "nariz",
			label: "Nariz",
			complaints: [
				{
					id: "correcao_desvio_septo",
					label: "Correção do desvio de septo",
					checked: false,
				},
				{
					id: "rinoplastia",
					label: "Rinoplastia (modificação do formato do nariz)",
					checked: false,
				},
			],
		},
		{
			id: "labios",
			label: "Lábios",
			complaints: [
				{
					id: "preenchimento_labial",
					label: "Preenchimento labial",
					checked: false,
				},
				{
					id: "correcao_assimetria",
					label: "Correção de assimetria",
					checked: false,
				},
			],
		},
		{
			id: "corpo",
			label: "Corpo",
			complaints: [
				{
					id: "gordura_localizada",
					label: "Gordura localizada",
					checked: false,
				},
				{ id: "flacidez", label: "Flacidez", checked: false },
				{ id: "cicatrizes", label: "Cicatrizes", checked: false },
				{
					id: "remocao_tatuagens",
					label: "Remoção de tatuagens",
					checked: false,
				},
			],
		},
		{
			id: "pernas",
			label: "Pernas",
			complaints: [
				{ id: "celulite", label: "Celulite", checked: false },
				{ id: "estrias", label: "Estrias", checked: false },
			],
		},
	]);

	const handleToggleCheckedComplaint = (
		groupId,
		complaintId,
		complaintChecked
	) => {
		let tempComplaintGroups = [...complaintGroups];

		let complaintGroup = tempComplaintGroups.find(
			(group) => group.id === groupId
		);
		const complaintGroupIndex = tempComplaintGroups.findIndex(
			(group) => group.id === groupId
		);

		let complaints = complaintGroup.complaints;

		let complaintItem = complaints.find(
			(complaint) => complaint.id === complaintId
		);

		complaintItem.checked = complaintChecked;

		tempComplaintGroups[complaintGroupIndex].complaints = complaints;

		setComplaintGroups(tempComplaintGroups);
	};

	const handleSaveAndContinue = () => {
		navigate(`${location.pathname}/../photos`);
	};

	return (
		<PageLayout.Container>
			<PageLayout.Header.Container>
				<PageLayout.Header.ButtonBack />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container sx={{ maxWidth: "1000px" }}>
				<PageLayout.Body.Title
					subtitle="Dados Clínicos"
					title="Queixas • Lívia Silva"
					description="Marque as partes do seu corpo nas quais deseja realizar modificações estéticas, permitindo-nos personalizar um plano de tratamento que atenda aos seus objetivos individuais com precisão e eficácia."
				/>
				<Box
					sx={{ display: "flex", flex:1, flexDirection: "row", gap: "20px" }}
				>
					<ComplaintsContext.Provider
						value={{
							complaintGroups,
							setComplaintGroups,
							handleToggleCheckedComplaint,
						}}
					>
						<ImageFemaleModel complaintList={complaintGroups} />
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "26px",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "20px",
								}}
							>
								{complaintGroups.map((group) => {
									const id = group.id;
									const title = group.label;
									const complaints = group.complaints;

									return (
										<CheckboxGroup
											key={`chgroup-${id}`}
											id={id}
											label={title}
											options={complaints}
										/>
									);
								})}
							</Box>
							<Box sx={{ marginBottom: "32px" }}>
								<ButtonCustomSubmit
									text={'Salvar e continuar'}
									onClick={handleSaveAndContinue}
									sx={{ paddingY: "12px", paddingX: "22px" }}
								/>
							</Box>
						</Box>
					</ComplaintsContext.Provider>
				</Box>
			</PageLayout.Body.Container>
		</PageLayout.Container>
	);
}
