import { useEffect } from "react";
import { useBusinessPageContext } from "../../../../../contexts/BusinessPageContext";
import { PageLayout } from "../../../../../components";
import { assets } from "../../../../../assets/images_sys";

export function BusinessSchedule() {
	const { setIsPageDrawerShown } = useBusinessPageContext();

	useEffect(() => {
		setIsPageDrawerShown(true);
	}, []);

	return (
		<PageLayout.Container>
			<PageLayout.Header.Container>
				<PageLayout.Header.ButtonBack />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container>
				{/* <PageLayout.Body.Title title="Agenda" /> */}
					<img src={assets.imgs.tela_agenda_laura} style={{width: '100%', height: 'auto'}}/>
			</PageLayout.Body.Container>
		</PageLayout.Container>
	);
}
