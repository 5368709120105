import React, { createContext, useContext } from "react"
import { Box, CircularProgress } from "@mui/material"
import { useNavigate } from 'react-router-dom'

const ConsultationContext = createContext()

export const useConsultationContext = () => {
	return useContext(ConsultationContext)
}

export function ConsultationContextProvider({ children }) {
	// const { 
  //   loading, 
  //   consultations,
  //   dataSelect, 
  //   handleSave, 
  //   handleRemoveById, 
  //   statusTable, 
  //   getAllByFilters, 
  //   filtersActive, 
  //   setFiltersActive, 
  //   handleDataSelectForForm, 
  //   handleRemoveFilter 
  // } = usePatientConsultation()

  const navigate = useNavigate()

  const defaultStatusTable = {
    currentPage: 1,
    countTotalPages: 0,
    countTotalRows: 0
  }

  const loading = false

  const handleIsOpenDrawerForm = () => {
    // navigate('pre-consultation/clinical-history')
  }

	return (
		<ConsultationContext.Provider
			value={{
        loading,
        consultations: [], 
        handleRemoveById: ()=>{},
        statusTable: defaultStatusTable,
        getAllByFilters: ()=>{},
        handleSave: ()=>{},
        filtersActive: {}, 
        setFiltersActive: ()=>{},
        handleDataSelectForForm: ()=>{},
        handleRemoveFilter: ()=>{},
        handleIsOpenDrawerForm
			}}
		>
      {loading &&
        <Box sx={{m:'10px'}}>
          <CircularProgress color="info"/>
        </Box>
      }
      
      <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'10px'}}>
			  {!loading && children}
      </Box>
		</ConsultationContext.Provider>
	)
}
