import React from "react"

import { useSalesPortfolioContext } from "../../../../context"
import { HeaderView } from "../../../../../../../../../../components"

export default function HeaderViewSalesPortfolio(){
  const { handleRemoveById, dataSelectView, handleIsOpenDrawerForm } = useSalesPortfolioContext()

  const data = {
    id: dataSelectView?.id,
    url_img: dataSelectView?.url_img,
    title: !!dataSelectView?.tratamento?.nome ? `${dataSelectView?.tratamento?.nome} - ${dataSelectView?.tratamento?.categoria?.nome}` : dataSelectView?.nome_servico,
    subTitle: dataSelectView?.aux_ci_categoria_vendas?.descricao,
    textFieldEdit: 'Editar serviço',
    textFieldRemove: 'Excluir serviço',
  }

  const handleOpenDrawerForm = () => {
    handleIsOpenDrawerForm(dataSelectView)
  }

  return (
    <HeaderView
      data={data}
      handleRemoveById={handleRemoveById}
      handleIsOpenDrawerForm={handleOpenDrawerForm}
      pathReturn={'/diagnostic/marketing/salesPortfolio'}
    />
  )
}