import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Box, Typography} from '@mui/material';

function CircularProgressWithLabel(props) {
  
  let colorTextPercent = '#6B7280';

  if(Math.round(props.value,) == 100){
    colorTextPercent = '#018F5D';
  }

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} color='success'/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography sx={{fontSize:'12px', fontWeight:400, lineHeight:'150%', color:colorTextPercent, fontFamily:'Inter'}}>
        {/* <Typography variant="caption" component="div" color="text.secondary"> */}
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export function CircularProgressCustom({value}) {
  const progress = value*100;
  // const [progress, setProgress] = useState(10);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return <CircularProgressWithLabel value={progress} />;
}