import React from "react"
import { Controller } from "react-hook-form"
import { Box, TextField } from "@mui/material"

import { ErrorMessageForm } from "../ErrorMessageForm"
import { TypographyCustom } from "../TypographyCustom"

export function TextFieldCustomNumber({
  label,
  required = false,
  name,
  control,
  disabled = false,
  min = 0,
  max = 999999999,
  textAlign = 'left' || 'right',
  numbersNotAccept = [],
  sx={},
  sxText={}
}) {

  const styleTypography = {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    color: "#374151",
    fontFamily: "Inter",
  }

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap:'3px', ...sx }}>
      {label &&
        <TypographyCustom
          text={`${label}${required ? "*" : ""}`}
          type='label-s'
        />
      }
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error} }) => {
          return (
            <>
              <TextField
                disabled={disabled}
                variant="outlined"
                value={value == '' ? 0 : value}
                error={!!error}
                onChange={(val)=>{
                  const input = val.nativeEvent.target.value
                  const inputNumber = parseInt(input)
                  const inputDefault = input
                  if((inputNumber >= min && inputNumber <= max) || inputDefault == ''){
                    if(!numbersNotAccept.includes(inputNumber, 0)){
                      onChange(val)
                    }
                  }
                }}
                defaultValue={0}
                type="text"           
                inputProps={{ maxLength: 200, min: min, max: max, style: {textAlign: textAlign}}}
                InputProps={{
                  style: {
                    borderRadius: 10,
                    height: '44px',
                    backgroundColor:'#FFFFFF',
                    border: '1px solid #6B7280',
                    ...styleTypography,
                    ...sxText
                  }
                }}
                onKeyDown={(event) => {
                  const key = event.key
                  if(min >= 0){
                    if(key === '-'){
                      event.preventDefault()
                    }
                  }
                }}
              />
              {!!error && <ErrorMessageForm errorField={error}/>}
            </>

          )
        }}
        rules={required && { required: "Este campo é obrigatório"}}
      />
    </Box>
  )
}
