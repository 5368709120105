import React from "react";
import RuleIcon from '@mui/icons-material/Rule';
import BadgeIcon from '@mui/icons-material/Badge';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import HouseboatIcon from '@mui/icons-material/Houseboat';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import { useAuthContext } from "../contexts";

export const usePermissionsAdminOptions = () => {
  const { userLogged } = useAuthContext();
  
  const gatePermissionsOptions = () => {
    const allOptions = [
      {
        category: 'cadastros',
        name: "cadastro_usuarios",
        label:"Usuários",
        icon: ()=>(<PeopleIcon/>),
        path:"/usuarios"
      },
      {
        category: 'cadastros',
        name: "cadastro_grupo_acesso",
        label:"Grupos de Acesso",
        icon: ()=>(<BadgeIcon/>),
        path:"/papeis"
      },
      {
        category: 'cadastros',
        name: "cadastro_clientes",
        label:"Clientes",
        icon: ()=>(<PeopleOutlineIcon/>),
        path:"/clientes"
      },
    ];

    var optionsUser = [
    ];

    if(!!userLogged){
      for (const permissionUser of userLogged.permissions) {
        const permissionFind = allOptions.find((option)=>{
          return option.name === permissionUser.name;
        });
        if(!!permissionFind){
          optionsUser.push(permissionFind);
        }
      }
    }
    return optionsUser;
  }

  return { gatePermissionsOptions };
}