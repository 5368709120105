//Imagens
// const logo_blue = require('./logo_blue.png');
// const logo_blue_clear = require('./logo_blue_clear.png');
const tela_img_login = require('./tela_img_login.png');
const tela_img_primeiros_passos = require('./tela_img_primeiros_passos.png');
const tela_img_perfil = require('./tela_img_perfil.png');
const tela_img_paciente = require('./tela_img_paciente.png');
const bk_trocar_senha = require('./bk_trocar_senha.png');
const bk_trocar_senha_sucesso = require('./bk_trocar_senha_sucesso.png');
const tela_resultado_diagnostico = require('./tela_resultado_diagnostico.png');
const img_boleto = require('./boleto.png');
const img_pix = require('./pix.png');
const img_info = require('./info.png');
const img_agenda_link = require('./img_agenda_link.png');
const img_agenda_calendar_hour = require('./agenda_calendar_hour.png');
const img_default = require('./img_default.png');
const img_female_body_model = require('./img_female_body_model.png')
const human_face_1_front = require('./human_face1_front.png');
const human_face_1_side = require('./human_face1_side.png');
const human_face_2_front = require('./human_face2_front.png');
const human_face_2_side = require('./human_face2_side.png');
const human_face_3_front = require('./human_face3_front.png');
const human_face_3_side = require('./human_face3_side.png');
const facial_biotype = require('./facial_biotype.png');
const facial_biotype_face_1 = require('./facial_biotype_face_1.png');
const facial_biotype_face_2 = require('./facial_biotype_face_2.png');
const facial_biotype_face_3 = require('./facial_biotype_face_3.png');
const treatment_plan_facial_1 = require('./treatment_plan_facial_1.png');
const treatment_plan_facial_2 = require('./treatment_plan_facial_2.png');
const treatment_plan_facial_3 = require('./treatment_plan_facial_3.png');
const treatment_plan_facial_4 = require('./treatment_plan_facial_4.png');
const treatment_plan_facial_5 = require('./treatment_plan_facial_5.png');
const treatment_plan_1 = require('./treatment_plan_1.png');
const treatment_plan_2 = require('./treatment_plan_2.png');
const treatment_plan_3 = require('./treatment_plan_3.png');
const treatment_plan_4 = require('./treatment_plan_4.png');
const human_body = require('./human_body.png');
const needle_depths = require('./needle_depths.png');
const scrolling_parameters = require('./scrolling_parameters.png');

//SVG
// import svg_logo_blue from './logo_blue.svg';
// import svg_logo_blue_clear from './logo_blue_clear.svg';
// import svg_logo_white_clear from './logo_white_clear.svg';
import svg_processando_perfil from './tela_svg_processando_perfil.svg';
import svg_processando_diagnostico from './tela_svg_processando_diagnostico.svg';
import svg_resultado_diagnostico from './tela_svg_resultado_diagnostico.svg';
import svg_bk_trocar_senha from './bk_trocar_senha.svg';
import svg_bk_trocar_senha_sucesso from './bk_trocar_senha_sucesso.svg';

// NOVOS LOGOS
//PNG
const logo_blue = require('./novo_logo/png/logo_nome_horizontal.png');
const logo_blue_clear = require('./novo_logo/png/logo.png');
const tela_agenda_laura = require('./agenda_laura.png');
//SVG
import svg_logo_blue from './novo_logo/svg/logo_nome.svg';
import svg_logo_blue_clear from './novo_logo/svg/logo.svg';
import svg_logo_white_clear from './novo_logo/svg/logo.svg';

export const assets = {
  imgs: {
    logo_blue,
    logo_blue_clear,
    tela_img_login,
    tela_img_primeiros_passos,
    tela_img_perfil,
    tela_img_paciente,
    bk_trocar_senha,
    bk_trocar_senha_sucesso,
    tela_resultado_diagnostico,
    tela_agenda_laura,
    img_boleto,
    img_pix,
    img_info,
    img_agenda_link,
    img_agenda_calendar_hour,
    img_default,
    img_female_body_model,
    human_face_1_front,
    human_face_1_side,
    human_face_2_front,
    human_face_2_side,
    human_face_3_front,
    human_face_3_side,
    facial_biotype,
    facial_biotype_face_1,
    facial_biotype_face_2,
    facial_biotype_face_3,
    treatment_plan_facial_1,
    treatment_plan_facial_2,
    treatment_plan_facial_3,
    treatment_plan_facial_4,
    treatment_plan_facial_5,
    treatment_plan_1,
    treatment_plan_2,
    treatment_plan_3,
    treatment_plan_4,
    human_body,
    needle_depths,
    scrolling_parameters,
  },
  svgs: {
    svg_logo_blue,
    svg_logo_blue_clear,
    svg_logo_white_clear,
    svg_processando_perfil,
    svg_processando_diagnostico,
    svg_resultado_diagnostico,
    svg_bk_trocar_senha,
    svg_bk_trocar_senha_sucesso
  }
}