import React from "react";
import { ReactSVG } from 'react-svg';
import { Box, Button, Typography } from "@mui/material";

import { assets } from '../../assets/images_sys';
import { useAuthContext } from "../../contexts";

export function HeaderMain({height, isAnswerLaterButtonActive=true}) {
  const { handleLogout } = useAuthContext();
  return (
    <Box
      sx={{
        width: "100vw",
        height: height,
        display:'flex',
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Box sx={{ ml: 3, display:'flex', alignItems:'center', justifyContent:'center'}}>
        <Box sx={{mt:1}}>
          <ReactSVG 
            src={assets.svgs.svg_logo_blue_clear}
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 40px')
            }}  
          />
        </Box>
        <Typography sx={{ml:'5px', fontSize:'20px', fontWeight:500, lineHeight:'150%', color:'#374151', fontFamily:'Inter'}}>
          Empresa Demo
        </Typography>
      </Box>
      
      {isAnswerLaterButtonActive &&
       <Box sx={{ mr: 3}}>
        <Button onClick={handleLogout} sx={{textTransform:'none', backgroundColor:'#EFF5FF', width:'155px'}}>
          <Typography sx={{fontSize:'14px', fontWeight:600, lineHeight:'100%', color:'#1A417D', fontFamily:'Inter'}}>
            Responder depois
          </Typography>
        </Button>
      </Box>
      }
    </Box>
  )
}