import React, { useState } from "react"
import { Box, Typography } from "@mui/material"

import { LinearProgressCustom } from "../LinearProgressCustom"
import { TypographyCustom } from "../TypographyCustom"

export function CardBox({icon, title, handleClick, value = undefined}) {

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <Box
      sx={{
        width: '300px',
        height: '192px',
        pl: '18px',
        pr: '18px',
        pb: '20px',
        pt: '20px',
        backgroundColor: '#FFFFFF',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        '&:hover': {
          backgroundColor: isHovered ? '#D1D5DB' : '#FFFFFF',
        },
        border: '1px solid #D1D5DB',
        borderRadius: '8px'
      }}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      
      <Box sx={{width:'100%', height:'50%'}}>
        {!!value && 
          <LinearProgressCustom value={value}/>
        }
      </Box>
      <Box sx={{width:'100%', height:'50%'}}>
        <Box sx={{height:'100%', display:'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-end', gap: '8px'}}>
          {!!icon && icon}
          <TypographyCustom
            text={title}
            type={'subtitle-r'}
          />
          {/* <Typography sx={{fontSize:'24px', fontWeight:500, lineHeight:'130%', color:'#4B5563', fontFamily:'Inter'}}>
            {title}
          </Typography> */}
        </Box>
      </Box>
    </Box>
  )
}