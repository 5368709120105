import React from 'react'
import { Box } from '@mui/material'

import FormAnamnesis from './formAnamnesis'

export const TabAnamnesis = () => {
  return (
    <Box sx={{width:'100%', height:'100%'}}>
      <FormAnamnesis/>
    </Box>
  )
}