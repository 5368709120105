import { get } from "../../../../environment";
import { api } from "../../../axios_config";

const getOne = async (id) => {
  const resp = await api.get(`/cadastros/roles/${id}`);
  return resp.data;
}

const getAllByUser = async (userId) => {
  const resp = await api.get(`/cadastros/userRoles/${userId}`)
  return resp.data;
}

const getAll = async (page, search) => {
  const resp = await api.get(`/cadastros/roles?limit=${get('LIMIT_ROWS_TABLE_PAGE')}&page=${page}&search=${search}`)
  return resp;
}

const getAllForAttach = async () => {
  const resp = await api.get(`/cadastros/rolesForAttach`)
  return resp;
}

const post = async (newRole) => {
  const resp = await api.post('/cadastros/roles', newRole);
  return resp.data;
}

const update = async (id, role) => {
  const resp = await api.put(`/cadastros/roles/${id}`, role);
  return resp.data;
}

const updateRolesUser = async (rolesUser) => {
  const resp = await api.post('/cadastros/userRoles', rolesUser)
  return resp.data;
}

const del = async (id) => {
  const resp = await api.delete(`/cadastros/roles/${id}`);
  return resp;
}

export const apiRoles = {
  del,
  post,
  update,
  getOne,
  getAll,
  getAllByUser,
  getAllForAttach,
  updateRolesUser,
}