import React from 'react'
import { Box, Typography } from '@mui/material'

import { useSalesPortfolioContext } from '../../../../../../../../context'
import { AccordionCustom } from '../../../../../../../../../../../../../../components'
import { ID_CLASSIFICATION_BODY, ID_CLASSIFICATION_CAPILLARY, ID_CLASSIFICATION_FACIAL } from '../../../../constrains'

export const TabApplicationPlan = () => {
  const { dataSelectView } = useSalesPortfolioContext()

  const checkClassification = (id) => {
    const classification = dataSelectView?.classificacao || []
    const check = classification.some(item => item.id == id)
    return check
  }

  return (
    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', gap:'8px'}}>
      {checkClassification(ID_CLASSIFICATION_CAPILLARY) && 
        <AccordionCustom
          headerTitle="Plano de Aplicação Capilar"
          colorClosed={'#f3f4f6'}
          defaultExpanded
        >
          <Typography>Conteúdo aqui</Typography>
        </AccordionCustom>
      }
      {checkClassification(ID_CLASSIFICATION_FACIAL) && 
        <AccordionCustom
          headerTitle="Plano de Aplicação Facial"
          colorClosed={'#f3f4f6'}
          defaultExpanded
        >
          <Typography>Conteúdo aqui</Typography>
        </AccordionCustom>
      }
      {checkClassification(ID_CLASSIFICATION_BODY) && 
        <AccordionCustom
          headerTitle="Plano de Aplicação Corporal"
          colorClosed={'#f3f4f6'}
          defaultExpanded
        >
          <Typography>Conteúdo aqui</Typography>
        </AccordionCustom>
      }
    </Box>
  )
}