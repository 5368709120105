import { Box, ListItemButton, ListItemIcon, Typography } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view";
import { useDrawerNavigationContext } from "../DrawerNavigationContainer";
import {
	ChevronDownIcon,
	ChevronRightIcon,
	ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";

export function DrawerNavigationItem({
	nodeId,
	icon = null,
	iconOnClosed = null,
	label = "",
	labelRight = "",
	children = null,
	path = "",
}) {
	const { open, selected, expanded, openDrawer } =
		useDrawerNavigationContext();
	const navigate = useNavigate();

	const buttonHeight = "45px";
	const isParent = !nodeId.includes('.')
	const isNodeSelected = selected === nodeId;
	const isNodeExpanded = expanded.find(
		(expandedNodeId) => expandedNodeId === nodeId
	);
	const colorOnSelected = isNodeSelected ? "#EFF5FF" : "#A7CAFF";
	const iconStyle = {
		color: isParent ? "#EFF5FF" : colorOnSelected,
		width: "24px",
		height: "24px",
	};
	const IconComponent = icon;
	const IconClosedComponent = iconOnClosed;
	const ExpandIcon = isNodeExpanded ? ChevronUpIcon : ChevronDownIcon;


	const TreeItemLabel = () => {
		const textStyle = {
			color: isParent ? "#EFF5FF" : colorOnSelected,
		};

		return (
			<Box
				sx={{
					height: buttonHeight,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					color: "#D1D5DB",
				}}
			>
				<Box
					sx={{
						display: "flex",
						gap: "10px",
						alignItems: "center",
					}}
				>
					{icon && <IconComponent style={iconStyle} />}
					{open && (
						<Typography variant={isParent ? 'body-r-v2': 'label-m-v3'} sx={{ ...textStyle }}>
							{label}
						</Typography>
					)}
				</Box>
				{open && (
					<Box
						sx={{
							display: "flex",
							gap: "8px",
							alignItems: "center",
						}}
					>
						<Typography variant={isParent ? 'body-r-v2': 'label-m-v3'} sx={{ ...textStyle }}>
							{labelRight}
						</Typography>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
							}}
						>
							{/* {!!children && <ExpandIcon style={{ ...iconStyle }} />} */}
							{!children ? (
								<ChevronRightIcon style={{ ...iconStyle }} />
							) : (
								<ExpandIcon style={{ ...iconStyle }} />
							)}
						</Box>
					</Box>
				)}
			</Box>
		);
	};

	const handleOnClick = () => {
		if (!path) return;
		navigate(path);
	};

	return open ? (
		<TreeItem
			nodeId={nodeId}
			label={<TreeItemLabel />}
			onClick={handleOnClick}
		>
			{children}
		</TreeItem>
	) : (
		<Box sx={{width:'100%', maxHeight:'100%', display:'flex', flexDirection:'column', px:'6px'}}>
			<ListItemButton
				sx={{
					height: buttonHeight,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
				onClick={openDrawer}
			>
				<ListItemIcon
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{iconOnClosed ? (
						<IconClosedComponent style={iconStyle} />
					) : (
						icon && <IconComponent style={iconStyle} />
					)}
				</ListItemIcon>
			</ListItemButton>

		</Box>
	);
}
