import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

import { LayoutAdminDefault } from "../../../../../layouts";
import { useAuthContext } from "../../../../../contexts";
import { apiClient } from "../../../../../services/apiInterface/admin/clients";
import { SelectCustom } from "../../../../../components";
import { formatDateTime } from "../../../../../utils/tools";
import { normalizeCNPJNumber, normalizePhoneNumber } from "../../../../../utils/masks";

export function EditClients() {

  const params = useParams();

  const navigate = useNavigate();

  const { userLogged } = useAuthContext();

  const [client, setClient] = useState();
  const [genderOptions, setGenderOptions] = useState([]);
  const [localityOptions, setLocalityOptions] = useState([]);
  const [classificationOptions, setClassificationOptions] = useState([]);
  const [businessCategoryOptions, setBusinessCategoryOptions] = useState([]);
  
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    (async ()=>{
      try {
        const {ok, register} = await apiClient.getOne(params?.id);
        if(ok){
          setClient(register);
        }
      } catch (error) {
        alert('Error: ' + error.message);
      }
    })()
  },[]);

  const schema = yup.object({
    status: yup.string().required("Campo obrigatório!"),
    cnpj: yup.string().required("Campo obrigatório!"),
    nome_empresa: yup.string().required("Campo obrigatório!"),
    data_abertura: yup.string().required("Campo obrigatório!"),
    nome_empresario: yup.string().required("Campo obrigatório!"),
    data_nascimento: yup.string().required("Campo obrigatório!"),
    email: yup.string().required("Campo obrigatório!"),
    telefone: yup.string().required("Campo obrigatório!"),
    valor_mensalidade: yup.string().required("Campo obrigatório!"),
    valor_total: yup.string().required("Campo obrigatório!"),
  }).required();

  const { register, handleSubmit, setValue, formState: { errors}, } = useForm({ resolver: yupResolver(schema)});

  const handleBack = () => {
    navigate('/clientes');
  }

  const sendForm = async (newRegister) => {
    try {
      
      newRegister.usuarios_id = userLogged.id;

      const {ok, msg} = await apiClient.update(params.id, newRegister);

      navigate('/clientes', {state: {type: ok?'success':'error', msg: msg}});

    } catch (error) {
      navigate('/clientes', {state: {type:'error', msg: error.message}});
    }
  }

  const handleEdit = (data) => {
    sendForm(data);
  }  

  useEffect(()=>{
    (async ()=>{
      try {
        const optionsCadastre = await apiClient.getOptionsCadastre();
        setLocalityOptions(optionsCadastre.locality);
        setGenderOptions(optionsCadastre.gender);
        setBusinessCategoryOptions(optionsCadastre.businessCategory);
        setClassificationOptions(optionsCadastre.classification);
      } catch (error) {
        alert('Error: ' + error.message);
      }
    })()
  },[]);

  useEffect(()=>{
    if(localityOptions.length > 0 && 
      genderOptions.length > 0 &&  businessCategoryOptions.length > 0 && 
      businessCategoryOptions.length > 0 && classificationOptions.length > 0){
      setLoading(false)
    }else{
      setLoading(true)
    }
  },[localityOptions, genderOptions, businessCategoryOptions, businessCategoryOptions, classificationOptions]);


  useEffect(()=>{
    setValue('cnpj', normalizeCNPJNumber(client?.cnpj)); 
    setValue('nome_empresa', client?.nome_empresa); 
    setValue('data_abertura', formatDateTime(client?.data_abertura,'YYYY-MM-DD')); 
    setValue('nome_empresario', client?.nome_empresario); 
    setValue('data_nascimento', formatDateTime(client?.data_nascimento, 'YYYY-MM-DD'));
    setValue('email', client?.email); 
    setValue('telefone', normalizePhoneNumber(client?.telefone)); 
    setValue('valor_mensalidade', client?.valor_mensalidade); 
    setValue('valor_total', client?.valor_total); 
  },[client]);


  return (
    <LayoutAdminDefault
      title="Editar cliente"
    >
      <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2}}>
        {!loading ? 
          <>
            <FormControl required>
              <InputLabel id="status">Status</InputLabel>
              <Select
                id="status"
                label="Status *"
                defaultValue={client?.status}
                {...register("status", { required: true })}
              >
                <MenuItem value={"1"}>Ativo</MenuItem>
                <MenuItem value={"0"}>Inativo</MenuItem>
              </Select>
            </FormControl>

            <TextField
              required
              label={"CNPJ"}
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("cnpj", { required: true })}
            />
            {/* {errors.cell && <Typography variant="caption" color="#DC1637">{errors.cell?.message}</Typography>} */}

            <TextField
              required
              label={"Nome da Empresa"}
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("nome_empresa", { required: true })}
            />

            <TextField
              required
              label={"Data Abertura"}
              type="date"
              sx={{width:'20%'}}
              InputLabelProps={{
                shrink: true,
              }}
              {...register("data_abertura", { required: true })}
            />

            <Typography>
              Localidade:
            </Typography>
            <SelectCustom 
              name="localidade_id" 
              options={localityOptions}
              register={register}
              defaultValue={client?.localidade_id}
            />

            <TextField
              required
              label={"Nome do Empresário"}
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("nome_empresario", { required: true })}
            />

            <TextField
              required
              label={"Data nascimento"}
              type="date"
              sx={{width:'20%'}}
              InputLabelProps={{
                shrink: true,
              }}
              {...register("data_nascimento", { required: true })}
            />

            <Typography>
              Sexo:
            </Typography>
            <SelectCustom 
              name="sexo_id" 
              options={genderOptions}
              register={register}
              defaultValue={client?.sexo_id}
            />
            
            <TextField
              required
              label={"E-mail"}
              type="email"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("email", { required: true })}
            /> 

            <TextField
              required
              label={"Telefone"}
              type="tel"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("telefone", { required: true })}
            /> 

            <Typography>
              Categoria do Negócio:
            </Typography>
            <SelectCustom 
              name="categoria_negocio_id" 
              options={businessCategoryOptions}
              register={register}
              defaultValue={client?.categoria_negocio_id}
            />

            <TextField
              required
              label={"Valor Mensalidade"}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("valor_mensalidade", { required: true })}
            />

            <TextField
              required
              label={"Valor Total"}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("valor_total", { required: true })}
            /> 

            <Typography>
              Classificação:
            </Typography>
            <SelectCustom 
              name="classificacao_empresa_id" 
              options={classificationOptions}
              register={register}
              defaultValue={client?.classificacao_empresa_id}
            />

          </>        
        :
          <Typography>
            Carregando...
          </Typography>
        }
      
      </Box>

      <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2}}>
        <Button
          variant="contained" 
          onClick={handleSubmit(handleEdit)}
          sx={{width:"15vw"}}
          color="primary"
        >
          <Typography sx={{fontSize:"18px"}}>Atualizar</Typography>
        </Button>

        <Button 
          variant="contained" 
          onClick={handleBack}
          sx={{width:"15vw"}}
          color="error"
        >
          <Typography sx={{fontSize:"18px"}}>Voltar</Typography>
        </Button>
      </Box>
      
    </LayoutAdminDefault>
  )
}