import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";

import { LayoutAdminDefault } from "../../../../../layouts";
import { normalizeCNPJNumber } from "../../../../../utils/masks";
import { apiClient } from "../../../../../services/apiInterface/admin/clients";
import { formattedNameSubdomain } from "../../../../../utils/tools";
import { useAuthContext } from "../../../../../contexts";
import { getConfig } from "../../../../../environment";

export function SubDomainClients() {

  const params = useParams();

  const navigate = useNavigate();

  const { userLogged } = useAuthContext()

  const [client, setClient] = useState();
  const [loading, setLoading] = useState(true);

  const schema = yup.object({
    nome_empresa: yup.string().required("Campo obrigatório!"),
    cnpj: yup.string().required("Campo obrigatório!"),
    nome: yup.string().required("Campo obrigatório!"),
    link: yup.string().required("Campo obrigatório!"),
    nome_banco_dados: yup.string().required("Campo obrigatório!"),
    usuario_banco_cliente: yup.string().required("Campo obrigatório!"),
    senha_usuario_banco: yup.string().required("Campo obrigatório!"),
    conf_senha_usuario_banco: yup.string().required("Campo obrigatório!")
  }).required();

  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm({ resolver: yupResolver(schema), defaultValues:{
    usuario_banco_cliente: 'admin',
    link:'.cheffinanceiro.com.br',
    nome_banco_dados: 'cheffinanceiro'
  }});

  const handleBack = () => {
    navigate('/clientes');
  }

  const handleSuggestSubdomain = () => {
    setValue('nome', formattedNameSubdomain(client?.nome_empresa));
  }

  const sendForm = async (data) => {
    // informar o usuário que está criando
    try {

      let state = {};
      if(!!client.subdomain){
        // put
        const userDefault = {
          id: client.userDb.id,
          usuario_banco_cliente: client.userDb.name,
          senha_usuario_banco: data.senha_usuario_banco,
          conf_senha_usuario_banco: data.conf_senha_usuario_banco,
        }
        const {ok, msg} = await apiClient.updatePassAdmin(params.id, client.subdomain.id, userDefault);
        state = {state: {type: ok ?'success':'error', msg: msg}};
      }else{
        // post
        const subdomain = {
          nome: data.nome,
          link: data.link,
          nome_banco_dados: data.nome_banco_dados,
          usuarios_id: userLogged.id
        }
        const userDefault = {
          usuario_banco_cliente: data.usuario_banco_cliente,
          senha_usuario_banco: data.senha_usuario_banco,
          conf_senha_usuario_banco: data.conf_senha_usuario_banco,
        }
  
        const {ok, msg} = await apiClient.createSubdomain(params.id, subdomain, userDefault);
        state = {state: {type: ok ?'success':'error', msg: msg}};
      }

      navigate('/clientes', state);

    } catch (error) {
      navigate('/clientes', {state: {type:'error', msg: error.message}});
    }
  }

  const handleEdit = (data) => {
    sendForm(data);
  }  

  useEffect(()=>{
    (async ()=>{
      try {
        setLoading(true);
        const {ok, register, msg} = await apiClient.getSubdomainByClientId(params?.id);
        if(ok){
          if(!!msg){
            alert(msg);
          }
          setClient(register);
          setValue('nome_empresa', register.nome_empresa); 
          setValue('cnpj', normalizeCNPJNumber(register.cnpj));

          if(!!register.subdomain){
            setValue('nome', register.subdomain.nome); 
            setValue('link',  register.subdomain.link); 
            setValue('nome_banco_dados',  register.subdomain.nome_banco_dados);
          }
        }
      } catch (error) {
        alert('Error: ' + error.message);
      } finally {
        setLoading(false);
      }
    })()
  },[]);

  const value_nome = watch('nome');
  useEffect(()=>{
    setValue('link', `${!!value_nome ? value_nome:''}.${getConfig('DOMAIN_APP_URL')}`);
    setValue('nome_banco_dados', `${!!value_nome ? value_nome:''}.${getConfig('DOMAIN_APP_NAME')}`);
  },[value_nome]);

  return (
    <LayoutAdminDefault
      title="Subdominio"
    >
      {!loading ?
        <>
          <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2}}>
            <TextField
              required
              label={"Nome da Empresa"}
              type="text"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              {...register("nome_empresa", { required: true })}
            />

            <TextField
              required
              label={"CNPJ"}
              type="text"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              {...register("cnpj", { required: true })}
            />
            <TextField
              required
              label={"Nome do subdominio desejado"}
              type="text"
              disabled={!!client?.subdomain ? true : false}
              InputLabelProps={{
                shrink: true,
              }}
              {...register("nome", { required: true })}
            />

            <TextField
              required
              label={"Link de acesso a área do cliente"}
              type="text"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              {...register("link", { required: true })}
            />

            <TextField
              required
              label={"Nome do banco de dados"}
              type="text"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              {...register("nome_banco_dados", { required: true })}
            />

            <TextField
              required
              label={"Usuário"}
              type="text"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              {...register("usuario_banco_cliente", { required: true })}
            />
            
            <TextField
              required
              label={"Senha"}
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("senha_usuario_banco", { required: true })}
            />

            <TextField
              required
              label={"Confirmar Senha"}
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("conf_senha_usuario_banco", { required: true })}
            />
          </Box>

          <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2}}>
            {!client?.subdomain ?
              <Button 
                variant="contained" 
                onClick={handleSuggestSubdomain}
                sx={{width:"15vw"}}
                color="error"
              >
                <Typography sx={{fontSize:"18px"}}>Sugerir subdominio</Typography>
              </Button>

            :
              null  
            }

            <Button
              variant="contained" 
              onClick={handleSubmit(handleEdit)}
              sx={{width:"15vw"}}
              color="primary"
            >
              <Typography sx={{fontSize:"18px"}}>Salvar</Typography>
            </Button>

            <Button 
              variant="contained" 
              onClick={handleBack}
              sx={{width:"15vw"}}
              color="error"
            >
              <Typography sx={{fontSize:"18px"}}>Voltar</Typography>
            </Button>
          </Box>
        </>
      :
        <Typography>
          Carregando...
        </Typography>
      }
    </LayoutAdminDefault>
  )
}