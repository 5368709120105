import { Box, Button, Divider, Typography } from "@mui/material";
import { ArrowRightIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { assets } from "../../../../../../../assets/images_sys";
import { useNavigate, useParams } from "react-router-dom";
import { useConsultationPageContext } from "../../../../contexts";

export function TreatmentsList({ list = [] }) {
	const navigate = useNavigate();
	const params = useParams();

	const { finishedTreatment  } = useConsultationPageContext()

	const patientId = params.patientId;

	const baseUrlPath = `/business/patients/${patientId}`;

	const [treatmentsList, setTreatmentsList] = useState(
		[
			{
				id: 1,
				nome: "Toxina Botulínica (1ª sessão)",
				descricao: `Revitalize sua pele com a aplicação precisa de toxina
     			botulínica. Suavize rugas e linhas de expressão,
     			revelando uma aparência mais jovem e radiante.`,
				dataAgendamento: "25/08/2023",
				concluido: finishedTreatment,
				imagem: assets.imgs.treatment_plan_1,
				path: `${baseUrlPath}/consultation/treatment/toxina-botulinica`,
			},
			{
				id: 2,
				nome: "Toxina Botulínica (2ª sessão)",
				descricao: `Revitalize sua pele com a aplicação precisa de toxina
     			botulínica. Suavize rugas e linhas de expressão,
     			revelando uma aparência mais jovem e radiante.`,
				dataAgendamento: "15/09/2023",
				concluido: false,
				imagem: assets.imgs.treatment_plan_1,
				path: `${baseUrlPath}/consultation/treatment/toxina-botulinica`,
			},
			{
				id: 3,
				nome: "Microagulhamento",
				descricao: `Transforme sua pele com o microagulhamento, um tratamento
					estimulante. Estimule o colágeno, reduza cicatrizes e melhore a textura,
					obtendo um brilho saudável.`,
				dataAgendamento: "22/09/2023",
				concluido: false,
				imagem: assets.imgs.treatment_plan_2,
				path: `${baseUrlPath}/consultation/treatment/microagulhamento`,
			},
			{
				id: 4,
				nome: "Peeling Químico",
				descricao: `Renove sua pele com um peeling químico personalizado. Elimine 
					células mortas, reduza manchas e imperfeições, revelando uma pele mais 
					fresca e radiante.`,
				dataAgendamento: "29/09/2023",
				concluido: false,
				imagem: assets.imgs.treatment_plan_3,
			},
			{
				id: 5,
				nome: "Limpeza de Pele",
				descricao: `Mime sua pele com uma limpeza profunda e revitalizante. Remova
					impurezas, desobstrua os poros e desfrute de uma pele mais clara e suave.`,
				dataAgendamento: "06/10/2023",
				concluido: false,
				imagem: assets.imgs.treatment_plan_4,
			},
		] || list
	);

	return (
		<Box sx={{ marginY: "8px" }}>
			{treatmentsList.map((treatment, index, array) => {
				const {
					id,
					nome,
					descricao,
					dataAgendamento,
					concluido,
					imagem,
					path,
				} = treatment;

				return (
					<>
						<Box
							key={`treatment-${id}`}
							sx={{ display: "flex", flexDirection: "column" }}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									gap: "24px",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										gap: "16px",
										justifyContent: "space-between",
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											gap: "4px",
										}}
									>
										<Typography
											variant="big-b"
											sx={{ color: "#3466B2" }}
										>
											{nome}
										</Typography>
										<Typography
											variant="body-r-v2"
											sx={{ color: "#374151" }}
										>
											{descricao}
										</Typography>
									</Box>
									<Box
										sx={{
											display: "flex",
											gap: "24px",
											alignItems: "end",
										}}
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												gap: "12px",
												alignSelf: "end",
											}}
										>
											<Typography
												variant="label-m-v2"
												sx={{ color: "#374151" }}
											>
												Data do agendamento
											</Typography>
											<Typography
												variant="subtitle-m"
												sx={{ color: "#09234A" }}
											>
												{dataAgendamento}
											</Typography>
										</Box>
										{concluido && (
											<Box
												sx={{
													display: "flex",
													gap: "12px",
													alignItems: "center",
												}}
											>
												<Box
													sx={{
														padding: "4px",
														display: "flex",
														backgroundColor:
															"#99E2C7",
														borderRadius: "50%",
														justifyContent:
															"center",
														alignItems: "center",
													}}
												>
													<CheckCircleIcon
														style={{
															width: "24px",
															height: "24px",
															color: "#018F5D",
														}}
													/>
												</Box>
												<Typography
													variant="big-m"
													sx={{ color: "#018F5D" }}
												>
													Procedimento concluído - 08/12/2023
												</Typography>
											</Box>
										)}
										<Button
											variant="outlined"
											onClick={
												path
													? () => navigate(path)
													: () => {}
											}
											size="small"
											endIcon={
												<ArrowRightIcon
													style={{
														width: "16px",
														height: "16px",
														color: "#3466B2",
													}}
												/>
											}
										>
											<Typography
												variant="button-s-v2"
												sx={{ color: "#3466B2" }}
											>
												Ver procedimento
											</Typography>
										</Button>
									</Box>
								</Box>
								{imagem && (
									<img
										style={{
											filter: concluido
												? "grayscale(100%)"
												: "none",
											webkitFilter: concluido
												? "grayscale(100%)"
												: "none",
										}}
										src={imagem}
										alt="treatment-plan"
									/>
								)}
							</Box>
						</Box>
						{index < array.length - 1 && (
							<Divider sx={{ marginY: "24px" }} />
						)}
					</>
				);
			})}
		</Box>
	);
}
