import React from "react"
import { Route } from "react-router-dom"

import { PlanningLayout } from "../layouts"
import { NotFound } from "../../../../pages/publics"
import { PlanningContextProvider } from "../contexts"
import { PageContextProvider } from "../../../../contexts"
import { PlanningMenu, PlanningWellCome } from "../pages"

export const ModulePlanningRouters = (
  <>
		<Route path="planning"
			element={
				<PageContextProvider>
					<PlanningContextProvider>
						<PlanningLayout/>
					</PlanningContextProvider>
				</PageContextProvider>
			}
		>
			<Route index element={<PlanningWellCome />} />
			<Route path="menu" element={<PlanningMenu />}/>
			<Route path="*" element={<NotFound />} />
		</Route>
  </>
)