import { useTableCustomContext } from "../TableCustomContainer";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { FunnelIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { TypographyCustom } from "../../TypographyCustom";
import { TextFieldCustomText } from "../../TextFieldCustomText";
import { useForm } from "react-hook-form";
import { TextFieldCustomNumber } from "../../TextFieldCustomNumber";
import { TextFieldCustomPhone } from "../../TextFieldCustomPhone";
import { TextFieldCustomDate } from "../../TextFieldCustomDate";
import { TextFieldCustomCPF } from "../../TextFieldCustomCPF";
import { TextFieldCustomCNPJ } from "../../TextFieldCustomCNPJ";

export default function TableCustomHeaderButtonFilter({
	handleOnClickFilter = (data) => {},
}) {
	const { columns, handleAddFilters } = useTableCustomContext();
	const [openFilterForm, setOpenFilterForm] = useState(false);

	const handleOpenFilterForm = () => setOpenFilterForm(true);
	const handleCloseFilterForm = () => setOpenFilterForm(false);

	const filterForm = useForm();

	const submitForm = (data) => {
		handleOnClickFilter(data);
		handleAddFilters(data);
	};

	const ColumnField = ({ column }) => {
		const id = column?.id;
		const type = column?.type;
		const name = column?.name;

		if (!type) return null;

		switch (type) {
			case "string":
			case "text":
				return (
					<TextFieldCustomText
						control={filterForm.control}
						name={id}
						label={name}
					/>
				);
			case "number":
				return (
					<TextFieldCustomNumber
						control={filterForm.control}
						name={id}
						label={name}
						errors={filterForm.formState.errors}
					/>
				);
			case "phone":
				return (
					<TextFieldCustomPhone
						control={filterForm.control}
						name={id}
						label={name}
						errors={filterForm.formState.errors}
					/>
				);
			case "date":
				return (
					<TextFieldCustomDate
						control={filterForm.control}
						name={id}
						label={name}
						errors={filterForm.formState.errors}
					/>
				);
			case "cpf":
				return (
					<TextFieldCustomCPF
						control={filterForm.control}
						name={id}
						label={name}
						errors={filterForm.formState.errors}
					/>
				);
			case "cnpj":
				return (
					<TextFieldCustomCNPJ
						control={filterForm.control}
						name={id}
						label={name}
						errors={filterForm.formState.errors}
					/>
				);
			default:
				return null;
		}
	};

	const FilterForm = () => {
		return (
			<Modal
				open={openFilterForm}
				onClose={handleCloseFilterForm}
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box
					sx={{
						minWidth: "300px",
						display: "flex",
						flexDirection: "column",
						backgroundColor: "#FFFFFF",
						borderRadius: "10px",
						padding: "16px",
						gap: "15px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Typography
							variant="subtitle-b"
							sx={{ fontWeight: "bold" }}
						>
							Filtros
						</Typography>
						<IconButton onClick={handleCloseFilterForm}>
							<XMarkIcon
								style={{ width: "24px", height: "24px" }}
							/>
						</IconButton>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "10px",
							alignItems: "center",
						}}
					>
						{columns.map((column) => {
							return (
								<ColumnField
									key={`filter-field-${column.id}`}
									column={column}
								/>
							);
						})}
					</Box>
					<Button
						onClick={filterForm.handleSubmit(submitForm)}
						variant="contained"
						sx={{ height: "44px" }}
					>
						<Typography variant="button-m">Filtrar</Typography>
					</Button>
				</Box>
			</Modal>
		);
	};

	return (
		<Box>
			<FilterForm />
			<Button
				onClick={handleOpenFilterForm}
				variant="outlined"
				sx={{
					// height: "44px",
					// textTransform: "none",
					// backgroundColor: "#EFF5FF",
					// border: "2px solid",
					// borderColor: /* disabled ? "#D1D5DB" :  */ "#558EE3",
					// borderRadius: "8px",
					display: "flex",
					gap: "4px",
					// alignItems: "center",
					// justifyContent: "center",
				}}
			>
				<FunnelIcon
					style={{
						color: /* disabled ? "#CCC" :  */ "#3466B2",
						width: "20px",
						height: "20px",
					}}
				/>
				{/* <Typography variant="button">Filtros</Typography> */}
				<TypographyCustom
					text="Filtros"
					type="button-s-v2"
					colorText={/* disabled ? "#CCC" :  */ "#1A417D"}
				/>
			</Button>
		</Box>
	);
}
