import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Input, Typography } from "@mui/material";

import { LayoutAdminDefault } from "../../../../../layouts";
import { useAuthContext } from "../../../../../contexts";
import { apiClient } from "../../../../../services/apiInterface/admin/clients";

export function UploadImgClients() {

  const params = useParams();

  const { userLogged } = useAuthContext();

  const [ file, setFile ] = useState();

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/clientes');
  }

  const sendForm = async (formData) => {
    try {
      const { ok, msg } = await apiClient.uploadImg(formData);
        navigate('/clientes', {state: {type:ok?'success':'error', msg: msg}});
    } catch (error) {
      navigate('/clientes', {state: {type: 'error', msg: error.message}});
    }
  }

  const handleImportImg = async () => {

    const formData = new FormData();
        
    formData.append('file', file);
    formData.append('client_id',params.id);
    formData.append('user_id',userLogged.id);

    sendForm(formData);
    
  }

  const openFile = (event) => {
    setFile(event.target.files[0]);
  }

  return (
    <LayoutAdminDefault
      title="Importar Logo"
    >
      <Box sx={{m:1, display: "flex", flexDirection:"column", minWidth: 120, gap:2}}>
        <Input
          id="file_img"
          type="file"
          onChange={openFile}
          required
        />
      </Box>

      <Box sx={{m:1, display: "flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2}}>
        <Button
          variant="contained" 
          onClick={handleImportImg}
          sx={{width:"15vw"}}
          color="primary"
        >
          <Typography sx={{fontSize:"13px"}}>Importar Imagem</Typography>
        </Button>

        <Button 
          variant="contained" 
          onClick={handleBack}
          sx={{width:"15vw"}}
          color="error"
        >
          <Typography sx={{fontSize:"13px"}}>Voltar</Typography>
        </Button>
      </Box>
      
    </LayoutAdminDefault>
  )
}