import { useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageLayout } from "../../../../../components";
import { useBusinessPageContext } from "../../../../../contexts/BusinessPageContext";
import {
	PatientDescription,
	ScheduledVisitsItem,
	ScheduledVisitsContainer,
	ScheduledVisitsHeader,
} from "./components";

export function BusinessPatientDescription() {
	const navigate = useNavigate();
	const { setIsPageDrawerShown } = useBusinessPageContext();

	const handleGoBack = () => {
		navigate("/business/patients");
	};

	useEffect(() => {
		setIsPageDrawerShown(true);
	}, []);

	return (
		<PageLayout.Container>
			<PageLayout.Header.Container>
				<PageLayout.Header.ButtonBack onClick={handleGoBack} />
			</PageLayout.Header.Container>
			<PageLayout.Body.Container
				sx={{ maxWidth: "1100px", paddingTop: "20px" }}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						gap: "30px",
					}}
				>
					<PatientDescription />
					<ScheduledVisitsContainer>
						<ScheduledVisitsHeader />
						<ScheduledVisitsItem preConsultation date={'Segunda-feira, 01 Setembro • 16:00'} />
						<ScheduledVisitsItem date={'Sexta-feira, 30 Junho • 13:00'}/>
					</ScheduledVisitsContainer>
				</Box>
			</PageLayout.Body.Container>
		</PageLayout.Container>
	);
}
