import React, { useEffect } from "react";
import { Divider, IconButton, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import MenuIcon from '@mui/icons-material/Menu';

import { useAuthContext, useDrawerAdminContext } from "../contexts";

export function LayoutAdminDefault({ children, title, toolsBar = undefined }) {
  const { toggleDrawerOpen } = useDrawerAdminContext();
  const { userLogged, handleLogout, authenticated } = useAuthContext();

  const theme = useTheme();

  useEffect(()=>{
    // if(!isAuthenticated()){
    //   handleLogout();
    // }
    if(authenticated){
      handleLogout();
    }
  },[]);

  return(
    <Box height="100%" display="flex" flexDirection="column" gap={1}>
      <Box padding={1} display="flex" height={theme.spacing(6)} >
        <Box width={'50%'} display="flex" alignItems="center" gap={1}>
          <IconButton onClick={toggleDrawerOpen}>
            <MenuIcon/>
          </IconButton>

          <Typography 
            variant='h6'
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {title}
          </Typography>
        </Box>
        <Box width={'50%'} display="flex" alignItems="center" justifyContent="end" paddingRight={theme.spacing(1)}>
          <Typography 
            variant='h6'
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            Usuário: {userLogged?.nickname}
          </Typography>
        </Box>
      </Box>

      <Divider/>

      {toolsBar && (<Box>
        {toolsBar}
      </Box>)}

      <Box flex={1} overflow="auto">
        {children}
      </Box>
    </Box>
  )
}