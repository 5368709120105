import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../../contexts";
import { get } from "../../../environment";
import { AlertCustom } from "../../../components/AlertCustom";

export const AdminLogin = () => {
  const { handleLogin } = useAuthContext();
  const { register, handleSubmit, setError, formState: { errors } } = useForm();
  const [ errorLogin, setErrorLogin] = useState();

  const LOGO = get('IMG_LOGO');

  const handleLoginValidate = async (data) => {
    // await admin.login(data);
    const { success, msg } = await handleLogin(data);
    if(!success){
      setErrorLogin({type: 'error', msg: msg});
    }
  }

  return (
    <Box sx={{height: '100vh', display:'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', gap: 2}}>
      <img src={LOGO} resize={'cover'} max-width="60%" alt=""/>
      <Box sx={{display:'flex', flexDirection: 'column', alignItems:'center', gap: 2}}>
        <Typography>
          Acesso Administrativo
        </Typography>
        <TextField
          label="Usuário"
          variant="outlined"
          {...register("nickname", {required: true})}
        />
        <TextField
          label="Senha"
          type="password"
          autoComplete="current-password"
          variant="outlined"
          {...register("pass", {required: true})}
        />
        {errors.general && <p>{errors.general.message}</p>}
        {!!errorLogin && <AlertCustom formMessage={errorLogin}/>}

        <Button 
          className="ButtonLogin" 
          sx={{width: '15rem', color: 'white', fontSize: 18, fontWeight: 'bold'}} 
          size="large" 
          variant="contained" 
          color="primary"
          onClick={handleSubmit(handleLoginValidate)}
        >
          Login
        </Button>
      </Box>
    </Box>

  )
}